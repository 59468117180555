.wrapper {
  margin: auto;
  max-width: 1440px;
  padding: 0 16px;
  width: 100%;
}

.container {
  width: 100%;
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  background-color: #f0f3f0;
  padding: var(--margin-lg) var(--margin-md);
}

.item {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 265px;
}

.item:not(:last-of-type) {
  margin-bottom: var(--margin-lg);
}

.icon {
  max-width: 100%;
}

.underlineContainer {
  position: relative;
}

.underline {
  left: 0;
  bottom: -2px;
  width: 118px;
  position: absolute;
}

.button {
  width: 100%;
  max-width: fit-content;
  margin: var(--margin-lg) auto 0 auto;
}

.player svg {
  width: 100%;
}

.title {
  line-height: 22px;
}

.heading {
  line-height: 34px;
}

.mobileBreakline {
  display: block;
}

.desktopBreakline {
  display: none;
}

@media (--viewport-lg) {
  .mobileBreakline {
    display: none;
  }

  .desktopBreakline {
    display: block;
  }

  .wrapper {
    padding: 0 var(--margin-xxl);
  }

  .container {
    padding: var(--margin-xxl) 0;
  }

  .player {
    max-width: 80%;
  }

  .player svg {
    width: unset;
    height: 125px !important;
  }

  .item {
    max-width: unset;
  }

  .item:not(:last-of-type) {
    margin-bottom: 0;
  }

  .grid {
    max-width: 1150px;
  }

  .heading {
    font-size: 40px;
    margin-bottom: 77px;
    line-height: 48px;
  }

  .button {
    margin-top: 72px;
  }

  .title {
    font-size: 22px;
    line-height: 28px;
  }
}
