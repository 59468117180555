.section {
  margin: 0 16px;
  padding: 0 8px;
  text-align: center;
  width: stretch;
}

.tile {
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  height: 100%;
  width: 100%;

  picture {
    display: block;
    width: 100%;
  }
}

.openIcon {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: var(--margin-sm);
  right: var(--margin-sm);
}

.tileTitle {
  position: absolute;
  top: 20px;
  left: 12px;
  width: 100%;
}

.tileImage {
  width: 100%;
  object-fit: cover;
}

.button {
  margin-top: var(--margin-md);
}
