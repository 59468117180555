/*
 * These styles are declared as imporant since they're meant to override corgi-x styles
 * When the new variants are added to corgi-x, these styles should be removed.
 */
.extendedButtonBase {
  border-radius: 40px !important;
  border-style: solid !important;
  border-width: 2px !important;
  cursor: pointer !important;
  display: inline-block !important;
  font-family: var(--tfd-font-family) !important;
  font-weight: bold !important;
  letter-spacing: 0.5px !important;
  line-height: 22px !important;
  padding: 10.5px var(--tfd-spacing-lg) !important;
  text-decoration: none !important;
  white-space: nowrap !important;
  font-size: 16px !important;
  min-width: 225px !important;
}

@media (--viewport-md) {
  .extendedButtonBase {
    font-size: 22px !important;
    line-height: 30px !important;
    padding: 15.5px var(--tfd-spacing-xl) !important;
    min-width: 281px !important;
  }
}

.solidCarrot {
  background-color: var(--carrot-2) !important;
  border-color: var(--carrot-2) !important;
  color: var(--white) !important;
}

.solidCarrot:hover {
  background-color: var(--carrot-3) !important;
  border-color: var(--carrot-3) !important;
}

.solidCarrot:active {
  background-color: var(--carrot-3) !important;
  border-color: var(--carrot-1) !important;
}

.solidKale {
  background-color: var(--kale-2) !important;
  border-color: var(--kale-2) !important;
  color: var(--white) !important;
}

.solidKale:disabled {
  cursor: default !important;
}


.solidKale:hover {
  background-color: var(--kale-3) !important;
  border-color: var(--kale-3) !important;
}

.solidKale:active {
  background-color: var(--kale-3) !important;
  border-color: var(--kale-1) !important;
}

.solidWhite {
  background-color: var(--white) !important;
  border-color: var(--white) !important;
  color: var(--kale-3) !important;
}

.solidWhite:hover {
  background-color: var(--kale-3) !important;
  border-color: var(--kale-3) !important;
  color: var(--white) !important;
}

.borderedWhite {
  background-color: transparent !important;
  color: var(--white) !important;
  border-color: var(--white) !important;
}

.borderedWhite:hover {
  outline: 1px solid var(--white) !important;
}

.borderedKale {
  background-color: transparent !important;
  border: 2px solid var(--kale-3) !important;
  color: var(--kale-3) !important;
}

.borderedKale:hover {
  outline: 1px solid var(--kale-3) !important;
}

.borderedCharcoal {
  background-color: transparent !important;
  border: 2px solid var(--charcoal-3) !important;
  color: var(--charcoal-3) !important;
}

.borderedCharcoal:hover {
  outline: 1px solid var(--charcoal-3) !important;
}
