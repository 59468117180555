.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  min-height: 222px;
}

.container {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 24px;
}

.slidesTrack {
  display: flex;
  position: relative;
}

.slide {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
}

.slideImage {
  width: auto;
  max-width: 100%;
  object-fit: contain;
  transition: all 0.5s ease;
}

.active {
  /* Styling for active slide */
  opacity: 1;
  filter: grayscale(0);
}

.inactive {
  /* Styling for inactive slides - grayscale */
  opacity: 0.25;
  filter: grayscale(1);
}

.footnoteContainer {
  margin-top: 24px;
  min-height: 12rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 100%;
  max-width: 90%;
  text-align: center;
}

.footnoteContainer:focus,
.footnoteContainer:focus-visible {
  outline: none;
}

.footnote {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
