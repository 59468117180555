.submitButton {
  width: auto;
  margin-bottom: var(--tfd-spacing-sm);
}

@media (--viewport-lg) {
  .submitButton {
    width: 100%;
  }
}
