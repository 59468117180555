.marqueeWrapper {
  display: block;
  padding: var(--tfd-spacing-md) 0 var(--tfd-spacing-xl) 0;
}

.marqueeWrapperCurrentFormat {
  padding-top: var(--tfd-spacing-lg);
}

@media (--viewport-lg) {
  .marqueeWrapper {
    display: none;
  }
}
