.button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--margin-md);
}

.text {
  margin-bottom: var(--margin-lg);
  width: 322px;
}

@media (--viewport-md) {
  .text {
    width: 620px;
  }
}
