.pageBackground {
  background-color: var(--chickpea-experimental);
}

.grid {
  gap: 60px;
  padding-top: 60px;
}

@media (--viewport-lg) {
  .grid {
    gap: var(--margin-4xl);
    padding-top: var(--margin-xxl);
  }
}
