.pageHeroWrapper {
  text-align: center;
  overflow-x: clip;
  height: 500px;
  margin: 0 16px 16px 16px;
}

.grid,
.content,
.pageHeroWrapper {
  position: relative;
}

.grid,
.pageHeroWrapper > div {
  height: 100%;
}

.title {
  font-size: 36px;
  line-height: 42px;
}

.subtitle {
  font-size: 16px;
}

.desktop {
  display: none;
}

.content {
  z-index: 2;
}

.pageHeroWrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180.52deg,
    rgba(0, 0, 0, 0) 11.8%,
    rgba(0, 0, 0, 0.3) 56.1%,
    rgba(0, 0, 0, 0) 96.52%
  );
}

.heroImage {
  z-index: unset;
  object-position: 75% 75%;
}

@media (--viewport-lg) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }

  .pageHeroWrapper {
    margin-bottom: 0;
    height: 550px;
  }

  .title {
    font-size: 52px;
    line-height: 58px;
  }

  .subtitle {
    font-size: 22px;
    line-height: 28px;
  }

  .heroImage {
    object-position: 64% 100%;
  }

  .pageHeroWrapper::after {
    background: linear-gradient(
      305deg,
      rgba(0, 0, 0, 0) 31.35%,
      rgba(0, 0, 0, 0.3) 71.91%
    );
  }

  .content {
    margin-top: 115px;
  }
}
