.container,
.activityIndicator,
.wistiaElement,
.gradient,
.controls,
.bottomRightControls,
.overlay,
.interactionBlocker,
.title {
  position: absolute;
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100% !important;
  height: 100%;
  min-height: 360px;
  outline: unset;
  overflow: hidden;
}

.activityIndicator {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wistiaElement {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.gradient {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.12)
  );
}

.controls {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomRightControls {
  justify-content: flex-end;
  align-items: flex-end;
  padding: var(--margin-sm);
  right: 0;
  bottom: 0;
  left: auto;
  top: auto;
}

.overlay,
.overlay * {
  width: 100%;
  height: 100%;
}

.overlay {
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.showOverlay {
  display: flex;
}

.interactionBlocker {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.title {
  border-radius: 20px;
  display: inline-block;
  top: var(--margin-md);
  left: var(--margin-md);
  background-color: #39383c66;
  padding: var(--margin-xs) var(--margin-sm);
}
