.recipeOptionsContainer {
  padding: 0 var(--tfd-spacing-sm) var(--tfd-spacing-sm);
  height: 100%;
}

.gridItem {
  display: flex;
  flex-direction: column;
}

.recipesBackgroundContainer {
  background: var(--chickpea-1);
  position: absolute;
  left: 0;
  overflow: hidden;
  width: 100vw;
  /* the viewport height minus the height of the signup nav */
  min-height: calc(100vh - 125px);
  margin-top: var(--tfd-spacing-sm);
}

.recipeToggleCard {
  margin-bottom: var(--tfd-spacing-md);
  text-align: left;
}

.detailsButton {
  margin: var(--tfd-spacing-xs) 0;
  /* have to overwrite corgi button props */
  font-size: 16px !important;
  font-weight: 400 !important;
}

.returnButton {
  /* ensure back to recommended button is not obscured by sticky CTA at any viewport size */
  margin-bottom: 120px;
}

.contentContainer {
  padding: 0 var(--tfd-spacing-sm) var(--tfd-spacing-sm);
}

.controlContainer {
  padding: var(--tfd-spacing-sm);
}

.gridContainer {
  max-width: 1155px;
  margin: 0 auto;
}

.priceDivider {
  max-width: 95%;
  display: block;
  margin: 0 auto;
}

.recipesCopy {
  margin-bottom: var(--tfd-spacing-sm);
}

.priceWrapper {
  text-align: center;
}

@media (--viewport-sm) {
  .contentContainer {
    padding: 0 var(--tfd-spacing-md) var(--tfd-spacing-sm);
  }

  .recipesCopy {
    padding: 0 var(--tfd-spacing-xl);
  }
}

@media (--viewport-md) {
  .priceDivider {
    max-width: 300px;
  }
}

@media (--viewport-lg) {
  .recipesBackgroundContainer {
    margin-top: var(--tfd-spacing-md);
  }

  .recipeOptionsContainer {
    padding: 0 var(--tfd-spacing-sm) var(--tfd-spacing-xl);
  }

  .recipeToggleCard {
    max-width: 180px;
    margin: 0 var(--tfd-spacing-xs) var(--tfd-spacing-md);
    height: 410px;
  }

  .controlContainer {
    padding: var(--tfd-spacing-sm) var(--tfd-spacing-md);
  }
}

@media (--viewport-xl) {
  .priceWrapper {
    text-align: left;
  }

  .priceDivider {
    max-width: unset;
  }

  .priceDividerWrapper,
  .oneRecipeDividerWrapper {
    margin: 0 auto;
  }

  .oneRecipeDividerWrapper {
    width: 180px;
  }
}
