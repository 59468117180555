.container {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  overflow: hidden;
  max-width: 100vw;
}

.horizontalSnap {
  box-sizing: border-box;
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap;
  align-items: center;
}

.message,
.dots {
  text-align: center;
  margin: 0 auto;
}

.backwardButtonContainer,
.forwardButtonContainer {
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.backwardButtonContainer {
  left: 19%;
}

.forwardButtonContainer {
  right: 19%;
}

.arrowButton {
  background: var(--chickpea-experimental);
  opacity: 0.6;
  transition: opacity var(--animation-standard);
  border: none;
  border-radius: 50%;
  padding: 7px;
  height: 72px;
  width: 72px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.arrowButton:hover,
.arrowButton:focus,
.arrowButton:active {
  opacity: 0.8;
}

.arrowButton:disabled {
  opacity: 0.25;
}

.isNotWide .message {
  margin-top: var(--margin-sm);
}

.isNotWide .dots {
  margin-top: var(--margin-md);
}

.isWide .message {
  margin-top: var(--margin-md);
}

.isWide .dots {
  margin-top: var(--margin-md);
}

.disableUserScroll {
  overflow: hidden;
}
