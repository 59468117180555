.root {
  background: var(--kale-0);
  border: 1px solid var(--kale-3);
  border-radius: var(--tfd-spacing-xs);
  margin: var(--tfd-spacing-sm) 0 var(--tfd-spacing-sm) 0;
  padding: var(--tfd-spacing-sm);
  display: flex;
  flex-flow: row nowrap;
}

.icon {
  margin-right: var(--tfd-spacing-xs);
}
