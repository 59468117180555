.article-24 {
  font-family: var(--tfd-font-family-serif-headline);
  font-size: 24px;
  letter-spacing: 0.5px;
  line-height: 30px;
}

.spaced {
  font-family: var(--tfd-font-family);
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
}

@media (--viewport-md) {
  .spaced {
    font-size: 16px;
  }
}

.heading-18 {
  font-family: var(--tfd-font-family);
  font-size: 18px;
  line-height: 22px;
}

.heading-22 {
  line-height: 22px;
}

.heading-28 {
  font-family: var(--tfd-font-family);
  line-height: 32px;
}

.heading-36 {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 36px;
  line-height: 42px;
}
