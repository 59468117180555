.heroTitleContainer {
  align-items: center;
  flex-direction: column;
  gap: var(--tfd-spacing-md);
  justify-content: center;
}

.heroTitle {
  font-size: 36px;
  font-weight: 900;
  letter-spacing: 0.5px;
  line-height: 42px;
  margin-bottom: 16px;
  text-align: center;
}

.heroSubtitle {
  font-size: 16px;
  line-height: 22px;
}

.titeBreakline,
.subtitleBreakline {
  display: block;
}

.heroCta {
  min-width: fit-content;
}

@media (--viewport-md) {
  .heroTitle {
    font-size: 52px;
    line-height: 62px;
    margin-bottom: var(--margin-sm);
  }

  .heroSubtitle {
    font-size: 22px;
    line-height: 30px;
  }

  .titeBreakline,
  .subtitleBreakline {
    display: none;
  }
}

@media (--viewport-xl) {
  .heroTitle,
  .heroSubtitle {
    text-align: left;
  }

  .heroTitleContainer {
    flex-direction: row;
    gap: var(--tfd-spacing-lg);
    justify-content: space-between;
  }
}
