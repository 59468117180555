@import url('https://use.typekit.net/tlf2grf.css');

@font-face {
  font-family: 'neue-haas-grotesk-display';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: auto;
  src:
    url('../src/assets/fonts/NeueHaasGrotDisp-65Medium-Web.woff2') format('woff2'),
    url('../src/assets/fonts/NeueHaasGrotDisp-65Medium-Web.woff') format('woff');
}

@font-face {
  font-family: tiempos-text;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('../src/assets/fonts/tiempos-text-web-regular.woff2') format('woff2'),
    url('../src/assets/fonts/tiempos-text-web-regular.woff') format('woff');
}

@font-face {
  font-family: tiempos-text;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src:
    url('../src/assets/fonts/tiempos-text-web-regular-italic.woff2') format('woff2'),
    url('../src/assets/fonts/tiempos-text-web-regular-italic.woff') format('woff');
}

@font-face {
  font-family: tiempos-text;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    url('../src/assets/fonts/tiempos-text-web-bold.woff2') format('woff2'),
    url('../src/assets/fonts/tiempos-text-web-bold.woff') format('woff');
}

@font-face {
  font-family: tiempos-text;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src:
    url('../src/assets/fonts/tiempos-text-web-bold-italic.woff2') format('woff2'),
    url('../src/assets/fonts/tiempos-text-web-bold-italic.woff') format('woff');
}

@font-face {
  font-family: tiempos-headline;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../src/assets/fonts/tiempos-headline-regular.woff2') format('woff2');
}

@font-face {
  font-family: tiempos-headline;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src:
    url('../src/assets/fonts/tiempos-headline-regular-italic.woff2') format('woff2'),
    url('../src/assets/fonts/tiempos-headline-regular-italic.woff') format('woff');
}

@font-face {
  font-family: tiempos-headline;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    url('../src/assets/fonts/tiempos-headline-semibold.woff2') format('woff2'),
    url('../src/assets/fonts/tiempos-headline-semibold.woff') format('woff');
}

@media (prefers-reduced-motion: no-preference) {
  html {
    /* Scroll smoothly when clicking on anchor links, or when using `scrollIntoView` */
    scroll-behavior: smooth;
  }
}

/* 
Use these variables for using our color palette. Use Color.ts to access these values in TypeScript. 
We keep these variables in a single file so that we parse it to test it matches the `Color` enum.
*/
:root {
  --blueberry-0: #e6ecf5;
  --blueberry-1: #b7c6e6;
  --blueberry-2: #385a9d;
  --blueberry-3: #03184e;

  --carrot-0: #fff4f1;
  --carrot-1: #ffd9cc;
  --carrot-2: #f2674b;
  --carrot-3: #d2563d;

  --charcoal-0: #c2c3c5;
  --charcoal-1: #969799;
  --charcoal-2: #717377;
  --charcoal-3: #39383c;

  --chickpea-0: #fffaf3;
  --chickpea-1: #fff3e1;
  --chickpea-2: #f2e1c8;
  --chickpea-3: #d3bfa2;
  --chickpea-experimental: #fffcf8;
  /* TODO: change this variable name */
  /* https://app.shortcut.com/farmersdog/story/134455/revise-new-chickpea-name */

  --kale-0: #e5efe7;
  --kale-1: #b5d3ba;
  --kale-2: #417c67;
  --kale-3: #173b33;
  --kale-4: #3c725e;

  --oyster-0: #f6f5f3;
  --oyster-1: #efedea;
  --oyster-2: #dbd6d1;
  --oyster-3: #c6bfb7;

  --white: #ffffff;
  --error-red: #e31c3d;
  --error-red-dark: #df0b1c;
  --error-red-bg: #fef1f1;
  --tfd-logo: #f5694d;

  --twitter: #1da1f2;
  --facebook: #3b5998;
  --instagram: #6ba5d5;
}
/* Ex @farmersdog/corgi variables added for backward compatibility. */
/* Do not use these variables in corgi-x. See https://app.shortcut.com/farmersdog/story/79291 */
:root {
  /* Margins (now named "spacings") */
  --margin-xxs: 2px;
  --margin-xs: 6px;
  --margin-sm: 12px;
  --margin-md: 24px;
  --margin-lg: 36px;
  --margin-xl: 48px;
  --margin-xxl: 72px;
  --margin-3xl: 96px;
  --margin-4xl: 120px;

  /* Animation timing */
  --animation-standard: 300ms ease-in-out;
  --animation-standard-timing-function: ease-in-out;
  --animation-standard-duration: 300ms;
}
/* --tfd variables. Use these variables in the corgi-x components. */
/* The list is still incomplete and meant to change. */
:root {
  --tfd-font-family:
    'neue-haas-grotesk-display', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --tfd-font-family-text:
    'neue-haas-grotesk-text', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --tfd-font-family-serif: tiempos-text, serif;
  --tfd-font-family-serif-headline: tiempos-headline, serif;

  --tfd-border-radius-xs: 2px;
  --tfd-border-radius-sm: 4px;
  --tfd-border-radius-md: 6px;
  --tfd-border-radius-lg: 20px;

  --tfd-background: var(--chickpea-1);

  /* Use these variables to set max width constraints. See Constraints.tsx for the values. */
  --tfd-constraint-width-sm: 375px;
  --tfd-constraint-width-md: 800px;
  --tfd-constraint-width-lg: 1200px;
  --tfd-constraint-width-xl: 1400px;

  /* Old constraints that should not be used. See https://app.shortcut.com/farmersdog/story/109645. */
  --tfd-max-width-sm: 800px;
  --tfd-max-width: 1200px;

  /* Use this as box-shadow for focus outlines */
  --tfd-outline-box-shadow: 0 0 0 2px var(--charcoal-0);

  /* Use these variables to set the spacing between elements. If you need to access to these values in TypeScript, use the `Spacing` enum instead. */
  --tfd-spacing-xxs: 2px;
  --tfd-spacing-xs: 6px;
  --tfd-spacing-sm: 12px;
  --tfd-spacing-md: 24px;
  --tfd-spacing-lg: 36px;
  --tfd-spacing-xl: 48px;
  --tfd-spacing-xxl: 72px;
  --tfd-spacing-3xl: 96px;
  --tfd-spacing-4xl: 120px;

  --tfd-animation-standard: 300ms ease-in-out;
  --tfd-animation-standard-timing-function: ease-in-out;
  --tfd-animation-standard-duration: 300ms;

  /* Components vars that can be overridden by consumers. */
  --tfd-appnav-z-index: 4;
  --tfd-appnav-height: 74px;
  --tfd-toast-z-index: 5;
  --tfd-lightbox-z-index: auto;
  --tfd-banner-min-height: 52px; /* Height of the banner of a one line of text */

  --tfd-fieldlabel-text-offset: 4px;
  --tfd-fieldlabel-padding: 12px;
  --tfd-fieldlabel-height: 50px;
  --tfd-fieldlabel-radius: 4px;

  --tfd-styledselect-height: 50px;

  /* Override typography in legacy corgi. */
  --tfd-article-72-line-height: 84px;
  --tfd-article-52-line-height: 62px;
  --tfd-article-40-line-height: 48px;
  --tfd-article-28-line-height: 34px;
  --tfd-article-20-line-height: 32px;
  --tfd-article-16-line-height: 26px;
  --tfd-article-12-line-height: 20px;
  --tfd-heading-72-line-height: 84px;
  --tfd-heading-52-line-height: 62px;
  --tfd-heading-40-line-height: 48px;
  --tfd-heading-28-line-height: 34px;
  --tfd-heading-22-line-height: 30px;
  --tfd-heading-16-line-height: 22px;
  --tfd-heading-12-line-height: 18px;
  --tfd-dynamic-bold-heading: 900;
  --tfd-togglebox-badge-padding: 3px var(--margin-sm);
}

/*
Set a @custom-media directive for each breakpoint. 

Import this file to your main CSS file with postcss-custom-media plugin enabled. 

```css
import '@farmersdog/corgi-x/src/styles/custom-media.css';
@media (--viewport-xs) {
  ... styles for extra small viewport..
}
```
*/

@media (min-width: 0px) {
  .spacing_xs-top-margin-none_16bid {
    margin-top: 0;
  }
  .spacing_xs-top-margin-xxs_16bid {
    margin-top: var(--tfd-spacing-xxs);
  }
  .spacing_xs-top-margin-xs_16bid {
    margin-top: var(--tfd-spacing-xs);
  }
  .spacing_xs-top-margin-sm_16bid {
    margin-top: var(--tfd-spacing-sm);
  }
  .spacing_xs-top-margin-md_16bid {
    margin-top: var(--tfd-spacing-md);
  }
  .spacing_xs-top-margin-lg_16bid {
    margin-top: var(--tfd-spacing-lg);
  }
  .spacing_xs-top-margin-xl_16bid {
    margin-top: var(--tfd-spacing-xl);
  }
  .spacing_xs-top-margin-xxl_16bid {
    margin-top: var(--tfd-spacing-xxl);
  }
  .spacing_xs-top-margin-3xl_16bid {
    margin-top: var(--tfd-spacing-3xl);
  }
  .spacing_xs-top-margin-4xl_16bid {
    margin-top: var(--tfd-spacing-4xl);
  }

  .spacing_xs-bottom-margin-none_16bid {
    margin-bottom: 0;
  }
  .spacing_xs-bottom-margin-xxs_16bid {
    margin-bottom: var(--tfd-spacing-xxs);
  }
  .spacing_xs-bottom-margin-xs_16bid {
    margin-bottom: var(--tfd-spacing-xs);
  }
  .spacing_xs-bottom-margin-sm_16bid {
    margin-bottom: var(--tfd-spacing-sm);
  }
  .spacing_xs-bottom-margin-md_16bid {
    margin-bottom: var(--tfd-spacing-md);
  }
  .spacing_xs-bottom-margin-lg_16bid {
    margin-bottom: var(--tfd-spacing-lg);
  }
  .spacing_xs-bottom-margin-xl_16bid {
    margin-bottom: var(--tfd-spacing-xl);
  }
  .spacing_xs-bottom-margin-xxl_16bid {
    margin-bottom: var(--tfd-spacing-xxl);
  }
  .spacing_xs-bottom-margin-3xl_16bid {
    margin-bottom: var(--tfd-spacing-3xl);
  }
  .spacing_xs-bottom-margin-4xl_16bid {
    margin-bottom: var(--tfd-spacing-4xl);
  }

  .spacing_xs-left-margin-none_16bid {
    margin-left: 0;
  }
  .spacing_xs-left-margin-xxs_16bid {
    margin-left: var(--tfd-spacing-xxs);
  }
  .spacing_xs-left-margin-xs_16bid {
    margin-left: var(--tfd-spacing-xs);
  }
  .spacing_xs-left-margin-sm_16bid {
    margin-left: var(--tfd-spacing-sm);
  }
  .spacing_xs-left-margin-md_16bid {
    margin-left: var(--tfd-spacing-md);
  }
  .spacing_xs-left-margin-lg_16bid {
    margin-left: var(--tfd-spacing-lg);
  }
  .spacing_xs-left-margin-xl_16bid {
    margin-left: var(--tfd-spacing-xl);
  }
  .spacing_xs-left-margin-xxl_16bid {
    margin-left: var(--tfd-spacing-xxl);
  }
  .spacing_xs-left-margin-3xl_16bid {
    margin-left: var(--tfd-spacing-3xl);
  }
  .spacing_xs-left-margin-4xl_16bid {
    margin-left: var(--tfd-spacing-4xl);
  }

  .spacing_xs-right-margin-none_16bid {
    margin-right: 0;
  }
  .spacing_xs-right-margin-xxs_16bid {
    margin-right: var(--tfd-spacing-xxs);
  }
  .spacing_xs-right-margin-xs_16bid {
    margin-right: var(--tfd-spacing-xs);
  }
  .spacing_xs-right-margin-sm_16bid {
    margin-right: var(--tfd-spacing-sm);
  }
  .spacing_xs-right-margin-md_16bid {
    margin-right: var(--tfd-spacing-md);
  }
  .spacing_xs-right-margin-lg_16bid {
    margin-right: var(--tfd-spacing-lg);
  }
  .spacing_xs-right-margin-xl_16bid {
    margin-right: var(--tfd-spacing-xl);
  }
  .spacing_xs-right-margin-xxl_16bid {
    margin-right: var(--tfd-spacing-xxl);
  }
  .spacing_xs-right-margin-3xl_16bid {
    margin-right: var(--tfd-spacing-3xl);
  }
  .spacing_xs-right-margin-4xl_16bid {
    margin-right: var(--tfd-spacing-4xl);
  }
}

@media (min-width: 375px) {
  .spacing_sm-top-margin-none_16bid {
    margin-top: 0;
  }
  .spacing_sm-top-margin-xxs_16bid {
    margin-top: var(--tfd-spacing-xxs);
  }
  .spacing_sm-top-margin-xs_16bid {
    margin-top: var(--tfd-spacing-xs);
  }
  .spacing_sm-top-margin-sm_16bid {
    margin-top: var(--tfd-spacing-sm);
  }
  .spacing_sm-top-margin-md_16bid {
    margin-top: var(--tfd-spacing-md);
  }
  .spacing_sm-top-margin-lg_16bid {
    margin-top: var(--tfd-spacing-lg);
  }
  .spacing_sm-top-margin-xl_16bid {
    margin-top: var(--tfd-spacing-xl);
  }
  .spacing_sm-top-margin-xxl_16bid {
    margin-top: var(--tfd-spacing-xxl);
  }
  .spacing_sm-top-margin-3xl_16bid {
    margin-top: var(--tfd-spacing-3xl);
  }
  .spacing_sm-top-margin-4xl_16bid {
    margin-top: var(--tfd-spacing-4xl);
  }

  .spacing_sm-bottom-margin-none_16bid {
    margin-bottom: 0;
  }
  .spacing_sm-bottom-margin-xxs_16bid {
    margin-bottom: var(--tfd-spacing-xxs);
  }
  .spacing_sm-bottom-margin-xs_16bid {
    margin-bottom: var(--tfd-spacing-xs);
  }
  .spacing_sm-bottom-margin-sm_16bid {
    margin-bottom: var(--tfd-spacing-sm);
  }
  .spacing_sm-bottom-margin-md_16bid {
    margin-bottom: var(--tfd-spacing-md);
  }
  .spacing_sm-bottom-margin-lg_16bid {
    margin-bottom: var(--tfd-spacing-lg);
  }
  .spacing_sm-bottom-margin-xl_16bid {
    margin-bottom: var(--tfd-spacing-xl);
  }
  .spacing_sm-bottom-margin-xxl_16bid {
    margin-bottom: var(--tfd-spacing-xxl);
  }
  .spacing_sm-bottom-margin-3xl_16bid {
    margin-bottom: var(--tfd-spacing-3xl);
  }
  .spacing_sm-bottom-margin-4xl_16bid {
    margin-bottom: var(--tfd-spacing-4xl);
  }

  .spacing_sm-right-margin-none_16bid {
    margin-right: 0;
  }
  .spacing_sm-right-margin-xxs_16bid {
    margin-right: var(--tfd-spacing-xxs);
  }
  .spacing_sm-right-margin-xs_16bid {
    margin-right: var(--tfd-spacing-xs);
  }
  .spacing_sm-right-margin-sm_16bid {
    margin-right: var(--tfd-spacing-sm);
  }
  .spacing_sm-right-margin-md_16bid {
    margin-right: var(--tfd-spacing-md);
  }
  .spacing_sm-right-margin-lg_16bid {
    margin-right: var(--tfd-spacing-lg);
  }
  .spacing_sm-right-margin-xl_16bid {
    margin-right: var(--tfd-spacing-xl);
  }
  .spacing_sm-right-margin-xxl_16bid {
    margin-right: var(--tfd-spacing-xxl);
  }
  .spacing_sm-right-margin-3xl_16bid {
    margin-right: var(--tfd-spacing-3xl);
  }
  .spacing_sm-right-margin-4xl_16bid {
    margin-right: var(--tfd-spacing-4xl);
  }

  .spacing_sm-left-margin-none_16bid {
    margin-left: 0;
  }
  .spacing_sm-left-margin-xxs_16bid {
    margin-left: var(--tfd-spacing-xxs);
  }
  .spacing_sm-left-margin-xs_16bid {
    margin-left: var(--tfd-spacing-xs);
  }
  .spacing_sm-left-margin-sm_16bid {
    margin-left: var(--tfd-spacing-sm);
  }
  .spacing_sm-left-margin-md_16bid {
    margin-left: var(--tfd-spacing-md);
  }
  .spacing_sm-left-margin-lg_16bid {
    margin-left: var(--tfd-spacing-lg);
  }
  .spacing_sm-left-margin-xl_16bid {
    margin-left: var(--tfd-spacing-xl);
  }
  .spacing_sm-left-margin-xxl_16bid {
    margin-left: var(--tfd-spacing-xxl);
  }
  .spacing_sm-left-margin-3xl_16bid {
    margin-left: var(--tfd-spacing-3xl);
  }
  .spacing_sm-left-margin-4xl_16bid {
    margin-left: var(--tfd-spacing-4xl);
  }
}

@media (min-width: 710px) {
  .spacing_md-top-margin-none_16bid {
    margin-top: 0;
  }
  .spacing_md-top-margin-xxs_16bid {
    margin-top: var(--tfd-spacing-xxs);
  }
  .spacing_md-top-margin-xs_16bid {
    margin-top: var(--tfd-spacing-xs);
  }
  .spacing_md-top-margin-sm_16bid {
    margin-top: var(--tfd-spacing-sm);
  }
  .spacing_md-top-margin-md_16bid {
    margin-top: var(--tfd-spacing-md);
  }
  .spacing_md-top-margin-lg_16bid {
    margin-top: var(--tfd-spacing-lg);
  }
  .spacing_md-top-margin-xl_16bid {
    margin-top: var(--tfd-spacing-xl);
  }
  .spacing_md-top-margin-xxl_16bid {
    margin-top: var(--tfd-spacing-xxl);
  }
  .spacing_md-top-margin-3xl_16bid {
    margin-top: var(--tfd-spacing-3xl);
  }
  .spacing_md-top-margin-4xl_16bid {
    margin-top: var(--tfd-spacing-4xl);
  }

  .spacing_md-bottom-margin-none_16bid {
    margin-bottom: 0;
  }
  .spacing_md-bottom-margin-xxs_16bid {
    margin-bottom: var(--tfd-spacing-xxs);
  }
  .spacing_md-bottom-margin-xs_16bid {
    margin-bottom: var(--tfd-spacing-xs);
  }
  .spacing_md-bottom-margin-sm_16bid {
    margin-bottom: var(--tfd-spacing-sm);
  }
  .spacing_md-bottom-margin-md_16bid {
    margin-bottom: var(--tfd-spacing-md);
  }
  .spacing_md-bottom-margin-lg_16bid {
    margin-bottom: var(--tfd-spacing-lg);
  }
  .spacing_md-bottom-margin-xl_16bid {
    margin-bottom: var(--tfd-spacing-xl);
  }
  .spacing_md-bottom-margin-xxl_16bid {
    margin-bottom: var(--tfd-spacing-xxl);
  }
  .spacing_md-bottom-margin-3xl_16bid {
    margin-bottom: var(--tfd-spacing-3xl);
  }
  .spacing_md-bottom-margin-4xl_16bid {
    margin-bottom: var(--tfd-spacing-4xl);
  }

  .spacing_md-right-margin-none_16bid {
    margin-right: 0;
  }
  .spacing_md-right-margin-xxs_16bid {
    margin-right: var(--tfd-spacing-xxs);
  }
  .spacing_md-right-margin-xs_16bid {
    margin-right: var(--tfd-spacing-xs);
  }
  .spacing_md-right-margin-sm_16bid {
    margin-right: var(--tfd-spacing-sm);
  }
  .spacing_md-right-margin-md_16bid {
    margin-right: var(--tfd-spacing-md);
  }
  .spacing_md-right-margin-lg_16bid {
    margin-right: var(--tfd-spacing-lg);
  }
  .spacing_md-right-margin-xl_16bid {
    margin-right: var(--tfd-spacing-xl);
  }
  .spacing_md-right-margin-xxl_16bid {
    margin-right: var(--tfd-spacing-xxl);
  }
  .spacing_md-right-margin-3xl_16bid {
    margin-right: var(--tfd-spacing-3xl);
  }
  .spacing_md-right-margin-4xl_16bid {
    margin-right: var(--tfd-spacing-4xl);
  }

  .spacing_md-left-margin-none_16bid {
    margin-left: 0;
  }
  .spacing_md-left-margin-xxs_16bid {
    margin-left: var(--tfd-spacing-xxs);
  }
  .spacing_md-left-margin-xs_16bid {
    margin-left: var(--tfd-spacing-xs);
  }
  .spacing_md-left-margin-sm_16bid {
    margin-left: var(--tfd-spacing-sm);
  }
  .spacing_md-left-margin-md_16bid {
    margin-left: var(--tfd-spacing-md);
  }
  .spacing_md-left-margin-lg_16bid {
    margin-left: var(--tfd-spacing-lg);
  }
  .spacing_md-left-margin-xl_16bid {
    margin-left: var(--tfd-spacing-xl);
  }
  .spacing_md-left-margin-xxl_16bid {
    margin-left: var(--tfd-spacing-xxl);
  }
  .spacing_md-left-margin-3xl_16bid {
    margin-left: var(--tfd-spacing-3xl);
  }
  .spacing_md-left-margin-4xl_16bid {
    margin-left: var(--tfd-spacing-4xl);
  }
}

@media (min-width: 1024px) {
  .spacing_lg-top-margin-none_16bid {
    margin-top: 0;
  }
  .spacing_lg-top-margin-xxs_16bid {
    margin-top: var(--tfd-spacing-xxs);
  }
  .spacing_lg-top-margin-xs_16bid {
    margin-top: var(--tfd-spacing-xs);
  }
  .spacing_lg-top-margin-sm_16bid {
    margin-top: var(--tfd-spacing-sm);
  }
  .spacing_lg-top-margin-md_16bid {
    margin-top: var(--tfd-spacing-md);
  }
  .spacing_lg-top-margin-lg_16bid {
    margin-top: var(--tfd-spacing-lg);
  }
  .spacing_lg-top-margin-xl_16bid {
    margin-top: var(--tfd-spacing-xl);
  }
  .spacing_lg-top-margin-xxl_16bid {
    margin-top: var(--tfd-spacing-xxl);
  }
  .spacing_lg-top-margin-3xl_16bid {
    margin-top: var(--tfd-spacing-3xl);
  }
  .spacing_lg-top-margin-4xl_16bid {
    margin-top: var(--tfd-spacing-4xl);
  }

  .spacing_lg-bottom-margin-none_16bid {
    margin-bottom: 0;
  }
  .spacing_lg-bottom-margin-xxs_16bid {
    margin-bottom: var(--tfd-spacing-xxs);
  }
  .spacing_lg-bottom-margin-xs_16bid {
    margin-bottom: var(--tfd-spacing-xs);
  }
  .spacing_lg-bottom-margin-sm_16bid {
    margin-bottom: var(--tfd-spacing-sm);
  }
  .spacing_lg-bottom-margin-md_16bid {
    margin-bottom: var(--tfd-spacing-md);
  }
  .spacing_lg-bottom-margin-lg_16bid {
    margin-bottom: var(--tfd-spacing-lg);
  }
  .spacing_lg-bottom-margin-xl_16bid {
    margin-bottom: var(--tfd-spacing-xl);
  }
  .spacing_lg-bottom-margin-xxl_16bid {
    margin-bottom: var(--tfd-spacing-xxl);
  }
  .spacing_lg-bottom-margin-3xl_16bid {
    margin-bottom: var(--tfd-spacing-3xl);
  }
  .spacing_lg-bottom-margin-4xl_16bid {
    margin-bottom: var(--tfd-spacing-4xl);
  }

  .spacing_lg-right-margin-none_16bid {
    margin-right: 0;
  }
  .spacing_lg-right-margin-xxs_16bid {
    margin-right: var(--tfd-spacing-xxs);
  }
  .spacing_lg-right-margin-xs_16bid {
    margin-right: var(--tfd-spacing-xs);
  }
  .spacing_lg-right-margin-sm_16bid {
    margin-right: var(--tfd-spacing-sm);
  }
  .spacing_lg-right-margin-md_16bid {
    margin-right: var(--tfd-spacing-md);
  }
  .spacing_lg-right-margin-lg_16bid {
    margin-right: var(--tfd-spacing-lg);
  }
  .spacing_lg-right-margin-xl_16bid {
    margin-right: var(--tfd-spacing-xl);
  }
  .spacing_lg-right-margin-xxl_16bid {
    margin-right: var(--tfd-spacing-xxl);
  }
  .spacing_lg-right-margin-3xl_16bid {
    margin-right: var(--tfd-spacing-3xl);
  }
  .spacing_lg-right-margin-4xl_16bid {
    margin-right: var(--tfd-spacing-4xl);
  }

  .spacing_lg-left-margin-none_16bid {
    margin-left: 0;
  }
  .spacing_lg-left-margin-xxs_16bid {
    margin-left: var(--tfd-spacing-xxs);
  }
  .spacing_lg-left-margin-xs_16bid {
    margin-left: var(--tfd-spacing-xs);
  }
  .spacing_lg-left-margin-sm_16bid {
    margin-left: var(--tfd-spacing-sm);
  }
  .spacing_lg-left-margin-md_16bid {
    margin-left: var(--tfd-spacing-md);
  }
  .spacing_lg-left-margin-lg_16bid {
    margin-left: var(--tfd-spacing-lg);
  }
  .spacing_lg-left-margin-xl_16bid {
    margin-left: var(--tfd-spacing-xl);
  }
  .spacing_lg-left-margin-xxl_16bid {
    margin-left: var(--tfd-spacing-xxl);
  }
  .spacing_lg-left-margin-3xl_16bid {
    margin-left: var(--tfd-spacing-3xl);
  }
  .spacing_lg-left-margin-4xl_16bid {
    margin-left: var(--tfd-spacing-4xl);
  }
}

@media (min-width: 1400px) {
  .spacing_xl-top-margin-none_16bid {
    margin-top: 0;
  }
  .spacing_xl-top-margin-xxs_16bid {
    margin-top: var(--tfd-spacing-xxs);
  }
  .spacing_xl-top-margin-xs_16bid {
    margin-top: var(--tfd-spacing-xs);
  }
  .spacing_xl-top-margin-sm_16bid {
    margin-top: var(--tfd-spacing-sm);
  }
  .spacing_xl-top-margin-md_16bid {
    margin-top: var(--tfd-spacing-md);
  }
  .spacing_xl-top-margin-lg_16bid {
    margin-top: var(--tfd-spacing-lg);
  }
  .spacing_xl-top-margin-xl_16bid {
    margin-top: var(--tfd-spacing-xl);
  }
  .spacing_xl-top-margin-xxl_16bid {
    margin-top: var(--tfd-spacing-xxl);
  }
  .spacing_xl-top-margin-3xl_16bid {
    margin-top: var(--tfd-spacing-3xl);
  }
  .spacing_xl-top-margin-4xl_16bid {
    margin-top: var(--tfd-spacing-4xl);
  }

  .spacing_xl-bottom-margin-none_16bid {
    margin-bottom: 0;
  }
  .spacing_xl-bottom-margin-xxs_16bid {
    margin-bottom: var(--tfd-spacing-xxs);
  }
  .spacing_xl-bottom-margin-xs_16bid {
    margin-bottom: var(--tfd-spacing-xs);
  }
  .spacing_xl-bottom-margin-sm_16bid {
    margin-bottom: var(--tfd-spacing-sm);
  }
  .spacing_xl-bottom-margin-md_16bid {
    margin-bottom: var(--tfd-spacing-md);
  }
  .spacing_xl-bottom-margin-lg_16bid {
    margin-bottom: var(--tfd-spacing-lg);
  }
  .spacing_xl-bottom-margin-xl_16bid {
    margin-bottom: var(--tfd-spacing-xl);
  }
  .spacing_xl-bottom-margin-xxl_16bid {
    margin-bottom: var(--tfd-spacing-xxl);
  }
  .spacing_xl-bottom-margin-3xl_16bid {
    margin-bottom: var(--tfd-spacing-3xl);
  }
  .spacing_xl-bottom-margin-4xl_16bid {
    margin-bottom: var(--tfd-spacing-4xl);
  }

  .spacing_xl-right-margin-none_16bid {
    margin-right: 0;
  }
  .spacing_xl-right-margin-xxs_16bid {
    margin-right: var(--tfd-spacing-xxs);
  }
  .spacing_xl-right-margin-xs_16bid {
    margin-right: var(--tfd-spacing-xs);
  }
  .spacing_xl-right-margin-sm_16bid {
    margin-right: var(--tfd-spacing-sm);
  }
  .spacing_xl-right-margin-md_16bid {
    margin-right: var(--tfd-spacing-md);
  }
  .spacing_xl-right-margin-lg_16bid {
    margin-right: var(--tfd-spacing-lg);
  }
  .spacing_xl-right-margin-xl_16bid {
    margin-right: var(--tfd-spacing-xl);
  }
  .spacing_xl-right-margin-xxl_16bid {
    margin-right: var(--tfd-spacing-xxl);
  }
  .spacing_xl-right-margin-3xl_16bid {
    margin-right: var(--tfd-spacing-3xl);
  }
  .spacing_xl-right-margin-4xl_16bid {
    margin-right: var(--tfd-spacing-4xl);
  }

  .spacing_xl-left-margin-none_16bid {
    margin-left: 0;
  }
  .spacing_xl-left-margin-xxs_16bid {
    margin-left: var(--tfd-spacing-xxs);
  }
  .spacing_xl-left-margin-xs_16bid {
    margin-left: var(--tfd-spacing-xs);
  }
  .spacing_xl-left-margin-sm_16bid {
    margin-left: var(--tfd-spacing-sm);
  }
  .spacing_xl-left-margin-md_16bid {
    margin-left: var(--tfd-spacing-md);
  }
  .spacing_xl-left-margin-lg_16bid {
    margin-left: var(--tfd-spacing-lg);
  }
  .spacing_xl-left-margin-xl_16bid {
    margin-left: var(--tfd-spacing-xl);
  }
  .spacing_xl-left-margin-xxl_16bid {
    margin-left: var(--tfd-spacing-xxl);
  }
  .spacing_xl-left-margin-3xl_16bid {
    margin-left: var(--tfd-spacing-3xl);
  }
  .spacing_xl-left-margin-4xl_16bid {
    margin-left: var(--tfd-spacing-4xl);
  }
}

@media (min-width: 0px) {
  .spacing_xs-top-padding-none_16bid {
    padding-top: 0;
  }
  .spacing_xs-top-padding-xxs_16bid {
    padding-top: var(--tfd-spacing-xxs);
  }
  .spacing_xs-top-padding-xs_16bid {
    padding-top: var(--tfd-spacing-xs);
  }
  .spacing_xs-top-padding-sm_16bid {
    padding-top: var(--tfd-spacing-sm);
  }
  .spacing_xs-top-padding-md_16bid {
    padding-top: var(--tfd-spacing-md);
  }
  .spacing_xs-top-padding-lg_16bid {
    padding-top: var(--tfd-spacing-lg);
  }
  .spacing_xs-top-padding-xl_16bid {
    padding-top: var(--tfd-spacing-xl);
  }
  .spacing_xs-top-padding-xxl_16bid {
    padding-top: var(--tfd-spacing-xxl);
  }
  .spacing_xs-top-padding-3xl_16bid {
    padding-top: var(--tfd-spacing-3xl);
  }
  .spacing_xs-top-padding-4xl_16bid {
    padding-top: var(--tfd-spacing-4xl);
  }

  .spacing_xs-bottom-padding-none_16bid {
    padding-bottom: 0;
  }
  .spacing_xs-bottom-padding-xxs_16bid {
    padding-bottom: var(--tfd-spacing-xxs);
  }
  .spacing_xs-bottom-padding-xs_16bid {
    padding-bottom: var(--tfd-spacing-xs);
  }
  .spacing_xs-bottom-padding-sm_16bid {
    padding-bottom: var(--tfd-spacing-sm);
  }
  .spacing_xs-bottom-padding-md_16bid {
    padding-bottom: var(--tfd-spacing-md);
  }
  .spacing_xs-bottom-padding-lg_16bid {
    padding-bottom: var(--tfd-spacing-lg);
  }
  .spacing_xs-bottom-padding-xl_16bid {
    padding-bottom: var(--tfd-spacing-xl);
  }
  .spacing_xs-bottom-padding-xxl_16bid {
    padding-bottom: var(--tfd-spacing-xxl);
  }
  .spacing_xs-bottom-padding-3xl_16bid {
    padding-bottom: var(--tfd-spacing-3xl);
  }
  .spacing_xs-bottom-padding-4xl_16bid {
    padding-bottom: var(--tfd-spacing-4xl);
  }

  .spacing_xs-left-padding-none_16bid {
    padding-left: 0;
  }
  .spacing_xs-left-padding-xxs_16bid {
    padding-left: var(--tfd-spacing-xxs);
  }
  .spacing_xs-left-padding-xs_16bid {
    padding-left: var(--tfd-spacing-xs);
  }
  .spacing_xs-left-padding-sm_16bid {
    padding-left: var(--tfd-spacing-sm);
  }
  .spacing_xs-left-padding-md_16bid {
    padding-left: var(--tfd-spacing-md);
  }
  .spacing_xs-left-padding-lg_16bid {
    padding-left: var(--tfd-spacing-lg);
  }
  .spacing_xs-left-padding-xl_16bid {
    padding-left: var(--tfd-spacing-xl);
  }
  .spacing_xs-left-padding-xxl_16bid {
    padding-left: var(--tfd-spacing-xxl);
  }
  .spacing_xs-left-padding-3xl_16bid {
    padding-left: var(--tfd-spacing-3xl);
  }
  .spacing_xs-left-padding-4xl_16bid {
    padding-left: var(--tfd-spacing-4xl);
  }

  .spacing_xs-right-padding-none_16bid {
    padding-right: 0;
  }
  .spacing_xs-right-padding-xxs_16bid {
    padding-right: var(--tfd-spacing-xxs);
  }
  .spacing_xs-right-padding-xs_16bid {
    padding-right: var(--tfd-spacing-xs);
  }
  .spacing_xs-right-padding-sm_16bid {
    padding-right: var(--tfd-spacing-sm);
  }
  .spacing_xs-right-padding-md_16bid {
    padding-right: var(--tfd-spacing-md);
  }
  .spacing_xs-right-padding-lg_16bid {
    padding-right: var(--tfd-spacing-lg);
  }
  .spacing_xs-right-padding-xl_16bid {
    padding-right: var(--tfd-spacing-xl);
  }
  .spacing_xs-right-padding-xxl_16bid {
    padding-right: var(--tfd-spacing-xxl);
  }
  .spacing_xs-right-padding-3xl_16bid {
    padding-right: var(--tfd-spacing-3xl);
  }
  .spacing_xs-right-padding-4xl_16bid {
    padding-right: var(--tfd-spacing-4xl);
  }
}

@media (min-width: 375px) {
  .spacing_sm-top-padding-none_16bid {
    padding-top: 0;
  }
  .spacing_sm-top-padding-xxs_16bid {
    padding-top: var(--tfd-spacing-xxs);
  }
  .spacing_sm-top-padding-xs_16bid {
    padding-top: var(--tfd-spacing-xs);
  }
  .spacing_sm-top-padding-sm_16bid {
    padding-top: var(--tfd-spacing-sm);
  }
  .spacing_sm-top-padding-md_16bid {
    padding-top: var(--tfd-spacing-md);
  }
  .spacing_sm-top-padding-lg_16bid {
    padding-top: var(--tfd-spacing-lg);
  }
  .spacing_sm-top-padding-xl_16bid {
    padding-top: var(--tfd-spacing-xl);
  }
  .spacing_sm-top-padding-xxl_16bid {
    padding-top: var(--tfd-spacing-xxl);
  }
  .spacing_sm-top-padding-3xl_16bid {
    padding-top: var(--tfd-spacing-3xl);
  }
  .spacing_sm-top-padding-4xl_16bid {
    padding-top: var(--tfd-spacing-4xl);
  }

  .spacing_sm-bottom-padding-none_16bid {
    padding-bottom: 0;
  }
  .spacing_sm-bottom-padding-xxs_16bid {
    padding-bottom: var(--tfd-spacing-xxs);
  }
  .spacing_sm-bottom-padding-xs_16bid {
    padding-bottom: var(--tfd-spacing-xs);
  }
  .spacing_sm-bottom-padding-sm_16bid {
    padding-bottom: var(--tfd-spacing-sm);
  }
  .spacing_sm-bottom-padding-md_16bid {
    padding-bottom: var(--tfd-spacing-md);
  }
  .spacing_sm-bottom-padding-lg_16bid {
    padding-bottom: var(--tfd-spacing-lg);
  }
  .spacing_sm-bottom-padding-xl_16bid {
    padding-bottom: var(--tfd-spacing-xl);
  }
  .spacing_sm-bottom-padding-xxl_16bid {
    padding-bottom: var(--tfd-spacing-xxl);
  }
  .spacing_sm-bottom-padding-3xl_16bid {
    padding-bottom: var(--tfd-spacing-3xl);
  }
  .spacing_sm-bottom-padding-4xl_16bid {
    padding-bottom: var(--tfd-spacing-4xl);
  }

  .spacing_sm-right-padding-none_16bid {
    padding-right: 0;
  }
  .spacing_sm-right-padding-xxs_16bid {
    padding-right: var(--tfd-spacing-xxs);
  }
  .spacing_sm-right-padding-xs_16bid {
    padding-right: var(--tfd-spacing-xs);
  }
  .spacing_sm-right-padding-sm_16bid {
    padding-right: var(--tfd-spacing-sm);
  }
  .spacing_sm-right-padding-md_16bid {
    padding-right: var(--tfd-spacing-md);
  }
  .spacing_sm-right-padding-lg_16bid {
    padding-right: var(--tfd-spacing-lg);
  }
  .spacing_sm-right-padding-xl_16bid {
    padding-right: var(--tfd-spacing-xl);
  }
  .spacing_sm-right-padding-xxl_16bid {
    padding-right: var(--tfd-spacing-xxl);
  }
  .spacing_sm-right-padding-3xl_16bid {
    padding-right: var(--tfd-spacing-3xl);
  }
  .spacing_sm-right-padding-4xl_16bid {
    padding-right: var(--tfd-spacing-4xl);
  }

  .spacing_sm-left-padding-none_16bid {
    padding-left: 0;
  }
  .spacing_sm-left-padding-xxs_16bid {
    padding-left: var(--tfd-spacing-xxs);
  }
  .spacing_sm-left-padding-xs_16bid {
    padding-left: var(--tfd-spacing-xs);
  }
  .spacing_sm-left-padding-sm_16bid {
    padding-left: var(--tfd-spacing-sm);
  }
  .spacing_sm-left-padding-md_16bid {
    padding-left: var(--tfd-spacing-md);
  }
  .spacing_sm-left-padding-lg_16bid {
    padding-left: var(--tfd-spacing-lg);
  }
  .spacing_sm-left-padding-xl_16bid {
    padding-left: var(--tfd-spacing-xl);
  }
  .spacing_sm-left-padding-xxl_16bid {
    padding-left: var(--tfd-spacing-xxl);
  }
  .spacing_sm-left-padding-3xl_16bid {
    padding-left: var(--tfd-spacing-3xl);
  }
  .spacing_sm-left-padding-4xl_16bid {
    padding-left: var(--tfd-spacing-4xl);
  }
}

@media (min-width: 710px) {
  .spacing_md-top-padding-none_16bid {
    padding-top: 0;
  }
  .spacing_md-top-padding-xxs_16bid {
    padding-top: var(--tfd-spacing-xxs);
  }
  .spacing_md-top-padding-xs_16bid {
    padding-top: var(--tfd-spacing-xs);
  }
  .spacing_md-top-padding-sm_16bid {
    padding-top: var(--tfd-spacing-sm);
  }
  .spacing_md-top-padding-md_16bid {
    padding-top: var(--tfd-spacing-md);
  }
  .spacing_md-top-padding-lg_16bid {
    padding-top: var(--tfd-spacing-lg);
  }
  .spacing_md-top-padding-xl_16bid {
    padding-top: var(--tfd-spacing-xl);
  }
  .spacing_md-top-padding-xxl_16bid {
    padding-top: var(--tfd-spacing-xxl);
  }
  .spacing_md-top-padding-3xl_16bid {
    padding-top: var(--tfd-spacing-3xl);
  }
  .spacing_md-top-padding-4xl_16bid {
    padding-top: var(--tfd-spacing-4xl);
  }

  .spacing_md-bottom-padding-none_16bid {
    padding-bottom: 0;
  }
  .spacing_md-bottom-padding-xxs_16bid {
    padding-bottom: var(--tfd-spacing-xxs);
  }
  .spacing_md-bottom-padding-xs_16bid {
    padding-bottom: var(--tfd-spacing-xs);
  }
  .spacing_md-bottom-padding-sm_16bid {
    padding-bottom: var(--tfd-spacing-sm);
  }
  .spacing_md-bottom-padding-md_16bid {
    padding-bottom: var(--tfd-spacing-md);
  }
  .spacing_md-bottom-padding-lg_16bid {
    padding-bottom: var(--tfd-spacing-lg);
  }
  .spacing_md-bottom-padding-xl_16bid {
    padding-bottom: var(--tfd-spacing-xl);
  }
  .spacing_md-bottom-padding-xxl_16bid {
    padding-bottom: var(--tfd-spacing-xxl);
  }
  .spacing_md-bottom-padding-3xl_16bid {
    padding-bottom: var(--tfd-spacing-3xl);
  }
  .spacing_md-bottom-padding-4xl_16bid {
    padding-bottom: var(--tfd-spacing-4xl);
  }

  .spacing_md-right-padding-none_16bid {
    padding-right: 0;
  }
  .spacing_md-right-padding-xxs_16bid {
    padding-right: var(--tfd-spacing-xxs);
  }
  .spacing_md-right-padding-xs_16bid {
    padding-right: var(--tfd-spacing-xs);
  }
  .spacing_md-right-padding-sm_16bid {
    padding-right: var(--tfd-spacing-sm);
  }
  .spacing_md-right-padding-md_16bid {
    padding-right: var(--tfd-spacing-md);
  }
  .spacing_md-right-padding-lg_16bid {
    padding-right: var(--tfd-spacing-lg);
  }
  .spacing_md-right-padding-xl_16bid {
    padding-right: var(--tfd-spacing-xl);
  }
  .spacing_md-right-padding-xxl_16bid {
    padding-right: var(--tfd-spacing-xxl);
  }
  .spacing_md-right-padding-3xl_16bid {
    padding-right: var(--tfd-spacing-3xl);
  }
  .spacing_md-right-padding-4xl_16bid {
    padding-right: var(--tfd-spacing-4xl);
  }

  .spacing_md-left-padding-none_16bid {
    padding-left: 0;
  }
  .spacing_md-left-padding-xxs_16bid {
    padding-left: var(--tfd-spacing-xxs);
  }
  .spacing_md-left-padding-xs_16bid {
    padding-left: var(--tfd-spacing-xs);
  }
  .spacing_md-left-padding-sm_16bid {
    padding-left: var(--tfd-spacing-sm);
  }
  .spacing_md-left-padding-md_16bid {
    padding-left: var(--tfd-spacing-md);
  }
  .spacing_md-left-padding-lg_16bid {
    padding-left: var(--tfd-spacing-lg);
  }
  .spacing_md-left-padding-xl_16bid {
    padding-left: var(--tfd-spacing-xl);
  }
  .spacing_md-left-padding-xxl_16bid {
    padding-left: var(--tfd-spacing-xxl);
  }
  .spacing_md-left-padding-3xl_16bid {
    padding-left: var(--tfd-spacing-3xl);
  }
  .spacing_md-left-padding-4xl_16bid {
    padding-left: var(--tfd-spacing-4xl);
  }
}

@media (min-width: 1024px) {
  .spacing_lg-top-padding-none_16bid {
    padding-top: 0;
  }
  .spacing_lg-top-padding-xxs_16bid {
    padding-top: var(--tfd-spacing-xxs);
  }
  .spacing_lg-top-padding-xs_16bid {
    padding-top: var(--tfd-spacing-xs);
  }
  .spacing_lg-top-padding-sm_16bid {
    padding-top: var(--tfd-spacing-sm);
  }
  .spacing_lg-top-padding-md_16bid {
    padding-top: var(--tfd-spacing-md);
  }
  .spacing_lg-top-padding-lg_16bid {
    padding-top: var(--tfd-spacing-lg);
  }
  .spacing_lg-top-padding-xl_16bid {
    padding-top: var(--tfd-spacing-xl);
  }
  .spacing_lg-top-padding-xxl_16bid {
    padding-top: var(--tfd-spacing-xxl);
  }
  .spacing_lg-top-padding-3xl_16bid {
    padding-top: var(--tfd-spacing-3xl);
  }
  .spacing_lg-top-padding-4xl_16bid {
    padding-top: var(--tfd-spacing-4xl);
  }

  .spacing_lg-bottom-padding-none_16bid {
    padding-bottom: 0;
  }
  .spacing_lg-bottom-padding-xxs_16bid {
    padding-bottom: var(--tfd-spacing-xxs);
  }
  .spacing_lg-bottom-padding-xs_16bid {
    padding-bottom: var(--tfd-spacing-xs);
  }
  .spacing_lg-bottom-padding-sm_16bid {
    padding-bottom: var(--tfd-spacing-sm);
  }
  .spacing_lg-bottom-padding-md_16bid {
    padding-bottom: var(--tfd-spacing-md);
  }
  .spacing_lg-bottom-padding-lg_16bid {
    padding-bottom: var(--tfd-spacing-lg);
  }
  .spacing_lg-bottom-padding-xl_16bid {
    padding-bottom: var(--tfd-spacing-xl);
  }
  .spacing_lg-bottom-padding-xxl_16bid {
    padding-bottom: var(--tfd-spacing-xxl);
  }
  .spacing_lg-bottom-padding-3xl_16bid {
    padding-bottom: var(--tfd-spacing-3xl);
  }
  .spacing_lg-bottom-padding-4xl_16bid {
    padding-bottom: var(--tfd-spacing-4xl);
  }

  .spacing_lg-right-padding-none_16bid {
    padding-right: 0;
  }
  .spacing_lg-right-padding-xxs_16bid {
    padding-right: var(--tfd-spacing-xxs);
  }
  .spacing_lg-right-padding-xs_16bid {
    padding-right: var(--tfd-spacing-xs);
  }
  .spacing_lg-right-padding-sm_16bid {
    padding-right: var(--tfd-spacing-sm);
  }
  .spacing_lg-right-padding-md_16bid {
    padding-right: var(--tfd-spacing-md);
  }
  .spacing_lg-right-padding-lg_16bid {
    padding-right: var(--tfd-spacing-lg);
  }
  .spacing_lg-right-padding-xl_16bid {
    padding-right: var(--tfd-spacing-xl);
  }
  .spacing_lg-right-padding-xxl_16bid {
    padding-right: var(--tfd-spacing-xxl);
  }
  .spacing_lg-right-padding-3xl_16bid {
    padding-right: var(--tfd-spacing-3xl);
  }
  .spacing_lg-right-padding-4xl_16bid {
    padding-right: var(--tfd-spacing-4xl);
  }

  .spacing_lg-left-padding-none_16bid {
    padding-left: 0;
  }
  .spacing_lg-left-padding-xxs_16bid {
    padding-left: var(--tfd-spacing-xxs);
  }
  .spacing_lg-left-padding-xs_16bid {
    padding-left: var(--tfd-spacing-xs);
  }
  .spacing_lg-left-padding-sm_16bid {
    padding-left: var(--tfd-spacing-sm);
  }
  .spacing_lg-left-padding-md_16bid {
    padding-left: var(--tfd-spacing-md);
  }
  .spacing_lg-left-padding-lg_16bid {
    padding-left: var(--tfd-spacing-lg);
  }
  .spacing_lg-left-padding-xl_16bid {
    padding-left: var(--tfd-spacing-xl);
  }
  .spacing_lg-left-padding-xxl_16bid {
    padding-left: var(--tfd-spacing-xxl);
  }
  .spacing_lg-left-padding-3xl_16bid {
    padding-left: var(--tfd-spacing-3xl);
  }
  .spacing_lg-left-padding-4xl_16bid {
    padding-left: var(--tfd-spacing-4xl);
  }
}

@media (min-width: 1400px) {
  .spacing_xl-top-padding-none_16bid {
    padding-top: 0;
  }
  .spacing_xl-top-padding-xxs_16bid {
    padding-top: var(--tfd-spacing-xxs);
  }
  .spacing_xl-top-padding-xs_16bid {
    padding-top: var(--tfd-spacing-xs);
  }
  .spacing_xl-top-padding-sm_16bid {
    padding-top: var(--tfd-spacing-sm);
  }
  .spacing_xl-top-padding-md_16bid {
    padding-top: var(--tfd-spacing-md);
  }
  .spacing_xl-top-padding-lg_16bid {
    padding-top: var(--tfd-spacing-lg);
  }
  .spacing_xl-top-padding-xl_16bid {
    padding-top: var(--tfd-spacing-xl);
  }
  .spacing_xl-top-padding-xxl_16bid {
    padding-top: var(--tfd-spacing-xxl);
  }
  .spacing_xl-top-padding-3xl_16bid {
    padding-top: var(--tfd-spacing-3xl);
  }
  .spacing_xl-top-padding-4xl_16bid {
    padding-top: var(--tfd-spacing-4xl);
  }

  .spacing_xl-bottom-padding-none_16bid {
    padding-bottom: 0;
  }
  .spacing_xl-bottom-padding-xxs_16bid {
    padding-bottom: var(--tfd-spacing-xxs);
  }
  .spacing_xl-bottom-padding-xs_16bid {
    padding-bottom: var(--tfd-spacing-xs);
  }
  .spacing_xl-bottom-padding-sm_16bid {
    padding-bottom: var(--tfd-spacing-sm);
  }
  .spacing_xl-bottom-padding-md_16bid {
    padding-bottom: var(--tfd-spacing-md);
  }
  .spacing_xl-bottom-padding-lg_16bid {
    padding-bottom: var(--tfd-spacing-lg);
  }
  .spacing_xl-bottom-padding-xl_16bid {
    padding-bottom: var(--tfd-spacing-xl);
  }
  .spacing_xl-bottom-padding-xxl_16bid {
    padding-bottom: var(--tfd-spacing-xxl);
  }
  .spacing_xl-bottom-padding-3xl_16bid {
    padding-bottom: var(--tfd-spacing-3xl);
  }
  .spacing_xl-bottom-padding-4xl_16bid {
    padding-bottom: var(--tfd-spacing-4xl);
  }

  .spacing_xl-right-padding-none_16bid {
    padding-right: 0;
  }
  .spacing_xl-right-padding-xxs_16bid {
    padding-right: var(--tfd-spacing-xxs);
  }
  .spacing_xl-right-padding-xs_16bid {
    padding-right: var(--tfd-spacing-xs);
  }
  .spacing_xl-right-padding-sm_16bid {
    padding-right: var(--tfd-spacing-sm);
  }
  .spacing_xl-right-padding-md_16bid {
    padding-right: var(--tfd-spacing-md);
  }
  .spacing_xl-right-padding-lg_16bid {
    padding-right: var(--tfd-spacing-lg);
  }
  .spacing_xl-right-padding-xl_16bid {
    padding-right: var(--tfd-spacing-xl);
  }
  .spacing_xl-right-padding-xxl_16bid {
    padding-right: var(--tfd-spacing-xxl);
  }
  .spacing_xl-right-padding-3xl_16bid {
    padding-right: var(--tfd-spacing-3xl);
  }
  .spacing_xl-right-padding-4xl_16bid {
    padding-right: var(--tfd-spacing-4xl);
  }

  .spacing_xl-left-padding-none_16bid {
    padding-left: 0;
  }
  .spacing_xl-left-padding-xxs_16bid {
    padding-left: var(--tfd-spacing-xxs);
  }
  .spacing_xl-left-padding-xs_16bid {
    padding-left: var(--tfd-spacing-xs);
  }
  .spacing_xl-left-padding-sm_16bid {
    padding-left: var(--tfd-spacing-sm);
  }
  .spacing_xl-left-padding-md_16bid {
    padding-left: var(--tfd-spacing-md);
  }
  .spacing_xl-left-padding-lg_16bid {
    padding-left: var(--tfd-spacing-lg);
  }
  .spacing_xl-left-padding-xl_16bid {
    padding-left: var(--tfd-spacing-xl);
  }
  .spacing_xl-left-padding-xxl_16bid {
    padding-left: var(--tfd-spacing-xxl);
  }
  .spacing_xl-left-padding-3xl_16bid {
    padding-left: var(--tfd-spacing-3xl);
  }
  .spacing_xl-left-padding-4xl_16bid {
    padding-left: var(--tfd-spacing-4xl);
  }
}

.constraints_width-constraint_sv7os {
  --width-constraint: auto;
  margin: 0 auto;
  max-width: var(--width-constraint);
}

@media (min-width: 0px) {
  .constraints_xs-width-constraint-sm_sv7os {
    --width-constraint: var(--tfd-constraint-width-sm);
  }

  .constraints_xs-width-constraint-md_sv7os {
    --width-constraint: var(--tfd-constraint-width-md);
  }

  .constraints_xs-width-constraint-lg_sv7os {
    --width-constraint: var(--tfd-constraint-width-lg);
  }

  .constraints_xs-width-constraint-xl_sv7os {
    --width-constraint: var(--tfd-constraint-width-xl);
  }
}

@media (min-width: 375px) {
  .constraints_sm-width-constraint-sm_sv7os {
    --width-constraint: var(--tfd-constraint-width-sm);
  }

  .constraints_sm-width-constraint-md_sv7os {
    --width-constraint: var(--tfd-constraint-width-md);
  }

  .constraints_sm-width-constraint-lg_sv7os {
    --width-constraint: var(--tfd-constraint-width-lg);
  }

  .constraints_sm-width-constraint-xl_sv7os {
    --width-constraint: var(--tfd-constraint-width-xl);
  }
}

@media (min-width: 710px) {
  .constraints_md-width-constraint-sm_sv7os {
    --width-constraint: var(--tfd-constraint-width-sm);
  }

  .constraints_md-width-constraint-md_sv7os {
    --width-constraint: var(--tfd-constraint-width-md);
  }

  .constraints_md-width-constraint-lg_sv7os {
    --width-constraint: var(--tfd-constraint-width-lg);
  }

  .constraints_md-width-constraint-xl_sv7os {
    --width-constraint: var(--tfd-constraint-width-xl);
  }
}

@media (min-width: 1024px) {
  .constraints_lg-width-constraint-sm_sv7os {
    --width-constraint: var(--tfd-constraint-width-sm);
  }

  .constraints_lg-width-constraint-md_sv7os {
    --width-constraint: var(--tfd-constraint-width-md);
  }

  .constraints_lg-width-constraint-lg_sv7os {
    --width-constraint: var(--tfd-constraint-width-lg);
  }

  .constraints_lg-width-constraint-xl_sv7os {
    --width-constraint: var(--tfd-constraint-width-xl);
  }
}

@media (min-width: 1400px) {
  .constraints_xl-width-constraint-sm_sv7os {
    --width-constraint: var(--tfd-constraint-width-sm);
  }

  .constraints_xl-width-constraint-md_sv7os {
    --width-constraint: var(--tfd-constraint-width-md);
  }

  .constraints_xl-width-constraint-lg_sv7os {
    --width-constraint: var(--tfd-constraint-width-lg);
  }

  .constraints_xl-width-constraint-xl_sv7os {
    --width-constraint: var(--tfd-constraint-width-xl);
  }
}

/** 
 * Use this class to create a visually hidden element that is still available to
 * screen readers. For more information read on the w3 website
 *
 * https://www.w3.org/WAI/tutorials/forms/labels/#note-on-hiding-elements
 */
.visuallyHidden_visuallyHidden_tl0p7 {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.AccordionLegacy_container_t1lue {
  width: 100%;
}

.AccordionLegacy_content_t1lue {
  margin-top: var(--margin-sm);
}

.DefaultDivider_root_1f1d7 {
  border: 0;
}

.DefaultDivider_horizontal_1f1d7 {
  min-width: 100%;
  border-top-width: 2px;
}

.DefaultDivider_vertical_1f1d7 {
  border-left-width: 2px;
  min-height: 100%;
  display: inline-block;
}

.RedSquigglyDivider_root_1n1u0 {
  line-height: 0;
  display: flex;
}

.RedSquigglyDivider_start_1n1u0 {
  justify-content: flex-start;
}

.RedSquigglyDivider_center_1n1u0 {
  justify-content: center;
}

.RedSquigglyDivider_end_1n1u0 {
  justify-content: flex-end;
}

.Text_Text_hv54p {
  /* Override browser defaults */
  font-weight: normal;
}

.colors_Blueberry0_jdlr9 {
  color: var(--blueberry-0);
}
.colors_Blueberry1_jdlr9 {
  color: var(--blueberry-1);
}
.colors_blueberry-2_jdlr9,
.colors_Blueberry2_jdlr9 {
  color: var(--blueberry-2);
}
.colors_blueberry-3_jdlr9,
.colors_Blueberry3_jdlr9 {
  color: var(--blueberry-3);
}
.colors_Carrot0_jdlr9 {
  color: var(--carrot-0);
}
.colors_Carrot1_jdlr9 {
  color: var(--carrot-1);
}
.colors_carrot-2_jdlr9,
.colors_Carrot2_jdlr9 {
  color: var(--carrot-2);
}
.colors_carrot-3_jdlr9,
.colors_Carrot3_jdlr9 {
  color: var(--carrot-3);
}
.colors_Charcoal0_jdlr9 {
  color: var(--charcoal-0);
}
.colors_charcoal-1_jdlr9,
.colors_Charcoal1_jdlr9 {
  color: var(--charcoal-1);
}
.colors_charcoal-2_jdlr9,
.colors_Charcoal2_jdlr9 {
  color: var(--charcoal-2);
}
.colors_charcoal-3_jdlr9,
.colors_Charcoal3_jdlr9 {
  color: var(--charcoal-3);
}
.colors_Chickpea0_jdlr9 {
  color: var(--chickpea-0);
}
.colors_Chickpea1_jdlr9 {
  color: var(--chickpea-1);
}
.colors_Chickpea2_jdlr9 {
  color: var(--chickpea-2);
}
.colors_Chickpea3_jdlr9 {
  color: var(--chickpea-3);
}
.colors_Kale0_jdlr9 {
  color: var(--kale-0);
}
.colors_Kale1_jdlr9 {
  color: var(--kale-1);
}
.colors_kale-2_jdlr9,
.colors_Kale2_jdlr9 {
  color: var(--kale-2);
}
.colors_kale-3_jdlr9,
.colors_Kale3_jdlr9 {
  color: var(--kale-3);
}
.colors_Oyster0_jdlr9 {
  color: var(--oyster-0);
}
.colors_Oyster1_jdlr9 {
  color: var(--oyster-1);
}
.colors_Oyster2_jdlr9 {
  color: var(--oyster-2);
}
.colors_Oyster3_jdlr9 {
  color: var(--oyster-3);
}
.colors_white_jdlr9,
.colors_White_jdlr9 {
  color: var(--white);
}
.colors_error-red_jdlr9,
.colors_ErrorRed_jdlr9 {
  color: var(--error-red);
}

.font_normal_12280 {
  font-style: normal;
}

.font_italic_12280 {
  font-style: italic;
}

.font_underline_12280 {
  text-decoration: underline;
}

.variants_heading-72_n8sxd {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 72px;
  line-height: var(--tfd-heading-72-line-height);
}

.variants_heading-52_n8sxd {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 52px;
  line-height: var(--tfd-heading-52-line-height);
}

.variants_heading-40_n8sxd {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 40px;
  line-height: var(--tfd-heading-40-line-height);
}

.variants_heading-28_n8sxd {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 28px;
  line-height: var(--tfd-heading-28-line-height);
}

.variants_heading-22_n8sxd {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 22px;
  line-height: var(--tfd-heading-22-line-height);
}

.variants_heading-20_n8sxd {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 20px;
  line-height: var(--tfd-heading-20-line-height);
}

.variants_heading-16_n8sxd {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 16px;
  line-height: var(--tfd-heading-16-line-height);
}

.variants_heading-12_n8sxd {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 12px;
  line-height: var(--tfd-heading-12-line-height);
}

/* By default, the font family for the article is set to "tiempos-text". 
However, the variable should be set to "tiempos-headline" for font sizes larger than 20. */

.variants_article-28_n8sxd,
.variants_article-40_n8sxd,
.variants_article-52_n8sxd,
.variants_article-72_n8sxd {
  --tfd-font-family-serif: var(--tfd-font-family-serif-headline);
  letter-spacing: 0.5px;
}

.variants_article-72_n8sxd {
  font-size: 72px;
  line-height: var(--tfd-article-72-line-height);
  font-family: var(--tfd-font-family-serif);
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

.variants_article-52_n8sxd {
  font-size: 52px;
  line-height: var(--tfd-article-52-line-height);
  font-family: var(--tfd-font-family-serif);
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

.variants_article-40_n8sxd {
  font-size: 40px;
  line-height: var(--tfd-article-40-line-height);
  font-family: var(--tfd-font-family-serif);
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

.variants_article-28_n8sxd {
  font-size: 28px;
  line-height: var(--tfd-article-28-line-height);
  font-family: var(--tfd-font-family-serif);
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

.variants_article-20_n8sxd {
  font-size: 20px;
  line-height: var(--tfd-article-20-line-height);
  font-family: var(--tfd-font-family-serif);
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

.variants_article-16_n8sxd {
  font-size: 16px;
  line-height: var(--tfd-article-16-line-height);
  font-family: var(--tfd-font-family-serif);
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

.variants_article-12_n8sxd {
  font-size: 12px;
  line-height: var(--tfd-article-12-line-height);
  font-family: var(--tfd-font-family-serif);
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

.weights_normal_1j5uv {
  font-weight: 400;
}

.weights_medium_1j5uv {
  font-weight: 500;
}

.weights_semibold_1j5uv {
  font-weight: 600;
}

.weights_bold_1j5uv {
  font-weight: 700;
}

.weights_black_1j5uv,
.weights_black_1j5uv strong {
  font-weight: 900;
}

.align_left_bhq9y {
  text-align: left;
}

.align_center_bhq9y {
  text-align: center;
}

.align_right_bhq9y {
  text-align: right;
}

@media (min-width: 0px) {
  .align_xs-left_bhq9y {
    text-align: left;
  }
  .align_xs-center_bhq9y {
    text-align: center;
  }
  .align_xs-right_bhq9y {
    text-align: right;
  }
}

@media (min-width: 375px) {
  .align_sm-left_bhq9y {
    text-align: left;
  }
  .align_sm-center_bhq9y {
    text-align: center;
  }
  .align_sm-right_bhq9y {
    text-align: right;
  }
}

@media (min-width: 710px) {
  .align_md-left_bhq9y {
    text-align: left;
  }
  .align_md-center_bhq9y {
    text-align: center;
  }
  .align_md-right_bhq9y {
    text-align: right;
  }
}

@media (min-width: 1024px) {
  .align_lg-left_bhq9y {
    text-align: left;
  }
  .align_lg-center_bhq9y {
    text-align: center;
  }
  .align_lg-right_bhq9y {
    text-align: right;
  }
}

@media (min-width: 1400px) {
  .align_xl-left_bhq9y {
    text-align: left;
  }
  .align_xl-center_bhq9y {
    text-align: center;
  }
  .align_xl-right_bhq9y {
    text-align: right;
  }
}

.base_init_lixan {
  opacity: 0;
}

.base_entered_lixan {
  opacity: 1;
}

.base_exited_lixan {
  opacity: 0;
}

.base_unmounted_lixan {
}

@keyframes fade_fade-in_k0hvh {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade_fade-out_k0hvh {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fade_entering_k0hvh {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: fade_fade-in_k0hvh;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}
.fade_exiting_k0hvh {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: fade_fade-out_k0hvh;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}

@keyframes slide-down_slide-down-in_c7hd0 {
  0% {
    transform: translate(0px, -12px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes slide-down_slide-down-out_c7hd0 {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 12px);
  }
}
@keyframes slide-down_fade-in_c7hd0 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide-down_fade-out_c7hd0 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide-down_scale-height-up_c7hd0 {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 1000px;
  }
}
@keyframes slide-down_scale-height-down_c7hd0 {
  0% {
    max-height: 1000px;
  }
  100% {
    max-height: 0px;
  }
}
.slide-down_entering_c7hd0 {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: slide-down_slide-down-in_c7hd0, slide-down_fade-in_c7hd0, slide-down_scale-height-up_c7hd0;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}
.slide-down_exiting_c7hd0 {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: slide-down_slide-down-out_c7hd0, slide-down_fade-out_c7hd0, slide-down_scale-height-down_c7hd0;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}

@keyframes slide-up_slide-up-in_1kp5r {
  0% {
    transform: translate(0px, 12px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes slide-up_slide-up-out_1kp5r {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, -12px);
  }
}
@keyframes slide-up_fade-in_1kp5r {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide-up_fade-out_1kp5r {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide-up_scale-height-up_1kp5r {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 1000px;
  }
}
@keyframes slide-up_scale-height-down_1kp5r {
  0% {
    max-height: 1000px;
  }
  100% {
    max-height: 0px;
  }
}
.slide-up_entering_1kp5r {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: slide-up_slide-up-in_1kp5r, slide-up_fade-in_1kp5r, slide-up_scale-height-up_1kp5r;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}
.slide-up_exiting_1kp5r {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: slide-up_slide-up-out_1kp5r, slide-up_fade-out_1kp5r, slide-up_scale-height-down_1kp5r;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}

button.ToggleButton_toggle_5o37c {
  display: flex;
  width: 100%;
  background: none;
  text-align: left;
  justify-content: space-between;
  align-items: center;
}

button.ToggleButton_toggle_5o37c svg {
  flex-shrink: 0;
}

.ButtonBase_buttonReset_1ns49 {
  box-sizing: border-box;
  appearance: none;
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  background: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  display: inline-block;
  color: inherit;

  -moz-appearance: none;
  -webkit-appearance: none;
}
.ButtonBase_buttonReset_1ns49:disabled,
.ButtonBase_buttonReset_1ns49[aria-disabled='true'] {
  cursor: default;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.themes_Chickpea_7xqun {
  background-color: var(--chickpea-0);
  border: 1px solid var(--chickpea-2);

}

[dir="ltr"] .themes_Chickpea_7xqun>button:first-child {
    text-align: left;

}

[dir="rtl"] .themes_Chickpea_7xqun>button:first-child {
    text-align: right;

}

.themes_Chickpea_7xqun>button:first-child {
    padding: var(--tfd-spacing-sm);
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    -moz-column-gap: var(--tfd-spacing-xs);
         column-gap: var(--tfd-spacing-xs);
  }

.themes_Chickpea_7xqun>button:first-child>h3 {
      margin: 0;
    }

.themes_Chickpea_7xqun>button:first-child>span {
      align-self: center;
    }

.themes_Chickpea_7xqun>button:first-child>svg {
      flex-shrink: 0;
    }

.themes_Chickpea_7xqun [role='region'] {
    padding: var(--tfd-spacing-sm) var(--tfd-spacing-md) var(--tfd-spacing-md);
  }

.themes_Kale_7xqun {
  background-color: var(--kale-0);
}

[dir="ltr"] .themes_Kale_7xqun>button:first-child {
  text-align: left;

}

[dir="rtl"] .themes_Kale_7xqun>button:first-child {
  text-align: right;

}

.themes_Kale_7xqun>button:first-child {
  padding: var(--tfd-spacing-sm);
  display: flex;
  width: 100%;
  justify-content: space-between;
  -moz-column-gap: var(--tfd-spacing-xs);
       column-gap: var(--tfd-spacing-xs);
}

.themes_Kale_7xqun>button:first-child>h3 {
  margin: 0;
}

.themes_Kale_7xqun>button:first-child>span {
  align-self: center;
}

.themes_Kale_7xqun>button:first-child>svg {
  flex-shrink: 0;
}

.themes_Kale_7xqun [role='region'] {
  padding: var(--tfd-spacing-sm) var(--tfd-spacing-md) var(--tfd-spacing-md);
}

.themes_Blueberry_7xqun {
  background-color: var(--blueberry-0);

}

[dir="ltr"] .themes_Blueberry_7xqun>button:first-child {
    text-align: left;

}

[dir="rtl"] .themes_Blueberry_7xqun>button:first-child {
    text-align: right;

}

.themes_Blueberry_7xqun>button:first-child {
    padding: var(--tfd-spacing-sm);
    display: flex;
    width: 100%;
    justify-content: space-between;
    -moz-column-gap: var(--tfd-spacing-xs);
         column-gap: var(--tfd-spacing-xs);
  }

.themes_Blueberry_7xqun>button:first-child>h3 {
      margin: 0;
    }

.themes_Blueberry_7xqun>button:first-child>span {
      align-self: center;
    }

.themes_Blueberry_7xqun>button:first-child>svg {
      flex-shrink: 0;
    }

.themes_Blueberry_7xqun [role='region'] {
    padding: var(--tfd-spacing-sm) var(--tfd-spacing-md) var(--tfd-spacing-md);
  }

.themes_Default_7xqun {
  border-top: 1px solid var(--charcoal-0);
}

.themes_Default_7xqun>button:first-child {
  margin: var(--tfd-spacing-md) 0;
}

[dir="ltr"] .themes_Default_7xqun>button:first-child {
  text-align: left;

}

[dir="rtl"] .themes_Default_7xqun>button:first-child {
  text-align: right;

}

.themes_Default_7xqun>button:first-child {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.themes_Default_7xqun>button:first-child>h3 {
  margin: 0;
}

.themes_Default_7xqun>button:first-child>span {
  display: flex;
  align-self: center;
}

.themes_Default_7xqun>button:first-child>svg {
  flex-shrink: 0;
}

.themes_Default_7xqun:last-child {
  border-bottom: 1px solid var(--charcoal-0);
}

.themes_Default_7xqun [role='region'] {
  padding-bottom: var(--tfd-spacing-md);
}
.Arrow_right_1d20g {
  transform: rotate(180deg) translateX(-2px);
}

.Arrow_left_1d20g {
  transform: rotate(0deg) translateX(-2px);
}

.Arrow_down_1d20g {
  transform: rotate(-90deg) translateX(1px);
}

.Arrow_up_1d20g {
  transform: rotate(90deg) translateX(1px);
}

.AnimateHeight_heightTransition_19bj7 {
  display: block;
  overflow: hidden;
  transition: all var(--tfd-animation-standard-duration)
    cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes ActivityIndicator_bounce-dots_1fetv {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.ActivityIndicator_container_1fetv {
  display: inline-flex;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  justify-items: center;
}

.ActivityIndicator_dot_1fetv {
  display: inline-block;
  margin: 0 1px;
  width: 1em;
  height: 1em;
  border-radius: 100%;
  animation: ActivityIndicator_bounce-dots_1fetv 1.4s infinite ease-in-out both;
}

.ActivityIndicator_dot_1fetv:nth-child(1) {
  animation-delay: -0.32s;
}

.ActivityIndicator_dot_1fetv:nth-child(2) {
  animation-delay: -0.16s;
}

.ActivityIndicator_carrot_1fetv .ActivityIndicator_dot_1fetv {
  background-color: var(--carrot-2);
}

.ActivityIndicator_kale_1fetv .ActivityIndicator_dot_1fetv {
  background-color: var(--kale-2);
}

.ActivityIndicator_kale-dark_1fetv .ActivityIndicator_dot_1fetv {
  background-color: var(--kale-3);
}

.ActivityIndicator_white_1fetv .ActivityIndicator_dot_1fetv {
  background-color: var(--white);
}

.ActivityModal_loading-modal_1984b {
  --modal-width: 100%;
}

@media (min-width: 710px) {
  .ActivityModal_loading-modal_1984b {
    --modal-width: 630px;
  }
}

@media (min-width: 1024px) {
  .ActivityModal_loading-modal_1984b {
    --modal-width: 716px;
  }
}

.ActivityModal_loading-modal_1984b {
  width: calc(var(--modal-width) - var(--tfd-spacing-sm));
  padding: var(--tfd-spacing-xl);
  border-radius: var(--tfd-border-radius-sm);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Lightbox_content_1wsh5.Lightbox_entering_1wsh5,
.Lightbox_content_1wsh5.Lightbox_entered_1wsh5 {
  transform: scale(1);
  opacity: 1;
}
.Lightbox_content_1wsh5.Lightbox_exiting_1wsh5 {
  transform: scale(0.8);
  opacity: 0;
}
.Lightbox_content_1wsh5.Lightbox_exited_1wsh5 {
  transform: scale(0.8);
  opacity: 0;
}
.Lightbox_backdrop_1wsh5 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(57, 56, 60, 0.94);

  overflow: hidden;

  transition: opacity 100ms linear;
  opacity: 0;
}
.Lightbox_backdrop_1wsh5.Lightbox_entering_1wsh5 {
  opacity: 1;
}
.Lightbox_backdrop_1wsh5.Lightbox_entered_1wsh5 {
  opacity: 1;
}
.Lightbox_backdrop_1wsh5.Lightbox_exiting_1wsh5 {
  opacity: 0;
}
.Lightbox_backdrop_1wsh5.Lightbox_exited_1wsh5 {
  opacity: 0;
}
.Lightbox_backdrop_1wsh5.Lightbox_unmounted_1wsh5 {
  /* Keep this for satisfying CSS types for transitions */
}
.Lightbox_dialog_1wsh5 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backface-visibility: hidden;

  z-index: var(--tfd-lightbox-z-index);

  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.Lightbox_disableAnimation_1wsh5 .Lightbox_backdrop_1wsh5 {
  transition-duration: 0ms;
}
.Lightbox_content_1wsh5 {
  position: relative;
  margin: auto;
  background: var(--white);
  backface-visibility: hidden;

  transition: all var(--tfd-animation-standard-duration)
    cubic-bezier(0.19, 1, 0.22, 1);
  transition-property: opacity, transform;
  transform-origin: center top;

  transform: scale(1);
  opacity: 1;
}
.Lightbox_content_1wsh5.Lightbox_entering_1wsh5 {
  transform: scale(1);
  opacity: 1;
}
.Lightbox_disableAnimation_1wsh5 .Lightbox_content_1wsh5 {
  transition-duration: 0ms;
}
.Lightbox_container_1wsh5 {
  min-height: 100%;
  display: flex;
  flex: 1;
}

.AppNav_root_6gly9 {
  position: relative;
  background-color: var(--white);
}

.AppNav_root_6gly9.AppNav_isOpen_6gly9 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--tfd-appnav-z-index);
}

.AppNav_logo_6gly9 {
  display: inline-block;
  position: relative;
  z-index: var(--tfd-appnav-z-index);
  transition: transform var(--tfd-animation-standard);
}

.AppNav_logo_6gly9 a {
  display: inline-block;
}

.AppNav_menuBackdrop_6gly9 {
  background-color: var(--white);
}

.Link_root_1y9ij {
  text-decoration: none;
  transition: color var(--animation-standard);
  background-color: transparent;
  color: var(--carrot-2);
}

.Link_root_1y9ij:hover {
  color: var(--carrot-3);
  text-decoration: none;
}

.Link_root_1y9ij:active {
  color: var(--carrot-3);
}

.Link_root_1y9ij[aria-disabled='true'] {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.Link_carrot_1y9ij {
  color: var(--carrot-2);
}

.Link_kale_1y9ij {
  color: var(--kale-3);
}

.Link_blueberry_1y9ij {
  color: var(--blueberry-3);
}

.Link_white_1y9ij {
  color: var(--white);
}

.Logo_logo_pt0b9 {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: inline-block;
}

.Logo_path_pt0b9 {
  transition: all var(--animation-standard);
  transition-property: fill;
}

.Hamburger_button_10590 {
  overflow: hidden; /* fixes outline issues in Safari desktop on focus */
}

.Hamburger_bar_10590 {
  width: var(--tfd-spacing-md);
  height: 2px;
  border-radius: 1px;
  background-color: var(--charcoal-3);
  position: relative;

  transition: all ease-in-out 200ms;
  transition-property: opacity, transform, background-color;
}

.Hamburger_bar_10590.Hamburger_darkTheme_10590 {
  background-color: var(--white);
}

.Hamburger_bar_10590 {
  margin: var(--tfd-spacing-xs) 0;
}

.Hamburger_isOpen_10590 .Hamburger_bar_10590:nth-child(1) {
  transform: translateY(calc(var(--tfd-spacing-xs) + 2px)) rotate(135deg);
}

.Hamburger_isOpen_10590 .Hamburger_bar_10590:nth-child(2) {
  opacity: 0;
}

.Hamburger_isOpen_10590 .Hamburger_bar_10590:nth-child(3) {
  transform: translateY(calc(-1 * var(--tfd-spacing-xs) - 2px)) rotate(-135deg);
}

.Menu_menu_1sri4 {
  background-color: var(--white);
}
.Menu_menuContainer_1sri4 {
  padding: var(--tfd-spacing-md);
  height: calc(100dvh - var(--tfd-appnav-height));
  overflow: auto;
  padding-bottom: 74px; /* ~ the height of the call to action  */
}
.Menu_listItem_1sri4 {
  padding: var(--tfd-spacing-sm) 0;
  border-bottom: 1px solid var(--oyster-1);
}
.Menu_listItem_1sri4:last-child {
  border-bottom: 0;
}
.Menu_navItem_1sri4 {
  display: block;
  width: 100%;
  padding: var(--tfd-spacing-sm) var(--tfd-spacing-sm);
}
.Menu_cta_1sri4 {
  background-color: var(--white);
  position: fixed;
  left: var(--tfd-spacing-sm);
  bottom: 0;
  right: var(--tfd-spacing-sm);
  display: flex;
  justify-content: center;
  padding: var(--tfd-spacing-sm) 0;
}
.Menu_cta_1sri4 > button,
.Menu_cta_1sri4 > a {
  /* 
  Making the assumption the button is the only child
  To remove the flex behavior wrap the cta into a `<div /` 
  */
  flex: 1;
}

.NavItem_navItem_1jy9e {
  display: inline-block;
  white-space: nowrap;
}

.NavItem_navItem_1jy9e + .NavItem_navItem_1jy9e {
  margin-left: var(--tfd-spacing-md);
}

.NavItem_navItem_1jy9e.NavItem_button_1jy9e {
  color: var(--kale-3);
}

.NavItem_navItem_1jy9e.NavItem_link_1jy9e {
  font-weight: bold;
  font-family: var(--tfd-font-family);
}

[data-overlay-mode='dark'] .NavItem_navItem_1jy9e.NavItem_link_1jy9e {
  color: var(--white);
}

.Button_button_ugt5i,
a.Button_button_ugt5i,
button.Button_button_ugt5i {
  font-family: var(--tfd-font-family);
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 22px;
}

.Button_button_ugt5i:not(.Button_plain-text_ugt5i) {
  text-align: center;
  border-radius: var(--tfd-border-radius-sm);
}

.Button_disabled_ugt5i,
a.Button_button_ugt5i.Button_disabled_ugt5i {
  cursor: default;
}

.Button_button_ugt5i.Button_primary_ugt5i {
  font-size: 16px;
  padding: 14px 24px;
  background-color: var(--carrot-2);
  color: var(--white);
}

.Button_button_ugt5i.Button_primary-mini_ugt5i {
  font-size: 16px;
  padding: 7px 12px;
  background-color: var(--carrot-2);
  color: var(--white);
}

.Button_button_ugt5i.Button_secondary_ugt5i {
  border: solid 2px var(--carrot-2);
  background-color: var(--white);
  color: var(--carrot-2);
  font-size: 16px;
  padding: 12px 22px;
}

.Button_button_ugt5i.Button_secondary-mini_ugt5i {
  font-size: 16px;
  padding: 5px 12px;
  border: solid 2px var(--carrot-2);
  background-color: var(--white);
  color: var(--carrot-2);
}

.Button_button_ugt5i.Button_special_ugt5i {
  background-color: var(--carrot-2);
  color: var(--white);
  font-size: 16px;
  border-radius: 30px;
  padding: 14px 36px;
}

.Button_button_ugt5i.Button_special-kale-experimental_ugt5i {
  color: var(--white);
  border-radius: 30px;
  padding: 6px 12px;
  padding: var(--tfd-spacing-xs, 6px) var(--tfd-spacing-sm, 12px);
  background-color: var(--kale-2);
  font-size: 12px;
}

.Button_button_ugt5i.Button_primary-mini-kale-experimental_ugt5i {
  padding: 7px 12px;
  background-color: var(--kale-2);
  color: var(--white);
}

.Button_button_ugt5i.Button_secondary-mini-kale-experimental_ugt5i {
  padding: 5px 12px;
  border: solid 2px var(--kale-2);
  background-color: var(--white);
  color: var(--kale-2);
}

.Button_button_ugt5i.Button_plain-text_ugt5i {
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  transition: color var(--animation-standard);
  color: var(--carrot-2);
}

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_loading_ugt5i,
.Button_button_ugt5i.Button_plain-text_ugt5i.Button_loading_ugt5i:hover {
  color: transparent !important;
}

.Button_button_ugt5i.Button_plain-text_ugt5i[aria-disabled='true'].Button_white_ugt5i,
.Button_button_ugt5i.Button_plain-text_ugt5i.Button_disabled_ugt5i.Button_white_ugt5i {
  color: var(--charcoal-1);
}

/* Font sizes are enabled only for plain-text variant. */

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_lg_ugt5i {
  font-size: 22px;
}

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_md_ugt5i {
  font-size: 16px;
}

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_sm_ugt5i {
  font-size: 12px;
}

/* Font settings are enabled only for plain-text variant. */

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_underline_ugt5i {
  text-decoration: underline;
}

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_bold_ugt5i {
  font-weight: bold;
}

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_normal_ugt5i {
  font-weight: normal;
}

/* Colors are enabled only for plain-text variant. */

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_carrot_ugt5i {
  color: var(--carrot-2);
}

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_carrot_ugt5i:hover,
.Button_button_ugt5i.Button_plain-text_ugt5i.Button_carrot_ugt5i.Button_hover_ugt5i {
  color: var(--carrot-3);
}

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_carrot_ugt5i:active,
.Button_button_ugt5i.Button_plain-text_ugt5i.Button_carrot_ugt5i.Button_active_ugt5i {
  color: var(--carrot-2);
}

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_kale_ugt5i {
  color: var(--kale-2);
}

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_kale_ugt5i .Button_indicator_ugt5i svg {
  stroke: var(--kale-2);
}

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_kale_ugt5i:hover,
.Button_button_ugt5i.Button_plain-text_ugt5i.Button_kale_ugt5i.Button_hover_ugt5i {
  color: var(--kale-3);
}

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_kale_ugt5i:active,
.Button_button_ugt5i.Button_plain-text_ugt5i.Button_kale_ugt5i.Button_active_ugt5i {
  color: var(--kale-2);
}

.Button_plain-text_ugt5i.Button_kale-dark_ugt5i {
  color: var(--kale-3);
}

.Button_plain-text_ugt5i.Button_kale-dark_ugt5i .Button_indicator_ugt5i svg {
  stroke: var(--kale-3);
}

.Button_plain-text_ugt5i.Button_kale-dark_ugt5i:hover,
.Button_plain-text_ugt5i.Button_kale-dark_ugt5i.Button_hover_ugt5i {
  color: var(--carrot-2);
}

.Button_plain-text_ugt5i.Button_kale-dark_ugt5i:active,
.Button_plain-text_ugt5i.Button_kale-dark_ugt5i.Button_active_ugt5i {
  color: var(--carrot-2);
}

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_white_ugt5i {
  color: var(--white);
}

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_white_ugt5i .Button_indicator_ugt5i svg {
  stroke: var(--white);
}

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_white_ugt5i:hover,
.Button_button_ugt5i.Button_plain-text_ugt5i.Button_white_ugt5i.Button_hover_ugt5i {
  color: var(--white);
}

.Button_button_ugt5i.Button_plain-text_ugt5i.Button_white_ugt5i:active,
.Button_button_ugt5i.Button_plain-text_ugt5i.Button_white_ugt5i.Button_active_ugt5i {
  color: var(--white);
}

.Button_button_ugt5i.Button_plain-text_ugt5i:not(.Button_loading_ugt5i)[aria-disabled='true'],
.Button_button_ugt5i.Button_plain-text_ugt5i:not(.Button_loading_ugt5i).Button_disabled_ugt5i {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
  color: var(--charcoal-2);
}

.Button_button_ugt5i.Button_primary_ugt5i:hover,
.Button_button_ugt5i.Button_primary_ugt5i.Button_hover_ugt5i,
.Button_button_ugt5i.Button_primary-mini_ugt5i:hover,
.Button_button_ugt5i.Button_primary-mini_ugt5i.Button_hover_ugt5i,
.Button_button_ugt5i.Button_special_ugt5i:hover,
.Button_button_ugt5i.Button_special_ugt5i.Button_hover_ugt5i {
  background-color: var(--carrot-3);
}

.Button_button_ugt5i.Button_special-kale-experimental_ugt5i:hover,
.Button_button_ugt5i.Button_special-kale-experimental_ugt5i.Button_hover_ugt5i,
.Button_button_ugt5i.Button_primary-mini-kale-experimental_ugt5i:hover,
.Button_button_ugt5i.Button_primary-mini-kale-experimental_ugt5i.Button_hover_ugt5i {
  background-color: var(--carrot-2);
}

.Button_button_ugt5i.Button_primary_ugt5i:active,
.Button_button_ugt5i.Button_primary_ugt5i.Button_active_ugt5i,
.Button_button_ugt5i.Button_primary-mini_ugt5i:active,
.Button_button_ugt5i.Button_primary-mini_ugt5i.Button_active_ugt5i,
.Button_button_ugt5i.Button_special_ugt5i:active,
.Button_button_ugt5i.Button_special_ugt5i.Button_active_ugt5i {
  background-color: var(--carrot-3);
}

.Button_button_ugt5i.Button_special-kale-experimental_ugt5i.Button_active_ugt5i,
.Button_button_ugt5i.Button_primary-mini-kale-experimental_ugt5i.Button_active_ugt5i {
  background-color: var(--carrot-2);
}

.Button_button_ugt5i.Button_primary_ugt5i.Button_disabled_ugt5i,
.Button_button_ugt5i.Button_primary-mini_ugt5i.Button_disabled_ugt5i,
.Button_button_ugt5i.Button_special_ugt5i.Button_disabled_ugt5i,
.Button_button_ugt5i.Button_special-kale-experimental_ugt5i.Button_disabled_ugt5i,
.Button_button_ugt5i.Button_primary-mini-kale-experimental_ugt5i.Button_disabled_ugt5i,
.Button_button_ugt5i.Button_secondary-mini-kale-experimental_ugt5i.Button_disabled_ugt5i {
  background-color: var(--charcoal-2);
}

.Button_button_ugt5i.Button_primary_ugt5i.Button_loading_ugt5i,
.Button_button_ugt5i.Button_primary-mini_ugt5i.Button_loading_ugt5i,
.Button_button_ugt5i.Button_special_ugt5i.Button_loading_ugt5i {
  background-color: var(--carrot-3);
}

.Button_button_ugt5i.Button_secondary-mini-kale-experimental_ugt5i:hover,
.Button_button_ugt5i.Button_secondary-mini-kale-experimental_ugt5i.Button_hover_ugt5i {
  border-color: var(--kale-3);
  background-color: var(--white);
  color: var(--kale-3);
}

.Button_button_ugt5i.Button_secondary-mini-kale-experimental_ugt5i:active,
.Button_button_ugt5i.Button_secondary-mini-kale-experimental_ugt5i.Button_active_ugt5i {
  border-color: var(--kale-3);
  background-color: var(--white);
  color: var(--kale-3);
}

.Button_button_ugt5i.Button_secondary-mini-kale-experimental_ugt5i.Button_disabled_ugt5i {
  border-color: var(--charcoal-2);
  background-color: var(--white);
  color: var(--charcoal-2);
}

.Button_button_ugt5i.Button_secondary_ugt5i:hover,
.Button_button_ugt5i.Button_secondary_ugt5i.Button_hover_ugt5i,
.Button_button_ugt5i.Button_secondary-mini_ugt5i:hover,
.Button_button_ugt5i.Button_secondary-mini_ugt5i.Button_hover_ugt5i {
  border-color: var(--carrot-3);
  background-color: var(--white);
  color: var(--carrot-3);
}

.Button_button_ugt5i.Button_secondary_ugt5i:active,
.Button_button_ugt5i.Button_secondary_ugt5i.Button_active_ugt5i,
.Button_button_ugt5i.Button_secondary-mini_ugt5i:active,
.Button_button_ugt5i.Button_secondary-mini_ugt5i.Button_active_ugt5i {
  border-color: var(--carrot-3);
  background-color: var(--white);
  color: var(--carrot-3);
}

.Button_button_ugt5i.Button_secondary_ugt5i.Button_disabled_ugt5i,
.Button_button_ugt5i.Button_secondary-mini_ugt5i.Button_disabled_ugt5i {
  border-color: var(--charcoal-2);
  background-color: var(--white);
  color: var(--charcoal-2);
}

.Button_button_ugt5i.Button_secondary_ugt5i.Button_loading_ugt5i,
.Button_button_ugt5i.Button_secondary-mini_ugt5i.Button_loading_ugt5i {
  border-color: var(--carrot-3);
  background-color: var(--white);
  color: var(--carrot-3);
}

/* Loading state */

.Button_loading_ugt5i {
  position: relative;
  display: inline-flex; /* Align activity indicator */
  min-width: 58px; /* Make sure button is large enough for the activity indicator */
}

/* Activity indicator is placed inside this container to cover the button. */

.Button_indicator_ugt5i {
  font-size: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
}

.MenuTrigger_menuTrigger_1x6ah {
  padding: 0;
}

.MenuTrigger_menuTrigger_1x6ah button {
  display: flex;
  align-items: center;
  gap: var(--tfd-spacing-xs);
  margin: 0;
}

.MenuTrigger_popup_1x6ah {
  display: block;
}

.MenuTrigger_mobileMenu_1x6ah {
  display: block;
}

.MenuTrigger_hidden_1x6ah {
  display: none;
}

.MenuTrigger_arrow_1x6ah > svg {
  margin-left: var(--tfd-spacing-xs);
}

.MenuTrigger_arrowDown_1x6ah {
  margin-top: var(--tfd-spacing-xs);
}

.MenuTrigger_arrowUp_1x6ah {
  margin-top: var(--tfd-spacing-xxs);
}

@media (min-width: 1024px) {
  .MenuTrigger_menuTrigger_1x6ah {
    padding: revert;
  }

  .MenuTrigger_popup_1x6ah {
    display: block;
    position: absolute;
    margin-left: calc(var(--tfd-spacing-xl) * -1);
    margin-top: calc(var(--tfd-spacing-xl) * -1 - var(--tfd-spacing-xxs));
  }

  .MenuTrigger_hidden_1x6ah {
    display: none;
  }

  .MenuTrigger_mobileMenu_1x6ah {
    display: none;
  }
}

.Menu_menu_h604p {
  background-color: var(--white);
  margin-left: var(--tfd-spacing-md);
  border-radius: var(--tfd-border-radius-xs);
}

.Menu_menu_h604p div[role='menuitem'] {
  padding: 0;
  margin-left: var(--tfd-spacing-sm);
  margin-bottom: var(--tfd-spacing-sm);
}

.Menu_active_h604p {
  color: var(--carrot-2);
}

@media (min-width: 1024px) {
  .Menu_menu_h604p {
    padding: var(--tfd-spacing-xxs) var(--tfd-spacing-md);
    margin-left: revert;
    box-shadow: var(--tfd-shadow-1);
  }

  .Menu_menu_h604p div[role='menuitem'] {
    margin: var(--tfd-spacing-md) 0;
  }
}

.Nav_nav_m46lv {
  align-items: center;
  display: flex;
  height: var(--tfd-appnav-height);
  justify-content: center;
  margin: 0 var(--tfd-spacing-md);
}

.Nav_logo_m46lv,
.Nav_cta_m46lv {
  /* TODO: Fix this in a more sensible way */
  z-index: calc(var(--tfd-appnav-z-index) + 1);
}

@media (min-width: 1024px) {
  .Nav_nav_m46lv {
    margin: 0 var(--tfd-spacing-xl);
  }
}

/* TODO: fix using @media(--viewport-md) instead of @media with max-width */

@media (max-width: 1024px) {
  .Nav_cta_m46lv + .Nav_logo_m46lv {
    display: none;
  }
}

@media (min-width: 1024px) {
  .Nav_cta_m46lv {
    display: none;
  }
}

.Nav_navItem_m46lv {
  display: inline-block;
  position: relative;
  margin: 0 calc(var(--tfd-spacing-lg) / 2);
}

.Nav_largeNav_m46lv {
  display: none;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
  margin-left: calc(var(--tfd-spacing-lg) / 2);
}

@media (min-width: 1024px) {
  .Nav_largeNav_m46lv {
    display: flex;
  }
}

.Nav_largeNavLeft_m46lv {
  display: flex;
  overflow: hidden;
  align-items: center;
}

.Nav_largeNavRight_m46lv {
  display: flex;
  justify-items: flex-end;
  align-items: center;
}

.Nav_largeNavRight_m46lv span:last-child > button,
.Nav_largeNavRight_m46lv span:last-child > a,
.Nav_largeNavRight_m46lv span:last-child > span {
  margin-right: 0;
}

.Nav_smallNav_m46lv {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--tfd-appnav-height);
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--tfd-appnav-z-index);
  padding: 0 var(--tfd-spacing-md);
}

.Nav_smallNav_m46lv span:last-child a {
  margin-right: 0;
}

.Nav_smallNav_m46lv > div {
  display: flex;
  align-items: center;
}

@media (min-width: 1024px) {
  .Nav_smallNav_m46lv {
    display: none;
  }
}

@media (min-width: 1024px) {
  .SubNav_subNav_1cmwp {
    display: none;
  }
}

.SubNav_subNav_1cmwp {
  overflow: auto;
  padding: var(--tfd-spacing-sm) 0;
  transition: all var(--tfd-animation-standard);
}

.SubNav_navItem_1cmwp {
  background-color: transparent;
}

.SubNav_list_1cmwp {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
}

.SubNav_listItem_1cmwp {
  padding: 0 var(--tfd-spacing-sm);
}

.SubNav_listItem_1cmwp:first-child {
  padding-left: var(--tfd-spacing-md);
}

.SubNav_listItem_1cmwp:last-child {
  padding-right: var(--tfd-spacing-md);
}

.ArrowButton_button_13i5x {
  height: 48px;
  width: 48px;
  border-radius: var(--tfd-border-radius-sm);
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ArrowButton_button_13i5x.ArrowButton_default_13i5x {
  background: none;
}

.ArrowButton_button_13i5x.ArrowButton_white-bg_13i5x {
  border-radius: 50%;
  background: var(--white);
}

.Banner_root_1nyzq {
  box-sizing: border-box;
  text-align: center;
  color: var(--white);
  font-family: var(--tfd-font-family);
  font-size: 16px;
  position: relative;
  padding: var(--margin-sm) var(--margin-md);
  min-height: var(--tfd-banner-min-height);
}

.Banner_root_1nyzq a {
  color: inherit;
}

.Banner_default_1nyzq {
  background: var(--kale-3);
}

.Banner_kale-2_1nyzq {
  background: var(--kale-2);
}

.Banner_oyster-0_1nyzq {
  background: var(--oyster-0);
}

.Banner_white_1nyzq {
  background: var(--white);
}

.Banner_chickpea-0_1nyzq {
  background: var(--chickpea-0);
}

.BeforeAfter_caretIcon_okfyx {
  transform: rotate(-90deg);
}

.BeforeAfter_container_okfyx {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.BeforeAfter_before_okfyx {
  text-decoration: line-through;
}

.BeforeAfter_before_okfyx,
.BeforeAfter_after_okfyx {
  white-space: nowrap;
}

.Breadcrumbs_caret_1rull {
  display: inline-block;
  height: 12px;
  margin: 0 var(--margin-sm);
  transform: rotate(180deg);
  fill: var(--charcoal-0);
}

.Breadcrumbs_root_1rull {
  color: var(--charcoal-1);
}

.Breadcrumbs_root_1rull a {
  text-decoration: none;
  color: var(--kale-3);
  transition: color 300ms ease-in-out;
}

.Breadcrumbs_root_1rull a:hover {
  color: var(--carrot-3);
}

.Callout_root_1hjmp {
  background: var(--blueberry-0);
  border: 1px solid var(--blueberry-3);
  border-radius: var(--tfd-spacing-xs);
  margin: var(--tfd-spacing-xl) 0 var(--tfd-spacing-md) 0;
  padding: var(--tfd-spacing-sm);
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.Callout_promo_1hjmp {
  background-color: var(--kale-3);
  border-color: var(--kale-3);
  color: var(--white);
}

.Callout_positive_1hjmp {
  background-color: var(--kale-0);
  border-color: var(--kale-3);
  color: var(--kale-3);
}

.Callout_neutral_1hjmp {
  background-color: var(--blueberry-0);
  border-color: var(--blueberry-3);
  color: var(--blueberry-3);
}

.Callout_negative_1hjmp {
  background-color: var(--error-red-bg);
  border-color: var(--error-red-dark);
  color: var(--error-red-dark);
}

.Callout_warning_1hjmp {
  border: 1px solid var(--chickpea-3);
  background: var(--chickpea-1);
  color: var(--charcoal-3);
}

.Callout_info_1hjmp {
  border: 1px solid var(--oyster-2);
  background: var(--oyster-0);
  color: var(--charcoal-3);
}

.Callout_icon_1hjmp {
  margin-right: var(--tfd-spacing-xs);
}

.Callout_promo_1hjmp .Callout_icon_1hjmp svg {
  fill: var(--white);
}

.Callout_positive_1hjmp .Callout_icon_1hjmp svg {
  fill: var(--kale-3);
}

.Callout_neutral_1hjmp .Callout_icon_1hjmp svg {
  fill: var(--blueberry-3);
}

.Callout_negative_1hjmp .Callout_icon_1hjmp svg {
  fill: var(--error-red-dark);
}

.Callout_warning_1hjmp .Callout_icon_1hjmp svg {
  fill: var(--charcoal-3);
}

.Callout_info_1hjmp .Callout_icon_1hjmp svg {
  fill: var(--charcoal-3);
}

.BorderedCard_bordered_1an05 {
  --badge-number-height: 52px; /* same as the font size */
  --badge-number-offset: -2px; /* align vertically the string */

  --badge-image-height: 80px; /* same as the font size */
  --badge-image-offset: -12px; /* same as the font size */

  display: block;
  margin: var(--tfd-spacing-md) 0;
  position: relative;
  background-color: var(--white);
  border: 1px solid var(--oyster-2);
  border-radius: var(--tfd-border-radius-sm);
  padding: var(--tfd-spacing-xl) var(--tfd-spacing-lg);
}

.BorderedCard_bordered_1an05.BorderedCard_with-number_1an05 {
  margin-top: calc(var(--badge-number-height) / 2 - var(--badge-number-offset));
}

.BorderedCard_bordered_1an05.BorderedCard_with-image_1an05 {
  margin-top: calc(var(--badge-image-height) / 2 - var(--badge-image-offset));
}

.Badge_root_xlk5k {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
}

.Badge_root_xlk5k > img {
  transform: translateY(var(--badge-image-offset));
  height: var(--badge-image-height);
  background-color: var(--white);
}

.Badge_content_xlk5k {
  transform: translateY(var(--badge-number-offset));
  background-color: var(--white);
  line-height: var(--badge-number-height);
  padding: 0 var(--tfd-spacing-md);
}

.PlainCard_plain_1xk8t {
  display: block;
  margin: var(--tfd-spacing-md) 0;
  position: relative;
  background-color: var(--white);
  border-radius: var(--tfd-border-radius-lg);
}
.PlainCard_plain_1xk8t.PlainCard_level-0_1xk8t {
  box-shadow: none;
}
.PlainCard_plain_1xk8t.PlainCard_level-1_1xk8t {
  box-shadow: 0 1px 12px 0 rgba(208, 209, 209, 0.3);
}

.SpeechBubbleCard_speechBubble_xka1w {
  margin: 0 auto;
  position: relative;
  text-align: center;
  max-width: 276px;
  padding: var(--margin-sm) var(--margin-md);
}

.SpeechBubbleCard_kale_xka1w {
  background-color: #e5efe7;
}

.SpeechBubbleCard_white_xka1w {
  background-color: white;
  border: 1px solid var(--carrot-2);
}

.SpeechBubbleCard_carrot_xka1w {
  background-color: var(--carrot-0);
  border: 1px solid var(--carrot-3);
}

@media (min-width: 1024px) {
  .SpeechBubbleCard_speechBubble_xka1w {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
}

.Grid_root_fq82r {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 0px) {
  .flexbox_justify-content-xs-flex-start_1ipcx {
    justify-content: flex-start;
  }
  .flexbox_justify-content-xs-flex-end_1ipcx {
    justify-content: flex-end;
  }
  .flexbox_justify-content-xs-space-between_1ipcx {
    justify-content: space-between;
  }
  .flexbox_justify-content-xs-space-around_1ipcx {
    justify-content: space-around;
  }
  .flexbox_justify-content-xs-center_1ipcx {
    justify-content: center;
  }

  .flexbox_flex-direction-xs-row_1ipcx {
    flex-direction: row;
  }
  .flexbox_flex-direction-xs-column_1ipcx {
    flex-direction: column;
  }
  .flexbox_flex-direction-xs-row-reverse_1ipcx {
    flex-direction: row-reverse;
  }
  .flexbox_flex-direction-xs-column-reverse_1ipcx {
    flex-direction: column-reverse;
  }

  .flexbox_align-items-xs-flex-start_1ipcx {
    align-items: flex-start;
  }
  .flexbox_align-items-xs-flex-end_1ipcx {
    align-items: flex-end;
  }
  .flexbox_align-items-xs-center_1ipcx {
    align-items: center;
  }
  .flexbox_align-items-xs-baseline_1ipcx {
    align-items: baseline;
  }
  .flexbox_align-items-xs-stretch_1ipcx {
    align-items: stretch;
  }

  .flexbox_grow-xs-1_1ipcx > * {
    flex-grow: 1;
  }
  .flexbox_grow-xs-0_1ipcx > * {
    flex-grow: 0;
  }
}

@media (min-width: 375px) {
  .flexbox_justify-content-sm-flex-start_1ipcx {
    justify-content: flex-start;
  }
  .flexbox_justify-content-sm-flex-end_1ipcx {
    justify-content: flex-end;
  }
  .flexbox_justify-content-sm-space-between_1ipcx {
    justify-content: space-between;
  }
  .flexbox_justify-content-sm-space-around_1ipcx {
    justify-content: space-around;
  }
  .flexbox_justify-content-sm-center_1ipcx {
    justify-content: center;
  }

  .flexbox_flex-direction-sm-row_1ipcx {
    flex-direction: row;
  }
  .flexbox_flex-direction-sm-column_1ipcx {
    flex-direction: column;
  }
  .flexbox_flex-direction-sm-row-reverse_1ipcx {
    flex-direction: row-reverse;
  }
  .flexbox_flex-direction-sm-column-reverse_1ipcx {
    flex-direction: column-reverse;
  }

  .flexbox_align-items-sm-flex-start_1ipcx {
    align-items: flex-start;
  }
  .flexbox_align-items-sm-flex-end_1ipcx {
    align-items: flex-end;
  }
  .flexbox_align-items-sm-center_1ipcx {
    align-items: center;
  }
  .flexbox_align-items-sm-baseline_1ipcx {
    align-items: baseline;
  }
  .flexbox_align-items-sm-stretch_1ipcx {
    align-items: stretch;
  }
  .flexbox_grow-sm-1_1ipcx > * {
    flex-grow: 1;
  }
  .flexbox_grow-sm-0_1ipcx > * {
    flex-grow: 0;
  }
}

@media (min-width: 710px) {
  .flexbox_justify-content-md-flex-start_1ipcx {
    justify-content: flex-start;
  }
  .flexbox_justify-content-md-flex-end_1ipcx {
    justify-content: flex-end;
  }
  .flexbox_justify-content-md-space-between_1ipcx {
    justify-content: space-between;
  }
  .flexbox_justify-content-md-space-around_1ipcx {
    justify-content: space-around;
  }
  .flexbox_justify-content-md-center_1ipcx {
    justify-content: center;
  }

  .flexbox_flex-direction-md-row_1ipcx {
    flex-direction: row;
  }
  .flexbox_flex-direction-md-column_1ipcx {
    flex-direction: column;
  }
  .flexbox_flex-direction-md-row-reverse_1ipcx {
    flex-direction: row-reverse;
  }
  .flexbox_flex-direction-md-column-reverse_1ipcx {
    flex-direction: column-reverse;
  }

  .flexbox_align-items-md-flex-start_1ipcx {
    align-items: flex-start;
  }
  .flexbox_align-items-md-flex-end_1ipcx {
    align-items: flex-end;
  }
  .flexbox_align-items-md-center_1ipcx {
    align-items: center;
  }
  .flexbox_align-items-md-baseline_1ipcx {
    align-items: baseline;
  }
  .flexbox_align-items-md-stretch_1ipcx {
    align-items: stretch;
  }

  .flexbox_grow-md-1_1ipcx > * {
    flex-grow: 1;
  }
  .flexbox_grow-md-0_1ipcx > * {
    flex-grow: 0;
  }
}

@media (min-width: 1024px) {
  .flexbox_justify-content-lg-flex-start_1ipcx {
    justify-content: flex-start;
  }
  .flexbox_justify-content-lg-flex-end_1ipcx {
    justify-content: flex-end;
  }
  .flexbox_justify-content-lg-space-between_1ipcx {
    justify-content: space-between;
  }
  .flexbox_justify-content-lg-space-around_1ipcx {
    justify-content: space-around;
  }
  .flexbox_justify-content-lg-center_1ipcx {
    justify-content: center;
  }

  .flexbox_flex-direction-lg-row_1ipcx {
    flex-direction: row;
  }
  .flexbox_flex-direction-lg-column_1ipcx {
    flex-direction: column;
  }
  .flexbox_flex-direction-lg-row-reverse_1ipcx {
    flex-direction: row-reverse;
  }
  .flexbox_flex-direction-lg-column-reverse_1ipcx {
    flex-direction: column-reverse;
  }

  .flexbox_align-items-lg-flex-start_1ipcx {
    align-items: flex-start;
  }
  .flexbox_align-items-lg-flex-end_1ipcx {
    align-items: flex-end;
  }
  .flexbox_align-items-lg-center_1ipcx {
    align-items: center;
  }
  .flexbox_align-items-lg-baseline_1ipcx {
    align-items: baseline;
  }
  .flexbox_align-items-lg-stretch_1ipcx {
    align-items: baseline;
  }

  .flexbox_grow-lg-1_1ipcx > * {
    flex-grow: 1;
  }
  .flexbox_grow-lg-0_1ipcx > * {
    flex-grow: 0;
  }
}

@media (min-width: 1400px) {
  .flexbox_justify-content-xl-flex-start_1ipcx {
    justify-content: flex-start;
  }
  .flexbox_justify-content-xl-flex-end_1ipcx {
    justify-content: flex-end;
  }
  .flexbox_justify-content-xl-space-between_1ipcx {
    justify-content: space-between;
  }
  .flexbox_justify-content-xl-space-around_1ipcx {
    justify-content: space-around;
  }
  .flexbox_justify-content-xl-center_1ipcx {
    justify-content: center;
  }

  .flexbox_flex-direction-xl-row_1ipcx {
    flex-direction: row;
  }
  .flexbox_flex-direction-xl-column_1ipcx {
    flex-direction: column;
  }
  .flexbox_flex-direction-xl-row-reverse_1ipcx {
    flex-direction: row-reverse;
  }
  .flexbox_flex-direction-xl-column-reverse_1ipcx {
    flex-direction: column-reverse;
  }

  .flexbox_align-items-xl-flex-start_1ipcx {
    align-items: flex-start;
  }
  .flexbox_align-items-xl-flex-end_1ipcx {
    align-items: flex-end;
  }
  .flexbox_align-items-xl-center_1ipcx {
    align-items: center;
  }
  .flexbox_align-items-xl-baseline_1ipcx {
    align-items: baseline;
  }
  .flexbox_align-items-xl-stretch_1ipcx {
    align-items: stretch;
  }

  .flexbox_grow-xl-1_1ipcx > * {
    flex-grow: 1;
  }
  .flexbox_grow-xl-0_1ipcx > * {
    flex-grow: 0;
  }
}

.gap_gap_njo60 {
  --column-gap: 0px;
  --row-gap: 0px;
}

.gap_col-gap_njo60 {
  -moz-column-gap: var(--column-gap);
       column-gap: var(--column-gap);
}

.gap_row-gap_njo60 {
  row-gap: var(--row-gap);
}

@media (min-width: 0px) {
  .gap_xs-col-none_njo60 {
    --column-gap: 0px;
  }
  .gap_xs-col-xxs_njo60 {
    --column-gap: var(--tfd-spacing-xxs);
  }
  .gap_xs-col-xs_njo60 {
    --column-gap: var(--tfd-spacing-xs);
  }
  .gap_xs-col-sm_njo60 {
    --column-gap: var(--tfd-spacing-sm);
  }
  .gap_xs-col-md_njo60 {
    --column-gap: var(--tfd-spacing-md);
  }
  .gap_xs-col-lg_njo60 {
    --column-gap: var(--tfd-spacing-lg);
  }
  .gap_xs-col-xl_njo60 {
    --column-gap: var(--tfd-spacing-xl);
  }
  .gap_xs-col-xxl_njo60 {
    --column-gap: var(--tfd-spacing-xxl);
  }
  .gap_xs-col-3xl_njo60 {
    --column-gap: var(--tfd-spacing-3xl);
  }
  .gap_xs-col-4xl_njo60 {
    --column-gap: var(--tfd-spacing-4xl);
  }

  .gap_xs-row-none_njo60 {
    --row-gap: 0px;
  }
  .gap_xs-row-xxs_njo60 {
    --row-gap: var(--tfd-spacing-xxs);
  }
  .gap_xs-row-xs_njo60 {
    --row-gap: var(--tfd-spacing-xs);
  }
  .gap_xs-row-sm_njo60 {
    --row-gap: var(--tfd-spacing-sm);
  }
  .gap_xs-row-md_njo60 {
    --row-gap: var(--tfd-spacing-md);
  }
  .gap_xs-row-lg_njo60 {
    --row-gap: var(--tfd-spacing-lg);
  }
  .gap_xs-row-xl_njo60 {
    --row-gap: var(--tfd-spacing-xl);
  }
  .gap_xs-row-xxl_njo60 {
    --row-gap: var(--tfd-spacing-xxl);
  }
  .gap_xs-row-3xl_njo60 {
    --row-gap: var(--tfd-spacing-3xl);
  }
  .gap_xs-row-4xl_njo60 {
    --row-gap: var(--tfd-spacing-4xl);
  }
}

@media (min-width: 375px) {
  .gap_sm-col-none_njo60 {
    --column-gap: 0px;
  }
  .gap_sm-col-xxs_njo60 {
    --column-gap: var(--tfd-spacing-xxs);
  }
  .gap_sm-col-xs_njo60 {
    --column-gap: var(--tfd-spacing-xs);
  }
  .gap_sm-col-sm_njo60 {
    --column-gap: var(--tfd-spacing-sm);
  }
  .gap_sm-col-md_njo60 {
    --column-gap: var(--tfd-spacing-md);
  }
  .gap_sm-col-lg_njo60 {
    --column-gap: var(--tfd-spacing-lg);
  }
  .gap_sm-col-xl_njo60 {
    --column-gap: var(--tfd-spacing-xl);
  }
  .gap_sm-col-xxl_njo60 {
    --column-gap: var(--tfd-spacing-xxl);
  }
  .gap_sm-col-3xl_njo60 {
    --row-gap: var(--tfd-spacing-3xl);
  }
  .gap_sm-col-4xl_njo60 {
    --row-gap: var(--tfd-spacing-4xl);
  }

  .gap_sm-row-none_njo60 {
    --row-gap: 0px;
  }
  .gap_sm-row-xxs_njo60 {
    --row-gap: var(--tfd-spacing-xxs);
  }
  .gap_sm-row-xs_njo60 {
    --row-gap: var(--tfd-spacing-xs);
  }
  .gap_sm-row-sm_njo60 {
    --row-gap: var(--tfd-spacing-sm);
  }
  .gap_sm-row-md_njo60 {
    --row-gap: var(--tfd-spacing-md);
  }
  .gap_sm-row-lg_njo60 {
    --row-gap: var(--tfd-spacing-lg);
  }
  .gap_sm-row-xl_njo60 {
    --row-gap: var(--tfd-spacing-xl);
  }
  .gap_sm-row-xxl_njo60 {
    --row-gap: var(--tfd-spacing-xxl);
  }
  .gap_sm-row-3xl_njo60 {
    --row-gap: var(--tfd-spacing-3xl);
  }
  .gap_sm-row-4xl_njo60 {
    --row-gap: var(--tfd-spacing-4xl);
  }
}

@media (min-width: 710px) {
  .gap_md-col-none_njo60 {
    --column-gap: 0px;
  }
  .gap_md-col-xxs_njo60 {
    --column-gap: var(--tfd-spacing-xxs);
  }
  .gap_md-col-xs_njo60 {
    --column-gap: var(--tfd-spacing-xs);
  }
  .gap_md-col-sm_njo60 {
    --column-gap: var(--tfd-spacing-sm);
  }
  .gap_md-col-md_njo60 {
    --column-gap: var(--tfd-spacing-md);
  }
  .gap_md-col-lg_njo60 {
    --column-gap: var(--tfd-spacing-lg);
  }
  .gap_md-col-xl_njo60 {
    --column-gap: var(--tfd-spacing-xl);
  }
  .gap_md-col-xxl_njo60 {
    --col-gap: var(--tfd-spacing-xxl);
  }
  .gap_md-col-3xl_njo60 {
    --col-gap: var(--tfd-spacing-3xl);
  }
  .gap_md-col-4xl_njo60 {
    --col-gap: var(--tfd-spacing-4xl);
  }

  .gap_md-row-none_njo60 {
    --row-gap: 0px;
  }
  .gap_md-row-xxs_njo60 {
    --row-gap: var(--tfd-spacing-xxs);
  }
  .gap_md-row-xs_njo60 {
    --row-gap: var(--tfd-spacing-xs);
  }
  .gap_md-row-sm_njo60 {
    --row-gap: var(--tfd-spacing-sm);
  }
  .gap_md-row-md_njo60 {
    --row-gap: var(--tfd-spacing-md);
  }
  .gap_md-row-lg_njo60 {
    --row-gap: var(--tfd-spacing-lg);
  }
  .gap_md-row-xl_njo60 {
    --row-gap: var(--tfd-spacing-xl);
  }
  .gap_md-row-xxl_njo60 {
    --row-gap: var(--tfd-spacing-xxl);
  }
  .gap_md-row-3xl_njo60 {
    --row-gap: var(--tfd-spacing-3xl);
  }
  .gap_md-row-4xl_njo60 {
    --row-gap: var(--tfd-spacing-4xl);
  }
}

@media (min-width: 1024px) {
  .gap_lg-col-none_njo60 {
    --colum-gap: 0px;
  }
  .gap_lg-col-xxs_njo60 {
    --column-gap: var(--tfd-spacing-xxs);
  }
  .gap_lg-col-xs_njo60 {
    --column-gap: var(--tfd-spacing-xs);
  }
  .gap_lg-col-sm_njo60 {
    --column-gap: var(--tfd-spacing-sm);
  }
  .gap_lg-col-md_njo60 {
    --column-gap: var(--tfd-spacing-md);
  }
  .gap_lg-col-lg_njo60 {
    --column-gap: var(--tfd-spacing-lg);
  }
  .gap_lg-col-xl_njo60 {
    --column-gap: var(--tfd-spacing-xl);
  }
  .gap_lg-col-xxl_njo60 {
    --row-gap: var(--tfd-spacing-xxl);
  }
  .gap_lg-col-3xl_njo60 {
    --row-gap: var(--tfd-spacing-3xl);
  }
  .gap_lg-col-4xl_njo60 {
    --row-gap: var(--tfd-spacing-4xl);
  }

  .gap_lg-row-none_njo60 {
    --row-gap: 0px;
  }
  .gap_lg-row-xxs_njo60 {
    --row-gap: var(--tfd-spacing-xxs);
  }
  .gap_lg-row-xs_njo60 {
    --row-gap: var(--tfd-spacing-xs);
  }
  .gap_lg-row-sm_njo60 {
    --row-gap: var(--tfd-spacing-sm);
  }
  .gap_lg-row-md_njo60 {
    --row-gap: var(--tfd-spacing-md);
  }
  .gap_lg-row-lg_njo60 {
    --row-gap: var(--tfd-spacing-lg);
  }
  .gap_lg-row-xl_njo60 {
    --row-gap: var(--tfd-spacing-xl);
  }
  .gap_lg-row-xxl_njo60 {
    --row-gap: var(--tfd-spacing-xxl);
  }
  .gap_lg-row-3xl_njo60 {
    --row-gap: var(--tfd-spacing-3xl);
  }
  .gap_lg-row-4xl_njo60 {
    --row-gap: var(--tfd-spacing-4xl);
  }
}

@media (min-width: 1400px) {
  .gap_xl-col-none_njo60 {
    --column-gap: 0px;
  }
  .gap_xl-col-xxs_njo60 {
    --column-gap: var(--tfd-spacing-xxs);
  }
  .gap_xl-col-xs_njo60 {
    --column-gap: var(--tfd-spacing-xs);
  }
  .gap_xl-col-sm_njo60 {
    --column-gap: var(--tfd-spacing-sm);
  }
  .gap_xl-col-md_njo60 {
    --column-gap: var(--tfd-spacing-md);
  }
  .gap_xl-col-lg_njo60 {
    --column-gap: var(--tfd-spacing-lg);
  }
  .gap_xl-col-xl_njo60 {
    --column-gap: var(--tfd-spacing-xl);
  }
  .gap_xl-col-xxl_njo60 {
    --column-gap: var(--tfd-spacing-xxl);
  }
  .gap_xl-col-3xl_njo60 {
    --column-gap: var(--tfd-spacing-3xl);
  }
  .gap_xl-col-4xl_njo60 {
    --column-gap: var(--tfd-spacing-4xl);
  }

  .gap_xl-row-none_njo60 {
    --row-gap: 0px;
  }
  .gap_xl-row-xxs_njo60 {
    --row-gap: var(--tfd-spacing-xxs);
  }
  .gap_xl-row-xs_njo60 {
    --row-gap: var(--tfd-spacing-xs);
  }
  .gap_xl-row-sm_njo60 {
    --row-gap: var(--tfd-spacing-sm);
  }
  .gap_xl-row-md_njo60 {
    --row-gap: var(--tfd-spacing-md);
  }
  .gap_xl-row-lg_njo60 {
    --row-gap: var(--tfd-spacing-lg);
  }
  .gap_xl-row-xl_njo60 {
    --row-gap: var(--tfd-spacing-xl);
  }
  .gap_xl-row-xxl_njo60 {
    --row-gap: var(--tfd-spacing-xxl);
  }
  .gap_xl-row-3xl_njo60 {
    --row-gap: var(--tfd-spacing-3xl);
  }
  .gap_xl-row-4xl_njo60 {
    --row-gap: var(--tfd-spacing-4xl);
  }
}

.GridItem_root_1yvng {
  --span-1: 8.333%;
  --offset-1: calc(1 - 0.0833);
  --span-2: 16.666%;
  --offset-2: calc(1 - 0.1666);
  --span-3: 25%;
  --offset-3: calc(1 - 0.25);
  --span-4: 33.333%;
  --offset-4: calc(1 - 0.3333);
  --span-5: 41.666%;
  --offset-5: calc(1 - 0.4166);
  --span-6: 50%;
  --offset-6: calc(1 - 0.5);
  --span-7: 58.333%;
  --offset-7: calc(1 - 0.5833);
  --span-8: 66.666%;
  --offset-8: calc(1 - 0.6666);
  --span-9: 75%;
  --offset-9: calc(1 - 0.75);
  --span-10: 83.333%;
  --offset-10: calc(1 - 0.8333);
  --span-11: 91.666%;
  --offset-11: calc(1 - 0.9166);
  --span-12: 100%;
  --offset-12: 0;

  box-sizing: border-box;
  flex: 0 0 auto;
}

.GridItem_flex_1yvng {
  display: flex;
}

@media (min-width: 0px) {
  .GridItem_col-xs-1_1yvng {
    width: calc(var(--span-1) - var(--column-gap) * var(--offset-1));
  }
  .GridItem_col-xs-2_1yvng {
    width: calc(var(--span-2) - var(--column-gap) * var(--offset-2));
  }
  .GridItem_col-xs-3_1yvng {
    width: calc(var(--span-3) - var(--column-gap) * var(--offset-3));
  }
  .GridItem_col-xs-4_1yvng {
    width: calc(var(--span-4) - var(--column-gap) * var(--offset-4));
  }
  .GridItem_col-xs-5_1yvng {
    width: calc(var(--span-5) - var(--column-gap) * var(--offset-5));
  }
  .GridItem_col-xs-6_1yvng {
    width: calc(var(--span-6) - var(--column-gap) * var(--offset-6));
  }
  .GridItem_col-xs-7_1yvng {
    width: calc(var(--span-7) - var(--column-gap) * var(--offset-7));
  }
  .GridItem_col-xs-8_1yvng {
    width: calc(var(--span-8) - var(--column-gap) * var(--offset-8));
  }
  .GridItem_col-xs-9_1yvng {
    width: calc(var(--span-9) - var(--column-gap) * var(--offset-9));
  }
  .GridItem_col-xs-10_1yvng {
    width: calc(var(--span-10) - var(--column-gap) * var(--offset-10));
  }
  .GridItem_col-xs-11_1yvng {
    width: calc(var(--span-11) - var(--column-gap) * var(--offset-11));
  }
  .GridItem_col-xs-12_1yvng {
    width: calc(var(--span-12) - var(--column-gap) * var(--offset-12));
  }
}

@media (min-width: 375px) {
  .GridItem_col-sm_1yvng {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .GridItem_col-sm-1_1yvng {
    width: calc(var(--span-1) - var(--column-gap) * var(--offset-1));
  }
  .GridItem_col-sm-2_1yvng {
    width: calc(var(--span-2) - var(--column-gap) * var(--offset-2));
  }
  .GridItem_col-sm-3_1yvng {
    width: calc(var(--span-3) - var(--column-gap) * var(--offset-3));
  }
  .GridItem_col-sm-4_1yvng {
    width: calc(var(--span-4) - var(--column-gap) * var(--offset-4));
  }
  .GridItem_col-sm-5_1yvng {
    width: calc(var(--span-5) - var(--column-gap) * var(--offset-5));
  }
  .GridItem_col-sm-6_1yvng {
    width: calc(var(--span-6) - var(--column-gap) * var(--offset-6));
  }
  .GridItem_col-sm-7_1yvng {
    width: calc(var(--span-7) - var(--column-gap) * var(--offset-7));
  }
  .GridItem_col-sm-8_1yvng {
    width: calc(var(--span-8) - var(--column-gap) * var(--offset-8));
  }
  .GridItem_col-sm-9_1yvng {
    width: calc(var(--span-9) - var(--column-gap) * var(--offset-9));
  }
  .GridItem_col-sm-10_1yvng {
    width: calc(var(--span-10) - var(--column-gap) * var(--offset-10));
  }
  .GridItem_col-sm-11_1yvng {
    width: calc(var(--span-11) - var(--column-gap) * var(--offset-11));
  }
  .GridItem_col-sm-12_1yvng {
    width: calc(var(--span-12) - var(--column-gap) * var(--offset-12));
  }
}

@media (min-width: 710px) {
  .GridItem_col-md-1_1yvng {
    width: calc(var(--span-1) - var(--column-gap) * var(--offset-1));
  }
  .GridItem_col-md-2_1yvng {
    width: calc(var(--span-2) - var(--column-gap) * var(--offset-2));
  }
  .GridItem_col-md-3_1yvng {
    width: calc(var(--span-3) - var(--column-gap) * var(--offset-3));
  }
  .GridItem_col-md-4_1yvng {
    width: calc(var(--span-4) - var(--column-gap) * var(--offset-4));
  }
  .GridItem_col-md-5_1yvng {
    width: calc(var(--span-5) - var(--column-gap) * var(--offset-5));
  }
  .GridItem_col-md-6_1yvng {
    width: calc(var(--span-6) - var(--column-gap) * var(--offset-6));
  }
  .GridItem_col-md-7_1yvng {
    width: calc(var(--span-7) - var(--column-gap) * var(--offset-7));
  }
  .GridItem_col-md-8_1yvng {
    width: calc(var(--span-8) - var(--column-gap) * var(--offset-8));
  }
  .GridItem_col-md-9_1yvng {
    width: calc(var(--span-9) - var(--column-gap) * var(--offset-9));
  }
  .GridItem_col-md-10_1yvng {
    width: calc(var(--span-10) - var(--column-gap) * var(--offset-10));
  }
  .GridItem_col-md-11_1yvng {
    width: calc(var(--span-11) - var(--column-gap) * var(--offset-11));
  }
  .GridItem_col-md-12_1yvng {
    width: calc(var(--span-12) - var(--column-gap) * var(--offset-12));
  }
}

@media (min-width: 1024px) {
  .GridItem_col-lg-1_1yvng {
    width: calc(var(--span-1) - var(--column-gap) * var(--offset-1));
  }
  .GridItem_col-lg-2_1yvng {
    width: calc(var(--span-2) - var(--column-gap) * var(--offset-2));
  }
  .GridItem_col-lg-3_1yvng {
    width: calc(var(--span-3) - var(--column-gap) * var(--offset-3));
  }
  .GridItem_col-lg-4_1yvng {
    width: calc(var(--span-4) - var(--column-gap) * var(--offset-4));
  }
  .GridItem_col-lg-5_1yvng {
    width: calc(var(--span-5) - var(--column-gap) * var(--offset-5));
  }
  .GridItem_col-lg-6_1yvng {
    width: calc(var(--span-6) - var(--column-gap) * var(--offset-6));
  }
  .GridItem_col-lg-7_1yvng {
    width: calc(var(--span-7) - var(--column-gap) * var(--offset-7));
  }
  .GridItem_col-lg-8_1yvng {
    width: calc(var(--span-8) - var(--column-gap) * var(--offset-8));
  }
  .GridItem_col-lg-9_1yvng {
    width: calc(var(--span-9) - var(--column-gap) * var(--offset-9));
  }
  .GridItem_col-lg-10_1yvng {
    width: calc(var(--span-10) - var(--column-gap) * var(--offset-10));
  }
  .GridItem_col-lg-11_1yvng {
    width: calc(var(--span-11) - var(--column-gap) * var(--offset-11));
  }
  .GridItem_col-lg-12_1yvng {
    width: calc(var(--span-12) - var(--column-gap) * var(--offset-12));
  }
}

@media (min-width: 1400px) {
  .GridItem_col-xl-1_1yvng {
    width: calc(var(--span-1) - var(--column-gap) * var(--offset-1));
  }
  .GridItem_col-xl-2_1yvng {
    width: calc(var(--span-2) - var(--column-gap) * var(--offset-2));
  }
  .GridItem_col-xl-3_1yvng {
    width: calc(var(--span-3) - var(--column-gap) * var(--offset-3));
  }
  .GridItem_col-xl-4_1yvng {
    width: calc(var(--span-4) - var(--column-gap) * var(--offset-4));
  }
  .GridItem_col-xl-5_1yvng {
    width: calc(var(--span-5) - var(--column-gap) * var(--offset-5));
  }
  .GridItem_col-xl-6_1yvng {
    width: calc(var(--span-6) - var(--column-gap) * var(--offset-6));
  }
  .GridItem_col-xl-7_1yvng {
    width: calc(var(--span-7) - var(--column-gap) * var(--offset-7));
  }
  .GridItem_col-xl-8_1yvng {
    width: calc(var(--span-8) - var(--column-gap) * var(--offset-8));
  }
  .GridItem_col-xl-9_1yvng {
    width: calc(var(--span-9) - var(--column-gap) * var(--offset-9));
  }
  .GridItem_col-xl-10_1yvng {
    width: calc(var(--span-10) - var(--column-gap) * var(--offset-10));
  }
  .GridItem_col-xl-11_1yvng {
    width: calc(var(--span-11) - var(--column-gap) * var(--offset-11));
  }
  .GridItem_col-xl-12_1yvng {
    width: calc(var(--span-12) - var(--column-gap) * var(--offset-12));
  }
}

.Tail_arrow_6rw8i {
  top: -22px;
  height: 21px;
  position: absolute;
  font-size: 16px;
}

.Tail_start_6rw8i {
  left: 7%;
}

.Tail_end_6rw8i {
  right: 12%;
  transform: rotateY(180deg);
}

.Carousel_container_zk4bn {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  position: relative;
}

.Carousel_horizontalSnap_zk4bn {
  flex: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.Carousel_next_zk4bn {
  margin-left: 12px;
}

.Carousel_previous_zk4bn {
  margin-right: 12px;
}

.Carousel_blank_zk4bn {
  min-height: 100%;
  min-width: 1px;
  display: block;
  opacity: 0;
}

.ScrollSnap_container_10ty9 {
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;

  display: flex;
  flex-direction: row;

  width: 100%;

  /** Required for Safari */
  -webkit-overflow-scrolling: touch;
  /** Fix IE11 and Edge */
  -ms-overflow-style: none;
  /** Remove scrollbar in Firefox */
  scrollbar-width: none;
}

.ScrollSnap_container_10ty9::-webkit-scrollbar {
  /** Remove scrollbar for Chrome/Safari */
  display: none;
}

.ScrollSnap_item_10ty9 {
  scroll-snap-align: center;
}

.CarouselItem_container_1m8go + .CarouselItem_container_1m8go {
  margin-left: 12px;
}

@media (min-width: 1024px) {
  .CarouselItem_container_1m8go + .CarouselItem_container_1m8go {
    margin-left: 30px;
  }
}

.CarouselItem_fullWidth_1m8go {
  flex-shrink: 0;
  flex-basis: 100%;
}

.Checkbox_container_1bpor,
.Checkbox_container_1bpor *,
.Checkbox_container_1bpor *::before,
.Checkbox_container_1bpor *::after {
  box-sizing: border-box;
}

.Checkbox_container_1bpor {
  --tfd-checkbox-input-size: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}

.Checkbox_container_1bpor.Checkbox_disabled_1bpor {
  cursor: default;
}

.Checkbox_container_1bpor + .Checkbox_container_1bpor {
  margin-top: var(--tfd-spacing-sm);
}

.Checkbox_checkbox_1bpor {
  outline: none;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  height: var(--tfd-checkbox-input-size);
  width: var(--tfd-checkbox-input-size);
  border-radius: var(--tfd-border-radius-sm);
  border: solid 1px var(--charcoal-2);
  pointer-events: none;
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: border-color, background-color, box-shadow;
}

.Checkbox_checkbox_1bpor > svg {
  opacity: 0;
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: opacity;
}

.Checkbox_container_1bpor.Checkbox_checked_1bpor .Checkbox_checkbox_1bpor > svg {
  opacity: 1;
}

.Checkbox_container_1bpor:hover:not(.Checkbox_disabled_1bpor) .Checkbox_checkbox_1bpor {
  border-color: var(--kale-2);
}

.Checkbox_container_1bpor.Checkbox_disabled_1bpor .Checkbox_checkbox_1bpor {
  border-color: var(--charcoal-1);
}

.Checkbox_container_1bpor.Checkbox_invalid_1bpor .Checkbox_checkbox_1bpor {
  border-color: var(--error-red);
}

.Checkbox_container_1bpor.Checkbox_focus_1bpor .Checkbox_checkbox_1bpor {
  border-color: var(--kale-2);
  box-shadow: var(--tfd-outline-box-shadow);
}

.Checkbox_container_1bpor.Checkbox_invalid_1bpor.Checkbox_focus_1bpor .Checkbox_checkbox_1bpor {
  border-color: var(--error-red);
}

.Checkbox_container_1bpor.Checkbox_checked_1bpor .Checkbox_checkbox_1bpor {
  border-color: var(--kale-2);
  background: var(--kale-2);
}

.Checkbox_container_1bpor.Checkbox_checked_1bpor.Checkbox_disabled_1bpor .Checkbox_checkbox_1bpor {
  border-color: var(--charcoal-2);
  background: var(--charcoal-2);
}

.Checkbox_container_1bpor.Checkbox_checked_1bpor.Checkbox_invalid_1bpor .Checkbox_checkbox_1bpor {
  border-color: var(--error-red);
  background: var(--error-red);
}

.Checkbox_label_1bpor {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2em;
  color: var(--charcoal-3);
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: color;
  padding-left: var(--tfd-spacing-sm);
}

.Checkbox_container_1bpor.Checkbox_disabled_1bpor:not(.Checkbox_checked_1bpor) .Checkbox_label_1bpor {
  color: var(--charcoal-2);
}

.CollapsibleCard_container_3kdhx {
  display: block;
  padding: var(--margin-lg);
}

.CollapsibleCard_container_3kdhx + .CollapsibleCard_container_3kdhx {
  margin-top: var(--margin-lg);
}

@media (min-width: 1024px) {
  .CollapsibleCard_container_3kdhx {
    padding: var(--margin-xl);
  }
}

.base_init_17ipr {
  opacity: 0;
}

.base_entered_17ipr {
  opacity: 1;
}

.base_exited_17ipr {
  opacity: 0;
}

.base_unmounted_17ipr {
}

@keyframes fade_fade-in_x46zu {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade_fade-out_x46zu {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fade_entering_x46zu {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: fade_fade-in_x46zu;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}
.fade_exiting_x46zu {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: fade_fade-out_x46zu;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}

@keyframes slide-down_slide-down-in_2cg08 {
  0% {
    transform: translate(0px, -12px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes slide-down_slide-down-out_2cg08 {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 12px);
  }
}
@keyframes slide-down_fade-in_2cg08 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide-down_fade-out_2cg08 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide-down_scale-height-up_2cg08 {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 1000px;
  }
}
@keyframes slide-down_scale-height-down_2cg08 {
  0% {
    max-height: 1000px;
  }
  100% {
    max-height: 0px;
  }
}
.slide-down_entering_2cg08 {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: slide-down_slide-down-in_2cg08, slide-down_fade-in_2cg08, slide-down_scale-height-up_2cg08;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}
.slide-down_exiting_2cg08 {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: slide-down_slide-down-out_2cg08, slide-down_fade-out_2cg08, slide-down_scale-height-down_2cg08;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}

@keyframes slide-up_slide-up-in_1te9p {
  0% {
    transform: translate(0px, 12px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes slide-up_slide-up-out_1te9p {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, -12px);
  }
}
@keyframes slide-up_fade-in_1te9p {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide-up_fade-out_1te9p {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide-up_scale-height-up_1te9p {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 1000px;
  }
}
@keyframes slide-up_scale-height-down_1te9p {
  0% {
    max-height: 1000px;
  }
  100% {
    max-height: 0px;
  }
}
.slide-up_entering_1te9p {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: slide-up_slide-up-in_1te9p, slide-up_fade-in_1te9p, slide-up_scale-height-up_1te9p;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}
.slide-up_exiting_1te9p {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: slide-up_slide-up-out_1te9p, slide-up_fade-out_1te9p, slide-up_scale-height-down_1te9p;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}

.ToggleButton_button_8ufsi {
  display: flex;
  width: 100%;
  background: none;
  justify-content: space-between;
  align-items: center;
}

.Ellipsis_ellipsis_12hf3 {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ContentLoader_container_bz0dn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.base_init_1jx2g {
  opacity: 0;
}

.base_entered_1jx2g {
  opacity: 1;
}

.base_exited_1jx2g {
  opacity: 0;
}

.base_unmounted_1jx2g {
  opacity: 0;
}

@keyframes fade_fade-in_1nnm6 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade_fade-out_1nnm6 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fade_entering_1nnm6 {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: fade_fade-in_1nnm6;
  animation-duration:  var(--animation-standard-duration);
  animation-fill-mode: forwards;
}
.fade_exiting_1nnm6 {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: fade_fade-out_1nnm6;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}

@keyframes slide-down_slide-down-in_1lncp {
  0% {
    transform: translate(0px, -12px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes slide-down_slide-down-out_1lncp {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 12px);
  }
}
@keyframes slide-down_fade-in_1lncp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide-down_fade-out_1lncp {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.slide-down_entering_1lncp {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: slide-down_slide-down-in_1lncp, slide-down_fade-in_1lncp;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}
.slide-down_exiting_1lncp {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: slide-down_slide-down-out_1lncp, slide-down_fade-out_1lncp;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}

@keyframes slide-up_slide-up-in_19xqu {
  0% {
    transform: translate(0px, 12px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes slide-up_slide-up-out_19xqu {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, -12px);
  }
}
@keyframes slide-up_fade-in_19xqu {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide-up_fade-out_19xqu {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.slide-up_entering_19xqu {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: slide-up_slide-up-in_19xqu, slide-up_fade-in_19xqu;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}
.slide-up_exiting_19xqu {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: slide-up_slide-up-out_19xqu, slide-up_fade-out_19xqu;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}

/**
  * This is a generated file. Do not modify directly.
  * Instead use generateKeyframes.js
  */
@keyframes spring-down_spring-down-in_1ky3j {
  0% {
    transform: translate(0px, -12px);
  }
  2% {
    transform: translate(0px, -9.93px);
  }
  4% {
    transform: translate(0px, -7.27px);
  }
  6% {
    transform: translate(0px, -4.59px);
  }
  8% {
    transform: translate(0px, -2.27px);
  }
  10% {
    transform: translate(0px, -0.48px);
  }
  12% {
    transform: translate(0px, 0.72px);
  }
  14% {
    transform: translate(0px, 1.38px);
  }
  16% {
    transform: translate(0px, 1.62px);
  }
  18% {
    transform: translate(0px, 1.55px);
  }
  20% {
    transform: translate(0px, 1.29px);
  }
  22% {
    transform: translate(0px, 0.96px);
  }
  24% {
    transform: translate(0px, 0.61px);
  }
  26% {
    transform: translate(0px, 0.31px);
  }
  28% {
    transform: translate(0px, 0.08px);
  }
  30% {
    transform: translate(0px, -0.08px);
  }
  32% {
    transform: translate(0px, -0.17px);
  }
  34% {
    transform: translate(0px, -0.21px);
  }
  38% {
    transform: translate(0px, -0.17px);
  }
  40% {
    transform: translate(0px, -0.13px);
  }
  42% {
    transform: translate(0px, -0.08px);
  }
  44% {
    transform: translate(0px, -0.04px);
  }
  46% {
    transform: translate(0px, -0.01px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
/**
  * This is a generated file. Do not modify directly.
  * Instead use generateKeyframes.js
  */
@keyframes spring-down_spring-down-out_1ky3j {
  0% {
    transform: translate(0px, 0px);
  }
  2% {
    transform: translate(0px, 2.07px);
  }
  4% {
    transform: translate(0px, 4.73px);
  }
  6% {
    transform: translate(0px, 7.41px);
  }
  8% {
    transform: translate(0px, 9.73px);
  }
  10% {
    transform: translate(0px, 11.52px);
  }
  12% {
    transform: translate(0px, 12.72px);
  }
  14% {
    transform: translate(0px, 13.38px);
  }
  16% {
    transform: translate(0px, 13.62px);
  }
  18% {
    transform: translate(0px, 13.55px);
  }
  20% {
    transform: translate(0px, 13.29px);
  }
  22% {
    transform: translate(0px, 12.96px);
  }
  24% {
    transform: translate(0px, 12.61px);
  }
  26% {
    transform: translate(0px, 12.31px);
  }
  28% {
    transform: translate(0px, 12.08px);
  }
  30% {
    transform: translate(0px, 11.92px);
  }
  32% {
    transform: translate(0px, 11.83px);
  }
  34% {
    transform: translate(0px, 11.79px);
  }
  38% {
    transform: translate(0px, 11.83px);
  }
  40% {
    transform: translate(0px, 11.87px);
  }
  42% {
    transform: translate(0px, 11.92px);
  }
  44% {
    transform: translate(0px, 11.96px);
  }
  46% {
    transform: translate(0px, 11.99px);
  }
  100% {
    transform: translate(0px, 12px);
  }
}
@keyframes spring-down_fade-in_1ky3j {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spring-down_fade-out_1ky3j {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.spring-down_entering_1ky3j {
  animation-timing-function: linear, linear;
  animation-name: spring-down_spring-down-in_1ky3j, spring-down_fade-in_1ky3j;
  animation-duration: 600ms, 150ms;
  animation-fill-mode: forwards;
}
.spring-down_exiting_1ky3j {
  animation-timing-function: linear;
  animation-name: spring-down_spring-down-out_1ky3j, spring-down_fade-out_1ky3j;
  animation-duration: 600ms, 150ms;
  animation-fill-mode: forwards;
}

/**
  * This is a generated file. Do not modify directly.
  * Instead use generateKeyframes.js
  */
@keyframes spring-up_spring-up-in_qonhs {
  0% {
    transform: translate(0px, 12px);
  }
  2% {
    transform: translate(0px, 9.93px);
  }
  4% {
    transform: translate(0px, 7.27px);
  }
  6% {
    transform: translate(0px, 4.59px);
  }
  8% {
    transform: translate(0px, 2.27px);
  }
  10% {
    transform: translate(0px, 0.48px);
  }
  12% {
    transform: translate(0px, -0.72px);
  }
  14% {
    transform: translate(0px, -1.38px);
  }
  16% {
    transform: translate(0px, -1.62px);
  }
  18% {
    transform: translate(0px, -1.55px);
  }
  20% {
    transform: translate(0px, -1.29px);
  }
  22% {
    transform: translate(0px, -0.96px);
  }
  24% {
    transform: translate(0px, -0.61px);
  }
  26% {
    transform: translate(0px, -0.31px);
  }
  28% {
    transform: translate(0px, -0.08px);
  }
  30% {
    transform: translate(0px, 0.08px);
  }
  32% {
    transform: translate(0px, 0.17px);
  }
  34% {
    transform: translate(0px, 0.21px);
  }
  38% {
    transform: translate(0px, 0.17px);
  }
  40% {
    transform: translate(0px, 0.13px);
  }
  42% {
    transform: translate(0px, 0.08px);
  }
  44% {
    transform: translate(0px, 0.04px);
  }
  46% {
    transform: translate(0px, 0.01px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
/**
  * This is a generated file. Do not modify directly.
  * Instead use generateKeyframes.js
  */
@keyframes spring-up_spring-up-out_qonhs {
  0% {
    transform: translate(0px, 0px);
  }
  2% {
    transform: translate(0px, -2.07px);
  }
  4% {
    transform: translate(0px, -4.73px);
  }
  6% {
    transform: translate(0px, -7.41px);
  }
  8% {
    transform: translate(0px, -9.73px);
  }
  10% {
    transform: translate(0px, -11.52px);
  }
  12% {
    transform: translate(0px, -12.72px);
  }
  14% {
    transform: translate(0px, -13.38px);
  }
  16% {
    transform: translate(0px, -13.62px);
  }
  18% {
    transform: translate(0px, -13.55px);
  }
  20% {
    transform: translate(0px, -13.29px);
  }
  22% {
    transform: translate(0px, -12.96px);
  }
  24% {
    transform: translate(0px, -12.61px);
  }
  26% {
    transform: translate(0px, -12.31px);
  }
  28% {
    transform: translate(0px, -12.08px);
  }
  30% {
    transform: translate(0px, -11.92px);
  }
  32% {
    transform: translate(0px, -11.83px);
  }
  34% {
    transform: translate(0px, -11.79px);
  }
  38% {
    transform: translate(0px, -11.83px);
  }
  40% {
    transform: translate(0px, -11.87px);
  }
  42% {
    transform: translate(0px, -11.92px);
  }
  44% {
    transform: translate(0px, -11.96px);
  }
  46% {
    transform: translate(0px, -11.99px);
  }
  100% {
    transform: translate(0px, -12px);
  }
}
@keyframes spring-up_fade-in_qonhs {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spring-up_fade-out_qonhs {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.spring-up_entering_qonhs {
  animation-timing-function: linear, linear;
  animation-name: spring-up_spring-up-in_qonhs, spring-up_fade-in_qonhs;
  animation-duration: 600ms, 150ms;
  animation-fill-mode: forwards;
}
.spring-up_exiting_qonhs {
  animation-timing-function: linear;
  animation-name: spring-up_spring-up-out_qonhs, spring-up_fade-out_qonhs;
  animation-duration: 600ms, 150ms;
  animation-fill-mode: forwards;
}

.ContentWidth_content_15fnu {
  --spacing-lg: 20px;
  --spacing-xl: 30px;

  /* Up to medium viewports it should cover the entire container */
  --content-width-xs: 100%;
  --content-width-sm: 100%;
  --content-width-md: 100%;

  /* From large viewports restrict the content width. (TODO: how are these calculated) */
  --content-width-lg: 300px;
  --content-width-xl: 375px;
}

.ContentWidth_content_15fnu {
  width: var(--content-width-xs);
  box-sizing: border-box;
}

.ContentWidth_content_15fnu.ContentWidth_start_15fnu {
  margin-left: 0;
}

.ContentWidth_content_15fnu.ContentWidth_end_15fnu {
  margin-right: 0;
  margin-left: auto;
}

.ContentWidth_content_15fnu.ContentWidth_center_15fnu {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 375px) {
  .ContentWidth_content_15fnu {
    width: var(--content-width-sm);
  }
}

@media (min-width: 710px) {
  .ContentWidth_content_15fnu {
    width: var(--content-width-md);
  }
}

@media (min-width: 1024px) {
  .ContentWidth_content_15fnu,
  .ContentWidth_content_15fnu.ContentWidth_fr-1_15fnu {
    width: var(--content-width-lg);
  }

  .ContentWidth_content_15fnu.ContentWidth_fr-2_15fnu {
    width: calc(var(--content-width-lg) * 2 + var(--spacing-lg));
  }

  .ContentWidth_content_15fnu.ContentWidth_fr-3_15fnu {
    width: 100%;
  }

  .ContentWidth_content_15fnu.ContentWidth_withPadding_15fnu {
    padding: 0 var(--spacing-lg);
  }

  .ContentWidth_content_15fnu.ContentWidth_withGutter_15fnu {
    margin-right: var(--spacing-lg);
  }
}

@media (min-width: 1400px) {
  .ContentWidth_content_15fnu,
  .ContentWidth_content_15fnu.ContentWidth_fr-1_15fnu {
    width: var(--content-width-xl);
  }

  .ContentWidth_content_15fnu.ContentWidth_fr-2_15fnu {
    width: calc(var(--content-width-xl) * 2 + var(--spacing-xl));
  }

  .ContentWidth_content_15fnu.ContentWidth_fr-3_15fnu {
    width: 100%;
  }

  .ContentWidth_content_15fnu.ContentWidth_withPadding_15fnu {
    padding: 0 var(--spacing-xl);
  }

  .ContentWidth_content_15fnu.ContentWidth_withGutter_15fnu {
    margin-right: var(--spacing-xl);
  }
}

.StyledStripeInput_label_17erh {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: var(--tfd-fieldlabel-padding);
}

/** 
 * The important statements used in this file are needed so we beat Stripe's
 * inline styles.
 */

.StyledStripeInput_input_17erh {
  width: 100%;
  display: inline-block;
}

.FieldLabel_container_17nre {
  width: 100%;
  height: calc(
    var(--tfd-fieldlabel-height) + var(--tfd-fieldlabel-text-offset)
  );
  box-sizing: border-box;
  padding-top: var(--tfd-fieldlabel-text-offset);
  display: inline-block;
}

.FieldLabel_containerNoLabel_17nre {
  height: var(--tfd-fieldlabel-height);
  padding-top: 0;
}

.FieldLabel_label_17nre {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: var(--tfd-fieldlabel-radius);
  border: solid 1px var(--charcoal-0);
  transition: all 100ms ease-in;
  transition-property: border-color;
  background-color: var(--white);
  white-space: nowrap;
  text-align: left;
}

.FieldLabel_normal_17nre {
  border-color: var(--charcoal-0);
}

.FieldLabel_focus_17nre {
  border-color: var(--kale-3);
}

.FieldLabel_invalid_17nre {
  border-color: var(--error-red);
}

.FieldLabel_disabled_17nre {
  border-color: var(--charcoal-0);
  background-color: var(--oyster-0);
}

.FieldLabel_highlighted_17nre {
  border-color: var(--kale-1);
  outline: 1px solid var(--kale-1);
}

.text-styles_text_s1z6g {
  position: absolute;
  overflow: hidden;

  top: 24.5px;
  left: 4px;
  right: 8px;

  padding: 0 4px;

  display: block;
  box-sizing: border-box;
  color: var(--charcoal-2);
  cursor: text;
  transition: all 100ms ease-in;
  transition-property: color, transform;
  transform: translateY(-50%);
  transform-origin: top left;
  pointer-events: none;

  font-family: var(--tfd-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.5px;
}

/* Wrapper of the text to render as white background. */

.text-styles_text_s1z6g span {
  transition: background 100ms ease-in;
  padding: 0 4px;
}

.text-styles_normal_s1z6g.text-styles_exited_s1z6g,
.text-styles_normal_s1z6g.text-styles_exiting_s1z6g {
  color: var(--charcoal-2);
}

.text-styles_normal_s1z6g.text-styles_entered_s1z6g,
.text-styles_normal_s1z6g.text-styles_entering_s1z6g {
  color: var(--kale-3);
}

.text-styles_focus_s1z6g {
  color: var(--kale-3);
}

.text-styles_invalid_s1z6g {
  color: var(--error-red);
}

.text-styles_disabled_s1z6g.text-styles_entering_s1z6g,
.text-styles_disabled_s1z6g.text-styles_entered_s1z6g {
  transition: none;
}

.text-styles_disabled_s1z6g.text-styles_exiting_s1z6g,
.text-styles_disabled_s1z6g.text-styles_exited_s1z6g {
  color: var(--charcoal-2);
}

.text-styles_entering_s1z6g,
.text-styles_entered_s1z6g {
  transform: translate(2px, -160%) scale(0.75);
}

.text-styles_entering_s1z6g span,
.text-styles_entered_s1z6g span {
  background: var(--white);
}

/** 
 * Do not combine this with other classes. This class must be defined separately
 * since it targets a special webkit class
 */

:-webkit-autofill + .text-styles_text_s1z6g {
  transform: translate(2px, -160%) scale(0.75);
}

:-webkit-autofill + .text-styles_text_s1z6g span {
  background: var(--white);
}

.text-styles_exiting_s1z6g span,
.text-styles_exited_s1z6g span {
  background: transparent;
  color: var(--charcoal-2);
}

.text-styles_highlighted_s1z6g {
  color: var(--kale-3);
}

.text-styles_unmounted_s1z6g {
  transition: none;
}

.FormControl_container_16uij {
  position: relative;
  width: 100%;
}

.FormControl_message_16uij {
  display: block;
  margin-top: var(--margin-xs);
  margin-left: var(--margin-sm);
}

.StatusMessage_invalid_1pae0 {
  color: var(--error-red);
}

.CreditCardNumber_container_4bhdn {
  --logo-width: 40px;
  --logo-height: 25px;
}

.CreditCardNumber_logoContainer_4bhdn {
  margin: 0 var(--tfd-fieldlabel-padding);
  flex: 0 0 var(--logo-width);
  display: flex;
  align-items: center;
}

.CreditCardNumber_logoContainer_4bhdn img {
  width: var(--logo-width);
  height: var(--logo-height);
}

.CreditCardNumber_amex_4bhdn {
  content: url('../src/components/CreditCardInput/CreditCardNumber/assets/american-express.png');
}

.CreditCardNumber_diners_4bhdn {
  content: url('../src/components/CreditCardInput/CreditCardNumber/assets/diners-club.png');
}

.CreditCardNumber_discover_4bhdn {
  content: url('../src/components/CreditCardInput/CreditCardNumber/assets/discover.png');
}

.CreditCardNumber_jcb_4bhdn {
  content: url('../src/components/CreditCardInput/CreditCardNumber/assets/jcb.png');
}

.CreditCardNumber_unknown_4bhdn {
  content: url('../src/components/CreditCardInput/CreditCardNumber/assets/unknown.png');
}

.CreditCardNumber_unionpay_4bhdn {
  content: url('../src/components/CreditCardInput/CreditCardNumber/assets/unionpay.png');
}

.CreditCardNumber_visa_4bhdn {
  content: url('../src/components/CreditCardInput/CreditCardNumber/assets/visa.png');
}

.CreditCardNumber_mastercard_4bhdn {
  content: url('../src/components/CreditCardInput/CreditCardNumber/assets/mastercard.png');
}

.Fieldset_fieldset_1wjzt {
  border: 0;
  padding: 0;
  min-width: 0;
}

.Fieldset_fieldset_1wjzt + .Fieldset_fieldset_1wjzt > legend {
  padding-top: var(--tfd-spacing-md);
}

.Fieldset_fieldset_1wjzt > legend {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: var(--tfd-spacing-sm);
}

.Flex_flex_6ivn6 {
  display: flex;
}
.Flex_direction-row_6ivn6 {
  flex-direction: row;
}
.Flex_direction-column_6ivn6 {
  flex-direction: column;
}
.Flex_direction-row-reverse_6ivn6 {
  flex-direction: row-reverse;
}
.Flex_direction-column-reverse_6ivn6 {
  flex-direction: column-reverse;
}
.Flex_justify-start_6ivn6 {
  justify-content: flex-start;
}
.Flex_justify-center_6ivn6 {
  justify-content: center;
}
.Flex_justify-end_6ivn6 {
  justify-content: flex-end;
}
.Flex_justify-between_6ivn6 {
  justify-content: space-between;
}
.Flex_justify-around_6ivn6 {
  justify-content: space-around;
}
.Flex_justify-evenly_6ivn6 {
  justify-content: space-evenly;
}
.Flex_align-items-start_6ivn6 {
  align-items: flex-start;
}
.Flex_align-items-center_6ivn6 {
  align-items: center;
}
.Flex_align-items-end_6ivn6 {
  align-items: flex-end;
}
.Flex_align-items-stretch_6ivn6 {
  align-items: stretch;
}
.Flex_align-items-baseline_6ivn6 {
  align-items: baseline;
}
.Flex_align-content-start_6ivn6 {
  align-content: flex-start;
}
.Flex_align-content-end_6ivn6 {
  align-content: flex-end;
}
.Flex_align-content-center_6ivn6 {
  align-content: center;
}
.Flex_align-content-stretch_6ivn6 {
  align-content: stretch;
}
.Flex_align-content-space-between_6ivn6 {
  align-content: space-between;
}
.Flex_align-content-space-around_6ivn6 {
  align-content: space-around;
}
.Flex_wrap-nowrap_6ivn6 {
  flex-wrap: nowrap;
}
.Flex_wrap-wrap_6ivn6 {
  flex-wrap: wrap;
}
.Flex_wrap-wrap-reverse_6ivn6 {
  flex-wrap: wrap-reverse;
}
.Flex_gap-xxs_6ivn6 {
  gap: var(--tfd-spacing-xxs);
}
.Flex_gap-xs_6ivn6 {
  gap: var(--tfd-spacing-xs);
}
.Flex_gap-sm_6ivn6 {
  gap: var(--tfd-spacing-sm);
}
.Flex_gap-md_6ivn6 {
  gap: var(--tfd-spacing-md);
}
.Flex_gap-lg_6ivn6 {
  gap: var(--tfd-spacing-lg);
}
.Flex_gap-xl_6ivn6 {
  gap: var(--tfd-spacing-xl);
}
.Flex_gap-xxl_6ivn6 {
  gap: var(--tfd-spacing-xxl);
}

.Footer_root_8sqt5 {
  background-color: var(--kale-3);
}

.Footer_root_8sqt5 h4 {
  font-weight: bold;
  margin-bottom: var(--tfd-spacing-md);
}

.Footer_root_8sqt5 a {
  color: inherit;
}

.Footer_container_8sqt5 {
  max-width: var(--tfd-max-width);
  margin: 0 auto;
  padding-bottom: 0;
}

.Footer_sections_8sqt5 {
  margin: var(--tfd-spacing-lg) 0;
  gap: var(--tfd-spacing-lg);
}

@media (min-width: 1024px) {
  .Footer_sections_8sqt5 {
    margin: var(--tfd-spacing-xl) 0;
  }
}

@media (min-width: 1024px) {
  .Footer_section_8sqt5:not(:last-child) {
    margin-bottom: 0;
  }
}

ul.Footer_itemsList_8sqt5 {
  margin: 0;
  list-style: none;
  padding: 0;
}

ul.Footer_itemsList_8sqt5 > li {
  display: block;
  margin-bottom: var(--tfd-spacing-md);
  padding: 0;
}

ul.Footer_itemsList_8sqt5 > li:last-child {
  margin-bottom: 0;
}

ul.Footer_itemsList_8sqt5 > li a {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

ul.Footer_itemsList_8sqt5 > li a:not(:hover) {
  /* See https://app.shortcut.com/farmersdog/story/96940 */
  color: var(--white);
}

.Footer_tagline_8sqt5 {
  margin-bottom: var(--tfd-spacing-lg);
}

.Footer_hideTagline_8sqt5 .Footer_sections_8sqt5 {
  margin-bottom: var(--tfd-spacing-lg);
}

.Footer_legalSection_8sqt5 {
  display: block;
}

.Footer_tagline_8sqt5 {
  margin-bottom: var(--tfd-spacing-lg);
}

@media (min-width: 1024px) {
  .Footer_tagline_8sqt5 {
    margin-bottom: var(--tfd-spacing-xl);
  }
}

.Footer_legalSectionContainer_8sqt5 {
  margin-top: var(--tfd-spacing-lg);
  margin-bottom: var(--tfd-spacing-sm);
}

@media (min-width: 1024px) {
  .Footer_legalSectionContainer_8sqt5 {
    margin: var(--tfd-spacing-xl) 0;
  }
}

@media (min-width: 1024px) {
  .Footer_legalSection_8sqt5 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.Footer_legalItemsList_8sqt5 {
  margin: 0;
  list-style: none;
  padding: 0;
}

.Footer_legalItemsList_8sqt5 > li {
  display: block;
  margin-bottom: var(--tfd-spacing-md);
  padding: 0;
  text-align: center;
}

.Footer_legalItemsList_8sqt5 > .Footer_legalItemLink_8sqt5 {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

@media (min-width: 1024px) {
  .Footer_legalItemsList_8sqt5 {
    display: flex;
    justify-content: space-between;
    gap: var(--tfd-spacing-md);
  }

  .Footer_legalItemsList_8sqt5 > li {
    text-align: left;
    margin-bottom: 0;
  }
}

.Wave_container_v7ido {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Wave_background_v7ido {
  padding: 0;
}

.Wave_top-wave_v7ido {
  margin-bottom: -1px;
  width: 100%;
  display: block;
  transform: translateY(1px);
}

.Wave_bottom-wave_v7ido {
  margin-top: -1px;
  width: 100%;
  display: block;
  transform: rotate(180deg) translateY(1px);
}

.Wave_margin-xxs_v7ido {
  padding: var(--margin-xxs) 0;
}

.Wave_margin-xs_v7ido {
  padding: var(--margin-xs) 0;
}

.Wave_margin-sm_v7ido {
  padding: var(--margin-sm) 0;
}

.Wave_margin-md_v7ido {
  padding: var(--margin-md) 0;
}

.Wave_margin-lg_v7ido {
  padding: var(--margin-lg) 0;
}

.Wave_margin-xl_v7ido {
  padding: var(--margin-xl) 0;
}

.Wave_margin-xxl_v7ido {
  padding: var(--margin-xxl) 0;
}

.Wave_margin-3xl_v7ido {
  padding: var(--margin-3xl) 0;
}

.Wave_margin-4xl_v7ido {
  padding: var(--margin-4xl) 0;
}

.Gallery_container_jlq3t {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.Gallery_horizontalSnap_jlq3t {
  box-sizing: border-box;
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap;
}

.Gallery_message_jlq3t,
.Gallery_dots_jlq3t {
  text-align: center;
  margin: 0 auto;
}

.Gallery_arrow-button_jlq3t {
  position: absolute;
  background: transparent;
  border: none;
  height: 72px;
  width: 72px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  z-index: 1;
}

.Gallery_arrow-button_jlq3t:disabled {
  opacity: 0.25;
}

.Gallery_forward-button_jlq3t {
  right: 0;
}

.Gallery_is-not-wide_jlq3t .Gallery_message_jlq3t {
  margin-top: var(--margin-sm);
}

.Gallery_is-not-wide_jlq3t .Gallery_dots_jlq3t {
  margin-top: var(--margin-md);
}

.Gallery_is-wide_jlq3t .Gallery_message_jlq3t {
  margin-top: var(--margin-md);
}

.Gallery_is-wide_jlq3t .Gallery_dots_jlq3t {
  margin-top: var(--margin-md);
}

.IndexButton_button_3cbaq {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  transition: background 100ms ease;
}

.IndexButton_button_3cbaq + .IndexButton_button_3cbaq {
  margin-left: var(--margin-xs);
}

.IndexButton_button_3cbaq.IndexButton_dark_3cbaq {
  background: var(--oyster-0);
  border: 1px solid var(--charcoal-0);
}

.IndexButton_button_3cbaq.IndexButton_dark_3cbaq.IndexButton_active_3cbaq {
  background: var(--charcoal-0);
  border: 1px solid var(--charcoal-0);
}

.IndexButton_button_3cbaq.IndexButton_darker_3cbaq {
  background: transparent;
  border: 1px solid var(--kale-3);
}

.IndexButton_button_3cbaq.IndexButton_darker_3cbaq.IndexButton_active_3cbaq {
  background: var(--kale-3);
}

.IndexButton_button_3cbaq.IndexButton_light_3cbaq {
  background: var(--oyster-0);
  border: 1px solid var(--oyster-0);
}

.IndexButton_button_3cbaq.IndexButton_light_3cbaq.IndexButton_active_3cbaq {
  background: var(--kale-1);
  border: 1px solid var(--kale-1);
}

.IndexButton_button_3cbaq.IndexButton_green_3cbaq {
  width: 10px;
  height: 10px;
  background: var(--white);
  border: 1px solid var(--white);
}

.IndexButton_button_3cbaq.IndexButton_green_3cbaq.IndexButton_active_3cbaq {
  background: var(--kale-3);
  border: 1px solid var(--kale-3);
}

.IndexButton_button_3cbaq.IndexButton_green_3cbaq + .IndexButton_button_3cbaq.IndexButton_green_3cbaq {
  margin-left: 5px;
}

.IndexButton_button_3cbaq.IndexButton_kale_3cbaq {
  background: var(--kale-1);
  border: 1px solid var(--kale-1);
}

.IndexButton_button_3cbaq.IndexButton_kale_3cbaq.IndexButton_active_3cbaq {
  background: var(--kale-3);
  border: 1px solid var(--kale-3);
}

.Slide_slide_1bp4u {
  display: block;
  margin: 0;
  flex: 0 0 auto;
  position: relative;
  box-sizing: border-box;
}

/** scroll snap breaks animation on iOS */

@supports (-webkit-touch-callout: none) {
  .Slide_slide_1bp4u {
    scroll-snap-align: none;
  }
}

.Slide_slide_1bp4u * {
  backface-visibility: hidden;
  transition: opacity var(--animation-standard);
}

.Slide_button_1bp4u {
  width: 100%;
}

.Slide_animation-reference_1bp4u {
  pointer-events: none;
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}

.Slide_animation-debug_1bp4u {
  background-color: var(--kale-1);
  z-index: 1;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Slide_is-not-wide_1bp4u.Slide_slide_1bp4u {
  width: 84%;
}

.Slide_is-not-wide_1bp4u.Slide_slide_1bp4u:first-child {
  margin-left: 8%;
}

.Slide_is-not-wide_1bp4u.Slide_slide_1bp4u:last-child {
  margin-right: 8%;
}

.Slide_is-not-wide_1bp4u .Slide_animation-reference_1bp4u {
  width: 120%;
}

.Slide_is-wide_1bp4u.Slide_slide_1bp4u {
  width: 50%;
}

.Slide_is-wide_1bp4u.Slide_slide_1bp4u:first-child {
  margin-left: 25%;
}

.Slide_is-wide_1bp4u.Slide_slide_1bp4u:last-child {
  margin-right: 25%;
}

.Slide_is-wide_1bp4u .Slide_animation-reference_1bp4u {
  width: 200%;
}

.Slide_active_1bp4u {
  opacity: 1;
}

.Slide_inactive_1bp4u {
  opacity: 0.25;
}

.Slide_inactive_1bp4u:focus {
  opacity: 1;
}

.GridContainer_container_uq5et {
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

ul.GridContainer_container_uq5et {
  list-style-type: none;
  margin-top: 0;
  margin-top: initial;
  margin-bottom: 0;
  margin-bottom: initial;
  padding: 0;
}

.GridContainer_container_uq5et:not(.GridContainer_skip-xs_uq5et) {
  width: calc(100% - var(--tfd-spacing-sm) * 2);
  grid-template-columns: repeat(4, 1fr);
  -moz-column-gap: var(--tfd-spacing-sm);
       column-gap: var(--tfd-spacing-sm);
}

@media (min-width: 375px) {
  .GridContainer_container_uq5et:not(.GridContainer_skip-sm_uq5et) {
    width: calc(100% - var(--tfd-spacing-md) * 2);
    grid-template-columns: repeat(4, 1fr);
    -moz-column-gap: var(--tfd-spacing-sm);
         column-gap: var(--tfd-spacing-sm);
  }
}

@media (min-width: 710px) {
  .GridContainer_container_uq5et:not(.GridContainer_skip-md_uq5et) {
    width: calc(100% - var(--tfd-spacing-xl) * 2);
    grid-template-columns: repeat(8, 1fr);
    -moz-column-gap: var(--tfd-spacing-md);
         column-gap: var(--tfd-spacing-md);
  }
}

@media (min-width: 1024px) {
  .GridContainer_container_uq5et:not(.GridContainer_skip-lg_uq5et) {
    width: calc(100% - var(--tfd-spacing-xl) * 2);
    grid-template-columns: repeat(12, 1fr);
    -moz-column-gap: var(--tfd-spacing-md);
         column-gap: var(--tfd-spacing-md);
  }
}

@media (min-width: 1400px) {
  .GridContainer_container_uq5et:not(.GridContainer_skip-xl_uq5et) {
    width: 1200px;
    grid-template-columns: repeat(12, 1fr);
    -moz-column-gap: var(--tfd-spacing-md);
         column-gap: var(--tfd-spacing-md);
  }
}

.GridContainer_container_uq5et.GridContainer_disabled_uq5et {
  width: auto;
  width: initial;
  margin: 0;
  margin: initial;
}

.Hero_section_zdrnj {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--margin-lg) 0;
  height: 558px;
  position: relative;
  overflow: hidden;
}

@media (min-height: 737px) {
  .Hero_section_zdrnj {
    height: 572px;
  }
}

.Hero_background_zdrnj {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Hero_content_zdrnj {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-left: 10px;
  margin-right: 10px;
}

.Hero_title_zdrnj {
  margin: 0;
  text-align: center;
  font-size: 40px;
  line-height: 1.33;
}

.Hero_subtitle_zdrnj {
  margin-top: var(--margin-sm);
  margin-bottom: 0;
  text-align: center;
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 0.5px;
}

a.Hero_cta_zdrnj {
  margin-top: var(--margin-md);
}

@media (min-width: 375px) {
  .Hero_content_zdrnj {
    margin-left: 30px;
    margin-right: 30px;
    max-width: none;
  }
}

@media (min-width: 710px) {
  .Hero_content_zdrnj {
    margin-left: auto;
    margin-right: auto;
    max-width: 630px;
  }
}

@media (min-width: 1024px) {
  .Hero_content_zdrnj {
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
  }

  .Hero_title_zdrnj {
    font-size: 52px;
    line-height: 1.33;
    letter-spacing: 0.5px;
  }

  .Hero_subtitle_zdrnj {
    font-size: 21px;
    line-height: 1.52;
    letter-spacing: normal;
  }
}

.PageWidth_container_1xfja {
  --page-margin-xs: 10px;
  --page-margin-sm: 30px;
  --page-margin-md: auto;
  --page-margin-lg: 40px;
  --page-margin-xl: auto;
}

.PageWidth_container_1xfja:not(.PageWidth_skip-xs_1xfja) {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - var(--page-margin-xs) * 2);
}

@media (min-width: 375px) {
  .PageWidth_container_1xfja:not(.PageWidth_skip-sm_1xfja) {
    width: calc(100% - var(--page-margin-sm) * 2);
  }
}

@media (min-width: 710px) {
  .PageWidth_container_1xfja:not(.PageWidth_skip-md_1xfja) {
    width: 630px;
  }
}

@media (min-width: 1024px) {
  .PageWidth_container_1xfja:not(.PageWidth_skip-lg_1xfja) {
    width: calc(100% - var(--page-margin-lg) * 2);
  }
}

@media (min-width: 1400px) {
  .PageWidth_container_1xfja:not(.PageWidth_skip-xl_1xfja) {
    width: 1185px;
  }
}

.PageWidth_container_1xfja.PageWidth_disabled_1xfja {
  width: auto;
  width: initial;
  margin: 0;
  margin: initial;
}

.IconButton_iconButton_7lc6t {
  border-radius: 50%;
  padding: var(--tfd-spacing-xs);
  display: inline-flex;
  vertical-align: middle;
}

.Input_root_m22hx > label {
  display: flex;
  align-items: center;
}

.Input_input_m22hx {
  --input-padding: var(--margin-sm);
  overflow: visible; /* Show the overflow in IE */
  box-sizing: border-box;
  margin: 0; /* Remove the margin in Firefox and Safari */
  padding: var(--input-padding);
  width: 100%;
  height: 100%;
  display: block;
  background: transparent;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  /* border-radius is needed or else autofill box shadow cuts off the border */
  border-radius: var(--tfd-border-radius-sm);
  color: var(--kale-3);
  font-family: var(--tfd-font-family);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.Input_input_m22hx::-moz-placeholder {
  color: var(--charcoal-2);
}

.Input_input_m22hx::placeholder {
  color: var(--charcoal-2);
}

.Input_input_m22hx:focus {
  outline: none;
}

.Input_input_m22hx:-webkit-autofill {
  box-shadow: inset 0 0 0 9999px var(--white);
}

.Input_input_m22hx[type='number'] {
  --stepper-width: 24px;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
          appearance: textfield;
  margin: 0;
  padding-right: calc(var(--input-padding) + var(--stepper-width));
}

/** Hide the native spin buttons, they are replaced by a component */

.Input_input_m22hx[type='number']::-webkit-inner-spin-button,
.Input_input_m22hx[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.RevealButton_button_1e51o {
  height: 24px;
  box-sizing: content-box;
  padding: 10px;
}

.SpinButtons_root_pyi80 {
  display: inline-flex;
  flex-direction: column;
  margin-right: var(--tfd-spacing-xs);
}
.SpinButtons_root_pyi80 > button {
  flex: 1 0 0;
  height: 22px;
  padding: 3px 6px;
  display: flex;
}
.SpinButtons_root_pyi80 > button path {
  fill: var(--kale-3);
}
.SpinButtons_root_pyi80 > button:first-child {
  align-items: flex-end;
}
.SpinButtons_root_pyi80.SpinButtons_disabled_pyi80 > button path {
  fill: var(--charcoal-0);
}

.Layout_layout_kk0gx {
  --tfd-layout-v-spacing: 0px; /* set the distance from top/bottom */
  --tfd-layout-h-spacing: 0px; /* set the distance from left/right */
}

.Layout_layout_kk0gx[data-theme='chickpea'],
.Layout_layout_kk0gx[data-theme='oyster'],
.Layout_layout_kk0gx[data-theme='kale'] {
  --tfd-layout-v-spacing: var(--tfd-spacing-lg);
  --tfd-layout-h-spacing: var(--tfd-spacing-md);
}

@media (min-width: 1024px) {
  /* On large viewports, increment the horizontal spacing. */
  .Layout_layout_kk0gx[data-theme='chickpea'],
  .Layout_layout_kk0gx[data-theme='oyster'],
  .Layout_layout_kk0gx[data-theme='kale'] {
    --tfd-layout-v-spacing: var(--tfd-spacing-xl);
    --tfd-layout-h-spacing: var(--tfd-spacing-xl);
  }
}

.Layout_layout_kk0gx {
  background-color: var(--white);
}

.Layout_layout_kk0gx[data-theme='chickpea'] {
  background-color: var(--chickpea-1);
}

.Layout_layout_kk0gx[data-theme='kale'] {
  background-color: var(--kale-0);
}

.Layout_layout_kk0gx[data-theme='oyster'] {
  background-color: var(--oyster-1);
}

.Layout_header_kk0gx {
  position: sticky;
  top: 0;
  z-index: var(--tfd-appnav-z-index);
}

.Layout_content_kk0gx {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
}

.Layout_layout_kk0gx.Layout_withAppNav_kk0gx .Layout_content_kk0gx {
  /* Consider the height of the nav when calculating the height of the content. */
  min-height: calc(
    100vh - var(--tfd-appnav-height) - var(--tfd-banner-min-height)
  );
}

@media (min-width: 710px) {
  .Layout_content_kk0gx {
    padding: var(--tfd-layout-v-spacing) var(--tfd-layout-h-spacing);
    display: flex;
  }
  .Layout_layout_kk0gx.Layout_withSubNav_kk0gx .Layout_content_kk0gx {
    max-width: var(--tfd-max-width);
  }
}

.Layout_content_kk0gx.Layout_shiftUp_kk0gx {
  /* Shift the main content up to account for the sticky header. */
  margin-top: calc(var(--tfd-appnav-height) * -1);
}

.Layout_main_kk0gx {
  padding: var(--tfd-layout-v-spacing) var(--tfd-layout-h-spacing);
  flex: 1 0 0;
}

.Layout_layout_kk0gx.Layout_withMenu_kk0gx .Layout_main_kk0gx {
  margin-top: var(--tfd-appnav-height);
}

.Layout_main_kk0gx > div > main > *:first-child {
  /* Fix spacings from components with top margin. */
  margin-top: 0;
}

@media (min-width: 710px) {
  .Layout_main_kk0gx {
    padding: 0;
  }
}

.Layout_aside_kk0gx {
  display: none;
  position: sticky;
  top: 0px;
  flex: 0 1 33%;
  z-index: 1;
  align-self: flex-start;
}

[data-theme='kale'] .Layout_aside_kk0gx,
[data-theme='oyster'] .Layout_aside_kk0gx,
[data-theme='chickpea'] .Layout_aside_kk0gx {
  top: calc(var(--tfd-appnav-height) + var(--tfd-layout-v-spacing));
}

@media (min-width: 710px) {
  .Layout_aside_kk0gx {
    display: block;
  }
}

/* Fixes for the NavBar paddings */

.Layout_aside_kk0gx > nav {
  display: block;
  position: relative;
  overflow-y: auto;
}

.Layout_aside_kk0gx > nav > ul {
  margin-top: 0;
}

.Layout_aside_kk0gx > nav > ul > li > span > a {
  margin-right: 0;
  padding: 0 var(--tfd-spacing-sm);
}

.Layout_aside_kk0gx > nav > ul > li:first-child > span > a {
  padding-left: var(--tfd-spacing-md);
}

.Layout_aside_kk0gx > nav > ul > li:last-child > span > a {
  padding-right: var(--tfd-spacing-md);
}

.VerticalNavigation_root_14v3x {
  position: fixed;
  overflow-y: auto;
}

.VerticalNavigation_root_14v3x ul {
  margin: 0;
  padding: 0;
  margin-top: var(--margin-xl);
}

.VerticalNavigation_root_14v3x ul li {
  margin: 0;
  padding: 0;
}

.VerticalNavigation_root_14v3x ul li > * {
  display: block;
  padding-left: var(--margin-md);
  margin-bottom: var(--margin-md);
}

.VerticalNavigation_activeLink_14v3x {
  box-shadow: inset 2px 0px var(--carrot-3);
}

.Mask_circle_107nu,
.Mask_squircle_107nu {
  display: inline-block;
}

.Mask_circle_107nu,
.Mask_circle_107nu > *,
.Mask_circle_107nu > picture > img {
  border-radius: 50%;
}

@supports (clip-path: circle()) or (-webkit-clip-path: circle()) {
  .Mask_circle_107nu,
  .Mask_circle_107nu > * {
    border-radius: 0;
    clip-path: circle(50% at 50% 50%);
  }
}

.Mask_squircle_107nu,
.Mask_squircle_107nu > *,
.Mask_squircle_107nu > picture > img {
  border-radius: 24px;
}

@supports (clip-path: inset()) or (-webkit-clip-path: inset()) {
  .Mask_squircle_107nu,
  .Mask_squircle_107nu > *,
  .Mask_squircle_107nu > picture > img {
    border-radius: 0;
    clip-path: inset(0 0 0 0 round 24px);
  }
}

.Mask_circle_107nu > *,
.Mask_circle_107nu > picture > img,
.Mask_squircle_107nu > *,
.Mask_squircle_107nu > picture > img {
  display: block;
}

.Mask_xs_107nu > *,
.Mask_xs_107nu > picture > img {
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
}

.Mask_sm_107nu > *,
.Mask_sm_107nu > picture > img {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}

.Mask_md_107nu > *,
.Mask_md_107nu > picture > img {
  min-width: 72px;
  min-height: 72px;
  max-width: 72px;
  max-height: 72px;
}

.Mask_lg_107nu > *,
.Mask_lg_107nu > picture > img {
  min-width: 150px;
  min-height: 150px;
  max-width: 150px;
  max-height: 150px;
}

.Mask_xl_107nu > *,
.Mask_xl_107nu > picture > img {
  min-width: 300px;
  min-height: 300px;
  max-width: 300px;
  max-height: 300px;
}

.Meter_container_14jlv {
  position: relative;
}

.Meter_children_14jlv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Meter_labels_14jlv {
  display: flex;
  justify-content: space-between;
}

.MeterSvg_container_1nda5 {
  width: 100%;
}

.MeterSvg_fill_1nda5 {
  transition: var(--animation-standard);
}

.MeterSvg_marker_1nda5 {
  transition: var(--animation-standard);
}

.Modal_modal_cvinn {
  --modal-padding: var(--tfd-spacing-lg);
  --modal-max-width: 100%;
}

@media (min-width: 710px) {
  .Modal_modal_cvinn {
    --modal-max-width: 630px;
  }
}

@media (min-width: 1024px) {
  .Modal_modal_cvinn {
    --modal-max-width: 716px;
  }
}

.Modal_modal_cvinn {
  max-width: var(--modal-max-width);
}

.Modal_modal_cvinn.Modal_variant-default_cvinn {
  padding: var(--modal-padding);
}

.Modal_modal_cvinn.Modal_variant-wave_cvinn {
  padding: 0;
}

.Modal_modal_cvinn h1 {
  margin-top: var(--tfd-spacing-md);
}

.Modal_modal_cvinn h1 + h2 {
  margin-top: var(--tfd-spacing-md);
}

.Modal_modal_cvinn.Modal_variant-default_cvinn h1 + h2 {
  margin-bottom: var(--tfd-spacing-md);
}

.Modal_closeButton_cvinn {
  height: 36px;
  width: 36px;
}

.Modal_nav_cvinn {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.Modal_wave_cvinn > div {
  padding: var(--modal-padding);
}

.Modal_modal_cvinn.Modal_variant-wave_cvinn .Modal_content_cvinn {
  padding: var(--modal-padding);
}

.Nowrap_nowrap_gdgxz {
  white-space: nowrap;
}

.PaddedCard_container_1clfg {
  display: block;
  padding: var(--margin-lg);
  margin-bottom: var(--margin-lg);
}

@media (min-width: 1024px) {
  .PaddedCard_container_1clfg {
    padding: var(--margin-xl);
  }
}

.Page_root_1tsy8 > main,
.Page_root_1tsy8 > header {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.Page_root_1tsy8 > nav {
  padding: var(--tfd-spacing-md);
  max-width: var(--tfd-constraint-width-md);
}

.Page_layout-base_1tsy8,
.Page_layout-centered_1tsy8 {
  padding: var(--tfd-spacing-md);
  background-color: var(--tfd-white);
}

.Page_layout-base_1tsy8 > nav,
.Page_layout-centered_1tsy8 > nav {
  padding: 0;
  padding-bottom: var(--tfd-spacing-md);
}

/* Base Layout */

/* Header and main have the same max width */

.Page_layout-full-width_1tsy8 > main {
  max-width: 100%;
}

.Page_layout-base_1tsy8 > main,
.Page_layout-base_1tsy8 > header {
  max-width: var(--tfd-constraint-width-md);
}

/* Centered layout */

/* - Header is centered only from medium screens or when there's an image  */

/* - Main is restricted to 5/12 the main width */

.Page_layout-centered_1tsy8.Page_with-image_1tsy8 > header {
  text-align: center;
}

@media (min-width: 710px) {
  .Page_layout-centered_1tsy8 > header {
    text-align: center;
    max-width: var(--tfd-constraint-width-md);
  }
  .Page_layout-centered_1tsy8 > main {
    max-width: var(--tfd-constraint-width-sm);
  }
}

.BaseNav_root_y184h {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.BaseNav_root_y184h > button:first-child {
  /* Move a bit on the left to align the icon with the text */
  transform: translateX(-10px);
}

.BaseNav_root_y184h > button:last-child {
  /* Move a bit on the right to align the icon with the text */
  transform: translateX(8px);
}

.Progress_default_vvq58 {
  --dot-size: 9px;
  --bar-height: 2px;
  --background-bar-color: var(--oyster-0);
  --bar-content-spacing: 0 var(--tfd-spacing-sm);
  --dot-active: var(--white);
  --bar-border-radius: 0;
}

.Progress_bold_vvq58 {
  --dot-size: 12px;
  --bar-height: 4px;
  --background-bar-color: var(--charcoal-1);
  --bar-content-spacing: 0;
  --dot-active: var(--kale-3);
  --bar-border-radius: 12px;
}

.Progress_root_vvq58 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: var(--dot-size);
}

/* As "background" we display a line that fits
   nicely with the progress bar when using fr < 3 */

.Progress_background_bar_vvq58,
.Progress_bar_vvq58 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: var(--bar-height);
  background: var(--background-bar-color);
  border-radius: var(--bar-border-radius);
}

/* The actual progress bar container */

.Progress_content_vvq58 {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: var(--bar-content-spacing);
}

.Progress_bar_vvq58 {
  transition: width var(--animation-standard);
}

.Progress_bar_active_vvq58 {
  width: 0%;
  background: var(--kale-3);
}

.Progress_dots_vvq58 {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.Progress_dot_vvq58 {
  min-width: var(--dot-size);
  width: var(--dot-size);
  height: var(--dot-size);
  border: var(--tfd-spacing-xxs) solid var(--background-bar-color);
  border-radius: 100%;
  box-sizing: border-box;
  background: var(--white);
  transition: border var(--animation-standard);
}

.Progress_dot_active_vvq58 {
  border-color: var(--kale-3);
  background: var(--dot-active);
}

.RadioButton_container_oekua {
  --tfd-radio-input-size: 24px;
  display: flex;
  justify-content: column;
  align-items: center;
  flex: 1;
  cursor: pointer;
}

.RadioButton_container_oekua + .RadioButton_container_oekua {
  margin-top: var(--tfd-spacing-md);
}

.RadioButton_radio_oekua {
  position: relative;
  display: inline-flex;
  flex: 0 0 auto;
  vertical-align: middle;
  height: var(--tfd-radio-input-size);
  width: var(--tfd-radio-input-size);
  background-color: var(--white);
  border: 1px solid var(--charcoal-1);
  border-radius: 50%;
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: border-color, background-color;
}

.RadioButton_radio_oekua.RadioButton_focus_oekua {
  border-color: var(--kale-3);
  box-shadow: 0 0 0 2px var(--kale-3);
}

.RadioButton_radio_oekua.RadioButton_checked_oekua.RadioButton_focus_oekua {
  box-shadow: inset 0 0 0 2px var(--white), 0 0 0 2px var(--kale-3);
}

.RadioButton_radio_oekua:after {
  content: '';
  opacity: 0;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  border: 1px solid var(--white);
  border-radius: 50%;
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: opacity;
}

.RadioButton_container_oekua:hover .RadioButton_radio_oekua {
  border-color: var(--kale-3);
}

.RadioButton_container_oekua:hover .RadioButton_radio_oekua.RadioButton_disabled_oekua {
  border-color: var(--charcoal-1);
}

input:checked ~ .RadioButton_radio_oekua,
.RadioButton_radio_oekua.RadioButton_checked_oekua {
  background-color: var(--kale-3);
  border-color: var(--kale-3);
}

input:checked ~ .RadioButton_radio_oekua:after,
.RadioButton_checked_oekua:after {
  opacity: 1;
}

input:checked ~ .RadioButton_radio_oekua.RadioButton_disabled_oekua {
  background-color: var(--charcoal-1);
  border-color: var(--charcoal-1);
}

.RadioButton_label_oekua {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2em;
  color: var(--charcoal-3);
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: color;
  padding-left: var(--tfd-spacing-xs);
}

.RadioButton_label_oekua.RadioButton_disabled_oekua {
  color: var(--charcoal-2);
}

.RadioButton_label_oekua.RadioButton_checked_oekua.RadioButton_disabled_oekua {
  color: var(--charcoal-3);
}

.RadioButton_label_oekua.RadioButton_focus_oekua {
  border: none;
}

/* TODO: delete when useElementState is removed https://app.shortcut.com/farmersdog/story/90425/spike-remove-useelementstate-hook */

.RadioButton_label_oekua.RadioButton_normal_oekua,
.RadioButton_label_oekua.RadioButton_highlighted_oekua,
.RadioButton_label_oekua.RadioButton_invalid_oekua {
  color: var(--charcoal-3);
}

.RichSelector_root_15vag {
  position: relative;
}

.RichSelector_options_15vag {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.RichSelector_options_15vag > label {
  /* TOFIX: this var never worked. Should it be `var(--tfd-constraint-width-sm)` instead? */
  max-width: var(--viewport-sm);
  flex: 1;
  margin-top: var(--tfd-spacing-sm);
}

.RichSelector_activeImage_15vag {
  text-align: center;
}

.RichSelector_activeImage_15vag picture img {
  margin-top: var(--tfd-spacing-lg);
  height: 144px;
}

@media (min-width: 1024px) {
  .RichSelector_activeImage_15vag {
    display: none;
  }
}

.RichSelector_description_15vag {
  padding: 0 var(--margin-sm);
  text-align: center;
}

.RadioButton_container_17mny {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: var(--tfd-border-radius-sm);
}

.RadioButton_input_17mny.RadioButton_focus-visible_17mny + .RadioButton_container_17mny {
  outline: none;
  /* Replace with var(--tfd-outline-box-shadow)
   when https://github.com/farmersdog/beethoven/pull/5355/files is merged */
  box-shadow: 0 0 0 2px var(--charcoal-0);
}

.RadioButton_input_17mny:focus-visible + .RadioButton_container_17mny {
  outline: none;
  /* Replace with var(--tfd-outline-box-shadow)
   when https://github.com/farmersdog/beethoven/pull/5355/files is merged */
  box-shadow: 0 0 0 2px var(--charcoal-0);
}

.RadioButton_label_17mny {
  color: inherit;
  line-height: 1.3em;
  text-align: center;
}

.RadioButton_description_17mny {
  text-align: center;
  margin-top: var(--tfd-spacing-xs);
}

.RadioButton_description_17mny > * {
  opacity: 0;
  color: var(--carrot-2);
}

.RadioButton_container_17mny:hover .RadioButton_description_17mny > *,
.RadioButton_input_17mny:checked + .RadioButton_container_17mny .RadioButton_description_17mny > *,
.RadioButton_input_17mny:focus + .RadioButton_container_17mny .RadioButton_description_17mny > * {
  opacity: 1;
}

.RadioButton_picture_17mny {
  display: block;
  height: 144px;
  width: auto;
  margin-top: var(--tfd-spacing-lg);
}

.RadioButton_picture_17mny.RadioButton_large_17mny {
  display: none;
}

@media (min-width: 1024px) {
  .RadioButton_picture_17mny {
    height: 144px;
  }
  .RadioButton_picture_17mny.RadioButton_small_17mny {
    display: none;
  }

  .RadioButton_picture_17mny.RadioButton_large_17mny {
    display: block;
  }
}

.CircleMark_radio_1ioah {
  position: relative;
  display: block;
  height: 24px;
  width: 24px;
  margin: 0 auto;
  background-color: var(--white);
  border: 2px solid var(--charcoal-1);
  border-radius: 50%;
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: border-color, background-color;
}

.CircleMark_radio_1ioah:after {
  content: '';
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 3px solid var(--white);
  border-radius: 50%;
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: opacity;
}

.CircleMark_checked_1ioah.CircleMark_radio_1ioah {
  background-color: var(--carrot-2);
  border-color: var(--charcoal-1);
}

.CircleMark_checked_1ioah.CircleMark_radio_1ioah:after {
  opacity: 1;
}

.CircleMark_line_1ioah {
  display: block;
  width: 100%;
  position: relative;
  margin: var(--margin-sm) 0;
}

.CircleMark_line_1ioah:before {
  z-index: 0;
  position: absolute;
  top: 50%;

  display: block;
  width: 100%;
  height: 2px;
  background: var(--charcoal-1);
  content: '';
  transform: translateY(-50%);
}

.CircleMark_lineFirst_1ioah:before {
  width: 50%;
  left: 50%;
  transform: translate(1%, -50%);
}

.CircleMark_lineLast_1ioah:before {
  width: 50%;
  left: 50%;
  transform: translate(-101%, -50%);
}

.SearchableSelect_label_1g8k4 {
  position: relative;
}

.SearchableSelect_label_1g8k4.SearchableSelect_has-value_1g8k4 {
  height: auto;
}

.SearchableSelect_input_1g8k4 {
  box-sizing: border-box;
  height: var(--tfd-styledselect-height);
  display: block;
  margin: 0;
  padding: var(--tfd-fieldlabel-padding);
  border: none;
  border-radius: var(--tfd-fieldlabel-radius);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  width: calc(100% - 48px);
}

.SearchableSelect_input_1g8k4:focus {
  outline: none;
}

.SearchableSelect_input_1g8k4.SearchableSelect_isMultiMaxed_1g8k4 {
  display: none;
}

.SearchableSelect_container_1g8k4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: var(--tfd-fieldlabel-radius);
  align-items: center;
  overflow: hidden;
  position: relative;
}

.SearchableSelect_container_1g8k4.SearchableSelect_isMultiMaxed_1g8k4 {
  padding-bottom: var(--tfd-spacing-sm);
}

.SearchableSelect_button_1g8k4 {
  height: var(--tfd-styledselect-height);
  margin: 0;
  padding: 0;
  display: block;
  background: transparent;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: var(--tfd-fieldlabel-radius);
  box-sizing: border-box;
  position: absolute;
  right: 4px;
  top: 0px;
  line-height: 0;
}

.SearchableSelect_button_1g8k4:focus {
  outline: none;
}

.SearchableSelect_button_1g8k4.SearchableSelect_isMultiMaxed_1g8k4 {
  display: none;
}

.SearchableSelect_buttonIcon_1g8k4 {
  display: block;
}

.SearchableSelect_buttonIcon_1g8k4.SearchableSelect_isOpen_1g8k4 {
  transform: rotate(180deg);
}

.SearchableSelect_chevron_1g8k4 {
  transform: rotate(90deg);
}

.SearchableSelect_chevron_1g8k4.SearchableSelect_buttonIcon_1g8k4.SearchableSelect_isOpen_1g8k4 {
  transform: rotate(-90deg);
}

.StyledOption_option_5m190 {
  cursor: pointer;
  list-style-type: none;
  padding: var(--tfd-spacing-sm);
}

.StyledOption_option_5m190 + .StyledOption_option_5m190 {
  border-top: 2px solid var(--oyster-0);
}

.StyledOption_selected_5m190 {
  background-color: var(--oyster-0);
}

.StyledOption_option_5m190.StyledOption_selected_5m190.StyledOption_hover_5m190 + .StyledOption_option_5m190 {
  border-top: 2px solid var(--kale-0);
}

.StyledOption_hover_5m190 {
  background-color: var(--kale-0);
}

.StyledOption_option_5m190.StyledOption_hover_5m190 + .StyledOption_option_5m190 {
  border-top: 2px solid var(--kale-0);
}

.StyledOption_option_5m190 + .StyledOption_option_5m190.StyledOption_hover_5m190 {
  border-top: 2px solid var(--kale-0);
}

.StyledOption_invalid_5m190 {
  color: var(--error-red);
}

.StyledOptionList_list_9fcot {
  display: none;
  position: absolute;
  z-index: 1;
  background: var(--white);
  left: -1px;
  top: 4px;
  border-radius: var(--tfd-fieldlabel-radius);
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  list-style-type: none;
  border: 1px solid var(--kale-3);
  box-sizing: content-box;
}

.StyledOptionList_list_9fcot:focus {
  outline: none;
}

.StyledOptionList_list_9fcot.StyledOptionList_isOpen_9fcot {
  display: block;
}

.StyledOptionList_list_9fcot::-webkit-scrollbar {
  width: 0px;
  -webkit-appearance: none;
          appearance: none;
}

.StyledOptionList_list_9fcot::-webkit-scrollbar-thumb {
  -webkit-appearance: none;
          appearance: none;
}

.MultiButton_button_1ocdi {
  font-family: var(--tfd-font-family);
  padding: 0 var(--tfd-spacing-xs);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--kale-3);
  border-radius: var(--tfd-fieldlabel-radius);
  margin-right: var(--tfd-fieldlabel-padding);
  margin-top: var(--tfd-fieldlabel-padding);
  color: var(--white);
}

.MultiButton_icon_1ocdi {
  margin-left: var(--tfd-spacing-xs);
  pointer-events: none;
}

.MultiButtonList_container_2onqk {
  margin-left: var(--tfd-fieldlabel-padding);
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.Select_select_uyd0x {
  /** Remove the inheritance of text transform in Firefox. */
  text-transform: none;
  /** Remove the margin in Firefox and Safari */
  margin: 0;
  padding: 12px;
  padding-right: 48px;
  width: 100%;
  height: 100%;
  display: block;
  background: transparent;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 4px;
  color: var(--kale-3);
  font-size: 16px;
}

.Select_select_uyd0x:focus {
  outline: none;
}

.Select_select_uyd0x:-webkit-autofill {
  box-shadow: inset 0 0 0 9999px var(--white);
}

/** Hide IE 11 select arrow */

.Select_select_uyd0x::-ms-expand {
  display: none;
}

.Select_select_uyd0x.Select_regular_uyd0x {
  font-family: var(--tfd-font-family);
  letter-spacing: 0.5px;
}

.Select_select_uyd0x.Select_bold_uyd0x {
  font-family: var(--tfd-font-family-text);
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
}

.Select_iconRoot_uyd0x {
  position: absolute;
  pointer-events: none;
  right: 4px;
  top: 6px;
  line-height: 0;
}

.Select_caret_uyd0x.Select_left_uyd0x,
.Select_caret_uyd0x.Select_right_uyd0x {
  position: absolute;
  width: 4px;
  height: 100%;
  background: var(--charcoal-0);
}

.Select_caret_uyd0x.Select_right_uyd0x {
  right: 0;
  transform: rotate(-135deg);
}

.Select_caret_uyd0x.Select_left_uyd0x {
  left: 0;
  transform: rotate(135deg);
}

.Select_chevron_uyd0x {
  transform: rotate(90deg);
}

.Slider_slides-container_19sdi {
  overflow: hidden;
}

.Slider_slides_19sdi {
  display: flex;
}

.Slider_slide_19sdi {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 200px;
  transition: visibility var(--tfd-animation-standard);
}

.Slider_slides-container_19sdi.Slider_animate_19sdi .Slider_slides_19sdi {
  transition: transform var(--tfd-animation-standard);
}

.Slider_slides-container_19sdi.Slider_animate_19sdi .Slider_slide_19sdi {
  transition: visibility var(--tfd-animation-standard);
}

.Slider_visible_19sdi {
  visibility: visible;
}

.Slider_hidden_19sdi {
  visibility: hidden;
}

.Slideshow_container_1gehw {
  width: 100%;
  position: relative;
}

.Slideshow_horizontalSnap_1gehw {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap;
  overflow-y: hidden;
}

.Slideshow_dots_1gehw {
  display: flex;
  justify-content: center;
}

.Slideshow_dots_1gehw.Slideshow_outside_1gehw {
  margin-top: var(--margin-md);
}

.Slideshow_dots_1gehw.Slideshow_inside_1gehw {
  position: absolute;
  bottom: var(--margin-md);
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0;
}

.Slideshow_previous_1gehw {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(
    calc((var(--arrow-button-size) / -2) - var(--margin-lg)),
    -50%
  );
}

.Slideshow_next_1gehw {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(
    calc((var(--arrow-button-size) / 2) + var(--margin-lg)),
    -50%
  );
}

.Slideshow_previous_1gehw.Slideshow_inside_1gehw {
  transform: translate(
    calc((var(--arrow-button-size) / -2) + var(--margin-xl)),
    -50%
  );
}

.Slideshow_next_1gehw.Slideshow_inside_1gehw {
  transform: translate(
    calc((var(--arrow-button-size) / 2) - var(--margin-xl)),
    -50%
  );
}

.Slide_slide_1k4ly {
  display: block;
  margin: 0;
  width: 100%;
  flex: 0 0 auto;
}

.Strikeout_strikeout_1l7ei {
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  text-decoration: none;
}

.Strikeout_strikeout_1l7ei::before {
  position: absolute;
  content: '';
  left: 0;
  top: calc(50% - 2px);
  right: 0;
  border-top: 2px solid;
  border-color: currentColor;
}

.StyledSelect_button_1x8xh {
  height: var(--tfd-styledselect-height);
  margin: 0;
  padding: 0 0 0 var(--margin-sm);
  width: 100%;
  height: 100%;
  display: block;
  background: transparent;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: var(--tfd-fieldlabel-radius);
  box-sizing: border-box;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.StyledSelect_button_1x8xh:focus {
  outline: none;
}

.StyledSelect_buttonText_1x8xh,
.StyledSelect_buttonIcon_1x8xh {
  display: block;
}

.StyledSelect_buttonText_1x8xh {
  width: 0;
  flex: 1 0;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}

.StyledSelect_buttonIcon_1x8xh {
  pointer-events: none;
}

.StyledSelect_buttonIcon_1x8xh.StyledSelect_isOpen_1x8xh {
  transform: rotate(180deg);
}

.StyledSelect_chevron_1x8xh.StyledSelect_buttonIcon_1x8xh {
  transform: rotate(90deg);
}

.StyledSelect_chevron_1x8xh.StyledSelect_buttonIcon_1x8xh.StyledSelect_isOpen_1x8xh {
  transform: rotate(-90deg);
}

.StyledSelect_container_1x8xh {
  display: inline-block;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.StyledSelect_boldSelectedValue_1x8xh {
  letter-spacing: 0;
  line-height: 26px;
}

.SecondaryNavigation_navContainer_16fvo {
  background-color: var(--chickpea-0);
}

.SecondaryNavigation_navbar_16fvo {
  background-color: var(--chickpea-0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 var(--tfd-spacing-md) 0 var(--tfd-spacing-md);
  overflow: auto;
}

@media (min-width: 1024px) {
  .SecondaryNavigation_navbar_16fvo {
    margin: 0;
  }
}

.SecondaryNavigation_navbar_16fvo .SecondaryNavigation_actionsContainer_16fvo {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .SecondaryNavigation_navbar_16fvo .SecondaryNavigation_actionsContainer_16fvo {
    width: auto;
    width: initial;
  }
}

.SecondaryNavigation_navbar_16fvo {
  /* with this margin we will make sure a space is reserved for the scrollbar */
  margin-bottom: 8px;
}

@media (min-width: 1024px) {
  .SecondaryNavigation_navbar_16fvo {
    margin-bottom: 0;
  }
}

/* just target ios devices */

@supports (-webkit-touch-callout: none) {
  .SecondaryNavigation_navbar_16fvo {
    /* with this padding we will make sure a space is reserved for the scrollbar on ios */
    padding-bottom: 6px;
  }
}

/* Scrollbar properties */

/* When changing this properties is needed to set all the other properties for the scrollbar */

.SecondaryNavigation_navbar_16fvo::-webkit-scrollbar {
  /* 5px will be the width of the scrollbar of horizontal position, this will make sure the navbar does not grow up*/
  height: 4px;
}

/* Track */

.SecondaryNavigation_navbar_16fvo::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

/* setup for the scrollbar */

.SecondaryNavigation_navbar_16fvo:hover::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136, 0.8);
  border-radius: 8px;
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.SecondaryNavigation_navbar_16fvo .SecondaryNavigation_actionsContainer_16fvo .SecondaryNavigation_navList_16fvo {
  display: flex;
  align-items: baseline;
  flex: 1;
  -moz-column-gap: var(--tfd-spacing-md);
       column-gap: var(--tfd-spacing-md);
  margin: 0;
  padding: 0;
}

@media (min-width: 1024px) {
  .SecondaryNavigation_navbar_16fvo .SecondaryNavigation_actionsContainer_16fvo .SecondaryNavigation_navList_16fvo {
    justify-content: center;
    padding-left: 0%;
  }
}

.SecondaryNavigation_navbar_16fvo li {
  list-style: none;
  color: var(--charcoal-0);
  height: 100%;
  display: flex;
  box-sizing: border-box;
}

.SecondaryNavigation_navbar_16fvo div.SecondaryNavigation_activeUnderline_16fvo {
  height: 4px;
  margin: 0;
  padding: 0;
  width: 100%;
  border: 0;
  background: var(--kale-3);
  transition: all var(--tfd-animation-standard);
}

.SecondaryNavigation_navItem_16fvo {
  display: flex;
  align-items: center;
  padding-top: calc(var(--tfd-spacing-lg) / 2);
  /* 4px is the height of the activeUnderline element */
  padding-bottom: calc(var(--tfd-spacing-lg) / 2 - 4px);
}

.TextArea_textarea_9ekae {
  box-sizing: border-box;
  /** Remove the margin in Firefox and Safari */
  margin: 0;
  padding: 12px;
  width: 100%;
  min-height: 100px;
  height: 100%;
  display: block;
  background: transparent;
  border: none;
  resize: vertical;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  /* This is needed or else autofill box shadow cuts off the border */
  border-radius: 4px;
  color: var(--kale-3);
  letter-spacing: 0.5px;
}

.TextArea_textarea_9ekae:focus {
  outline: none;
}

.TextArea_textarea_9ekae::-moz-placeholder {
  color: var(--charcoal-2);
}

.TextArea_textarea_9ekae::placeholder {
  color: var(--charcoal-2);
}

.TextArea_textarea_9ekae:-webkit-autofill {
  box-shadow: inset 0 0 0 9999px var(--white);
}

.TextArea_fieldLabel_9ekae {
  min-height: calc(100px + var(--tfd-fieldlabel-text-offset));
  height: auto;
}

.Toast_portal_1sgh7 {
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100vh;
  z-index: var(--tfd-toast-z-index);
  /* Make sure elements behind can be clicked */
  pointer-events: none;
}

.Toast_container_1sgh7 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.Toast_toast_1sgh7 {
  margin: var(--tfd-spacing-sm);
  min-height: 26px;
  padding: var(--tfd-spacing-sm) var(--tfd-spacing-md);
  border: 2px solid transparent;
  border-radius: var(--tfd-border-radius-sm);
  box-sizing: border-box;
  /* Restore pointer event overridden by toast container */
  pointer-events: auto;
}

@media (min-width: 375px) {
  .Toast_toast_1sgh7 {
    margin: var(--tfd-spacing-sm) var(--tfd-spacing-lg);
  }
}

@media (min-width: 710px) {
  .Toast_toast_1sgh7 {
    margin: var(--tfd-spacing-sm) auto;
    max-width: 630px;
  }
}

@media (min-width: 1024px) {
  .Toast_toast_1sgh7 {
    margin: var(--tfd-spacing-sm) auto;
    max-width: 720px;
  }
}

.Toast_toast_1sgh7 + .Toast_toast_1sgh7 {
  margin-top: var(--tfd-spacing-sm);
}

.Toast_neutral_1sgh7 {
  border-color: var(--chickpea-2);
  background: var(--chickpea-1);
}

.Toast_negative_1sgh7 {
  border-color: #e0aa9f;
  background: var(--carrot-1);
}

.Toast_positive_1sgh7 {
  border-color: #9bb5a0;
  background: var(--kale-1);
}

.Toast_document_1sgh7 {
  display: flex;
  flex-direction: row;
}

.Toast_message_1sgh7 {
  flex: 1;
}

@keyframes transitions_spring-in_1qzcr {
  0% {
    transform: translateY(-100px);
  }
  2% {
    transform: translateY(-82.76px);
  }
  4% {
    transform: translateY(-60.58px);
  }
  6% {
    transform: translateY(-38.26px);
  }
  8% {
    transform: translateY(-18.89px);
  }
  10% {
    transform: translateY(-4.01px);
  }
  12% {
    transform: translateY(5.99px);
  }
  14% {
    transform: translateY(11.54px);
  }
  16% {
    transform: translateY(13.49px);
  }
  18% {
    transform: translateY(12.91px);
  }
  20% {
    transform: translateY(10.78px);
  }
  22% {
    transform: translateY(7.97px);
  }
  24% {
    transform: translateY(5.1px);
  }
  26% {
    transform: translateY(2.59px);
  }
  28% {
    transform: translateY(0.64px);
  }
  30% {
    transform: translateY(-0.69px);
  }
  32% {
    transform: translateY(-1.44px);
  }
  34% {
    transform: translateY(-1.72px);
  }
  36% {
    transform: translateY(-1.66px);
  }
  38% {
    transform: translateY(-1.4px);
  }
  40% {
    transform: translateY(-1.05px);
  }
  42% {
    transform: translateY(-0.68px);
  }
  44% {
    transform: translateY(-0.35px);
  }
  46% {
    transform: translateY(-0.1px);
  }
  48% {
    transform: translateY(0.08px);
  }
  50% {
    transform: translateY(0.18px);
  }
  52% {
    transform: translateY(0.22px);
  }
  56% {
    transform: translateY(0.18px);
  }
  58% {
    transform: translateY(0.14px);
  }
  60% {
    transform: translateY(0.09px);
  }
  62% {
    transform: translateY(0.05px);
  }
  64% {
    transform: translateY(0.01px);
  }
  66% {
    transform: translateY(-0.01px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes transitions_spring-out_1qzcr {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.transitions_entering_1qzcr {
  animation-timing-function: linear;
  animation-name: transitions_spring-in_1qzcr;
  animation-duration: 600ms;
}

.transitions_entered_1qzcr {
}

.transitions_exiting_1qzcr {
  animation-timing-function: linear;
  animation-name: transitions_spring-out_1qzcr;
  animation-duration: 100ms;
  animation-fill-mode: forwards;
}

.transitions_unmounted_1qzcr,
.transitions_exited_1qzcr {
}

.ToggleBox_root_1nfje {
  --default-color: var(--charcoal-1);
  --hover-color: var(--kale-3);
  --checked-color: var(--kale-3);
  --focused-color: var(--kale-3);
  --disabled-color: var(--oyster-3);

  display: block;
  max-width: 327px;
  box-sizing: content-box;
  position: relative;

  /* Padding for the badge */
  padding-top: calc(var(--tfd-spacing-sm) / 2);
}

.ToggleBox_root_1nfje.ToggleBox_focus-visible_1nfje {
  outline: none;
}

.ToggleBox_root_1nfje:focus-visible {
  outline: none;
}

.ToggleBox_root_1nfje:hover {
  --default-color: var(--hover-color);
}

.ToggleBox_root_1nfje.ToggleBox_badge-default_1nfje {
  --badge-color: var(--charcoal-3);
}

.ToggleBox_root_1nfje:hover.ToggleBox_badge-default_1nfje {
  --badge-color: var(--hover-color);
}

.ToggleBox_root_1nfje.ToggleBox_badge-recommended_1nfje {
  --badge-color: var(--carrot-2);
}

.ToggleBox_root_1nfje + .ToggleBox_root_1nfje {
  margin-top: calc(var(--tfd-spacing-md) - (var(--tfd-spacing-sm) / 2));
}

.ToggleBox_root_1nfje [role='status'] {
  position: absolute;
  top: calc(var(--tfd-spacing-sm) / 2);
  left: var(--tfd-spacing-md);
  transform: translateY(-50%);
}

.ToggleBox_container_1nfje {
  transition: border-color var(--tfd-animation-standard-duration)
    var(--tfd-animation-standard-timing-function);
  border: 1px solid var(--default-color);
  border-radius: var(--tfd-border-radius-sm);
  cursor: pointer;
  padding: var(--tfd-spacing-md);
}

.ToggleBox_grid_1nfje {
  display: flex;
  align-items: center;
}

.ToggleBox_grid_1nfje > div:first-child {
  flex-grow: 1;
  overflow: hidden;
}

.ToggleBox_grid_1nfje > div:last-child {
  flex-grow: 0;
  display: flex;
}

.ToggleBox_input_1nfje.ToggleBox_focus-visible_1nfje ~ .ToggleBox_container_1nfje {
  --default-color: var(--focused-color);
  border: 1px solid var(--checked-color);
  box-shadow: var(--tfd-outline-box-shadow);
}

.ToggleBox_input_1nfje:focus-visible ~ .ToggleBox_container_1nfje {
  --default-color: var(--focused-color);
  border: 1px solid var(--checked-color);
  box-shadow: var(--tfd-outline-box-shadow);
}

.ToggleBox_input_1nfje.ToggleBox_focus-visible_1nfje ~ [role='status'] {
  box-shadow: var(--tfd-outline-box-shadow);
}

.ToggleBox_input_1nfje:focus-visible ~ [role='status'] {
  box-shadow: var(--tfd-outline-box-shadow);
}

.ToggleBox_input_1nfje:disabled ~ [role='status'] {
  --badge-color: var(--disabled-color);
}

.ToggleBox_input_1nfje:checked ~ .ToggleBox_container_1nfje {
  --default-color: var(--checked-color) !important;
}

.ToggleBox_input_1nfje:checked ~ .ToggleBox_container_1nfje {
  border: 1px solid var(--checked-color);
}

.ToggleBox_root_1nfje.ToggleBox_badge-default_1nfje .ToggleBox_input_1nfje:checked ~ [role='status'] {
  --badge-color: var(--checked-color);
}

.ToggleBox_root_1nfje.ToggleBox_badge-default_1nfje .ToggleBox_input_1nfje.ToggleBox_focus-visible_1nfje ~ [role='status'] {
  --badge-color: var(--focused-color);
}

.ToggleBox_root_1nfje.ToggleBox_badge-default_1nfje .ToggleBox_input_1nfje:focus-visible ~ [role='status'] {
  --badge-color: var(--focused-color);
}

.ToggleBox_input_1nfje:disabled ~ .ToggleBox_container_1nfje {
  border: 1px solid var(--disabled-color);
  cursor: default;
}

.ToggleBox_input_1nfje:disabled ~ .ToggleBox_container_1nfje .ToggleBox_label_1nfje,
.ToggleBox_input_1nfje:disabled ~ .ToggleBox_container_1nfje .ToggleBox_supportCopy_1nfje {
  color: var(--disabled-color);
}

.ToggleBox_label_1nfje {
  display: block;
  line-height: 32px;
  color: var(--checked-color);
  white-space: nowrap;
  overflow: hidden;
  padding-right: var(--tfd-spacing-sm);
  text-overflow: ellipsis;
}

.ToggleBox_supportCopy_1nfje {
  line-height: 26px;
  color: var(--charcoal-3);
  padding-right: var(--tfd-spacing-sm);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Badge_root_h1us8 {
  display: inline-block;
  border-radius: var(--tfd-border-radius-xs);
  background-color: var(--badge-color);
  padding: var(--tfd-togglebox-badge-padding);
  color: var(--white);
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: background-color;
}

/* Please note this CSS uses variables set for the parent */
.CheckMark_root_11sxt {
  /* -webkit-transform fixes a glitch in Safari, causing the svg content to subtly move when transitioning the opacity */
  -webkit-transform: translate3d(0, 0, 0);
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: var(--tfd-border-radius-sm);
  /* Note that --default-color changes when the ToggleBox root is hovered */
  border: solid 1px var(--default-color);
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: border-color, background-color, box-shadow;
}
.CheckMark_root_11sxt > svg {
  opacity: 0;
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: opacity;
}
.CheckMark_root_11sxt.CheckMark_checked_11sxt {
  border-color: var(--checked-color);
  background: var(--checked-color);
}
.CheckMark_root_11sxt.CheckMark_checked_11sxt > svg {
  opacity: 1;
}
.CheckMark_root_11sxt.CheckMark_disabled_11sxt {
  border-color: var(--disabled-color);
}
.CheckMark_root_11sxt.CheckMark_disabled_11sxt.CheckMark_checked_11sxt {
  background: var(--disabled-color);
}

.CircleMark_root_4qrqh {
  -webkit-transform: translate3d(0, 0, 0);
  position: relative;
  display: inline-block;
  height: 32px;
  width: 32px;
  border: 1.5px solid var(--default-color);
  border-radius: 50%;
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: border-color, background-color;
}

.CircleMark_lg_4qrqh {
  height: 32px;
  width: 32px;
}

.CircleMark_md_4qrqh {
  height: 16px;
  width: 16px;
}

.CircleMark_root_4qrqh:after {
  content: '';
  opacity: 0;
  position: absolute;
  top: 0.5px;
  left: 0.5px;
  right: 0.5px;
  bottom: 0.5px;
  border: 2px solid var(--white);
  border-radius: 50%;
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: opacity;
}

.CircleMark_root_4qrqh.CircleMark_focused_4qrqh {
  border-color: var(--focused-color);
}

.CircleMark_root_4qrqh.CircleMark_checked_4qrqh {
  background-color: var(--checked-color);
  border-color: var(--checked-color);
}

.CircleMark_root_4qrqh.CircleMark_checked_4qrqh:after {
  opacity: 1;
}

.CircleMark_root_4qrqh.CircleMark_disabled_4qrqh {
  border-color: var(--disabled-color);
}

.CircleMark_root_4qrqh.CircleMark_checked_4qrqh.CircleMark_disabled_4qrqh {
  background-color: var(--disabled-color);
}

.ToggleCard_group_1v1cb {
  --image-size: 100px;

  margin-top: calc(var(--image-size) / 2);

  display: inline-flex;
  width: 100%;
  min-width: var(--image-size);
  box-sizing: content-box;
  position: relative;
}

.ToggleCard_root_1v1cb.ToggleCard_focus-visible_1v1cb {
  outline: none;
}

.ToggleCard_root_1v1cb:focus-visible {
  outline: none;
}

.ToggleCard_badgeContainer_1v1cb {
  position: absolute;
  top: calc(-1 * var(--image-size) / 4);
  left: 50%;
  transform: translateY(-50%) translateX(-100%);
}

.ToggleCard_badge_1v1cb {
  display: inline-block;
  border-radius: var(--tfd-border-radius-xs);
  background-color: var(--kale-3);
  padding: 0 var(--tfd-spacing-xs);
  line-height: 18px;
  color: var(--white);
}

.ToggleCard_container_1v1cb {
  width: 100%;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--white);
  border-radius: var(--tfd-border-radius-lg);
}

.ToggleCard_imageContainer_1v1cb {
  align-self: center;
}

.ToggleCard_image_1v1cb {
  margin: 0 auto;
  transform: translateY(-50%);
  margin-bottom: calc(-1 * var(--image-size) / 2);
  max-height: var(--image-size);
  max-width: var(--image-size);
}

.ToggleCard_content_1v1cb {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: var(--tfd-spacing-sm);
  padding: var(--tfd-spacing-sm) var(--tfd-spacing-md);
}

.ToggleCard_contentTexts_1v1cb {
  display: flex;
  flex-direction: column;
  row-gap: var(--tfd-spacing-sm);
}

.ToggleCard_content_1v1cb > .ToggleCard_contentTexts_1v1cb > h5,
.ToggleCard_content_1v1cb > .ToggleCard_contentTexts_1v1cb > p {
  margin: 0;
}

.ToggleCard_content_1v1cb > .ToggleCard_button_1v1cb {
  text-align: left;
  font-weight: 500;
  white-space: normal;
}

.ToggleCard_control_1v1cb {
  border-top: 2px solid var(--oyster-1);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--tfd-spacing-sm);
  padding: var(--tfd-spacing-sm) var(--tfd-spacing-md);
}

.ToggleCard_label_1v1cb {
  line-height: 32px;
  color: var(--kale-3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Tooltip_overlay-wrapper_h9ybp {
  position: relative;
  display: inline-block;
}

/* Sets max width via width prop */

.Tooltip_tooltip-wrapper_h9ybp {
  border-radius: 6px;
  display: flex;
  overflow: hidden; /* makes tooltip triangle disappear */
  position: absolute;
  /* visibility: hidden; */
  width: 360px; /* max width; will clip beyond this point*/
  z-index: 1;
}

.Tooltip_tooltip_h9ybp {
  display: inline-block;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
}

.Tooltip_tooltip_h9ybp.Tooltip_light_h9ybp {
  background-color: var(--white);
  border: 1px solid var(--charcoal-0);
  color: var(--charcoal-3);
}

.Tooltip_tooltip_h9ybp.Tooltip_dark_h9ybp {
  background-color: var(--charcoal-3);
}

.Tooltip_tooltip_h9ybp.Tooltip_dark_h9ybp,
.Tooltip_tooltip_h9ybp.Tooltip_dark_h9ybp * {
  color: white;
}

.Tooltip_tooltip-wrapper_h9ybp[aria-hidden='true'] {
  visibility: hidden;
  width: 0;
}

.Tooltip_tooltip-wrapper_h9ybp[aria-hidden='false'] {
  visibility: visible;
}

.Tooltip_tooltip-top_h9ybp {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  padding-bottom: var(--margin-xs);
}

.Tooltip_tooltip-top-left_h9ybp {
  bottom: 100%;
  padding-bottom: var(--margin-xs);
}

.Tooltip_tooltip-top-right_h9ybp {
  bottom: 100%;
  left: 100%;
  transform: translateX(-100%);
  justify-content: flex-end;
  padding-bottom: var(--margin-xs);
}

.Tooltip_tooltip-bottom_h9ybp {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  padding-top: var(--margin-xs);
}

.Tooltip_tooltip-bottom-left_h9ybp {
  top: 100%;
  padding-top: var(--margin-xs);
}

.Tooltip_tooltip-bottom-right_h9ybp {
  top: 100%;
  left: 100%;
  transform: translateX(-100%);
  justify-content: flex-end;
  padding-top: var(--margin-xs);
}

.Tooltip_tooltip-left_h9ybp {
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  justify-content: flex-end;
  padding-right: var(--margin-xs);
}

.Tooltip_tooltip-left-top_h9ybp {
  top: 0%;
  right: 100%;
  justify-content: flex-end;
  padding-right: var(--margin-xs);
}

.Tooltip_tooltip-left-bottom_h9ybp {
  right: 100%;
  transform: translateY(-100%);
  justify-content: flex-end;
  padding-right: var(--margin-xs);
}

.Tooltip_tooltip-right_h9ybp {
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  padding-left: var(--margin-xs);
}

.Tooltip_tooltip-right-top_h9ybp {
  top: 0%;
  left: 100%;
  padding-left: var(--margin-xs);
}

.Tooltip_tooltip-right-bottom_h9ybp {
  left: 100%;
  transform: translateY(-100%);
  padding-left: var(--margin-xs);
}

.VideoSection_container_15n5r {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  min-height: 360px;
  outline: medium none invert;
  outline: initial;
}
.VideoSection_activityIndicator_15n5r {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.VideoSection_wistiaElement_15n5r {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.VideoSection_gradient_15n5r {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.12)
  );
}
.VideoSection_controls_15n5r {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.VideoSection_overlay_15n5r {
  position: absolute;
  width: 100%;
  top: 63%; /* Starts at the last third of its height */
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 var(--tfd-spacing-md);
  text-align: center;
}
.VideoSection_interactionBlocker_15n5r {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* Force to hide the Wistia context menu as it is not accessible */
.w-context-menu {
  display: none !important;
}

.WistiaElement_wistiaElement_12q8w {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  min-height: 360px;
}

.WistiaElement_wistiaElement_12q8w .WistiaElement_popover_12q8w {
  display: inline-block;
}

/*# sourceMappingURL=styles.css.map */