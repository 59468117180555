.bowlSection {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: var(--tfd-spacing-lg);
  transform: translate(-16rem, -18.75rem);
}

.bowlContainer {
  position: relative;
  width: 50vw;
  max-width: 50rem;
  opacity: 0;
}

.bowlContainer.optimized_hero {
  transform: translate(-1.5rem, -4rem);
}

.bowlImageWrapper {
  position: relative;
  z-index: 20;
  transform: translateX(-800px) rotate(-120deg);
}

.bowlImage {
  width: 100%;
  object-fit: fill;
}

.bowlShadowImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: scale(1.2) rotate(-110deg) translateX(-120px);
}

.bowlContent {
  text-align: left;
  flex: 1;
  max-width: 492px;
  padding: 12rem 0;
  transform: translateY(7.75rem);
}

.packSection {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: var(--tfd-spacing-lg);
  width: 88%;
  min-height: 25rem;
  transform: translate(2rem, -20rem);
  z-index: 10;
  margin-bottom: -10rem;
}

.packImageWrapper {
  position: absolute;
  top: -24rem;
  right: -17rem;
}

.packImage {
  width: 50vw;
  max-width: 37.5rem;
  transform: rotate(30deg);
}

.packContent {
  position: relative;
  min-width: 375px;
  width: 45%;
  text-align: left;
  margin-left: 2rem;
}

.bowlArrowContainer {
  min-height: 72px;
}

.bowlArrow {
  transform: translateX(-5rem);
  width: 140px;
}

.packArrow {
  position: absolute;
  right: 0;
  transform: translate(3rem, -3rem);
  width: 120px;
}

@media (--viewport-xl) {
  .bowlContainer {
    width: 56vw;
  }

  .bowlShadowImage {
    transform: scale(1.2) rotate(-110deg) translateX(-164px);
  }

  .packSection {
    width: 80%;
    transform: translate(12rem, -22rem);
  }

  .packContent {
    margin-left: 6rem;
    width: 60%;
    max-width: 375px;
  }

  .bowlContent {
    padding: 12rem 0;
    transform: translateY(4rem);
  }

  .packImageWrapper {
    top: -24rem;
    right: -14rem;
  }

  .packArrow {
    position: absolute;
    right: 0;
    transform: translate(5rem, -3rem);
  }
}
