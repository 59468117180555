.banner {
  min-height: var(--tfd-banner-min-height);
}

.header {
  position: sticky;
  top: 0;
  z-index: var(--tfd-appnav-z-index);
}

.chickpeaBackground {
  background-color: var(--chickpea-experimental) !important;
}
