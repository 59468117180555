.wrapper {
  margin: auto;
  max-width: 1440px;
  text-align: center;
  width: 100%;
}

.buttonsContainer {
  display: flex;
  gap: var(--tfd-spacing-xs);
  justify-content: center;
}

.button {
  width: 100%;
  min-width: unset !important;
}

@media (--viewport-md) {
  .buttonsContainer {
    gap: var(--tfd-spacing-md);
  }

  .button {
    width: unset;
  }
}
