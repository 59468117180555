.wistiaElement {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  min-height: 360px;
}

.wistiaElement .popover {
  display: inline-block;
}
