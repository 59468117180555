.container {
  display: flex;
  padding: var(--margin-lg);
  flex-direction: row-reverse;
  justify-content: left;
}

.image {
  position: absolute;
  bottom: 0;
  max-height: 142px;
  right: 18px;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: 100px;
  align-items: flex-start;
  width: 100%;
  column-gap: var(--margin-md);
  row-gap: var(--margin-md);
}

@media (--viewport-lg) {
  .container {
    flex-direction: row;
  }
  
  .image {
    left: 45px;
  }
  
  .content {
    margin-left: 170px;
    margin-right: 0;
    flex-direction: column;
  }
}
