.quoteTitle {
  color: black;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.title {
  padding-right: 30px;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list li {
  display: flex;
  gap: var(--margin-md);
  align-items: flex-start;
}

.list svg {
  width: 16.6px;
  flex-shrink: 0;
}

.modalContent {
  height: 100%;
  position: relative;
  background-color: #f0f3f0;
  padding: 40px 60px 18px 60px;
}

.blockquote {
  margin: 0;
  background: white;
  padding: var(--margin-md);
  margin-bottom: var(--margin-lg);
}

.closeButton {
  top: 18px;
  right: 60px;
  position: absolute;

  &.bottom {
    top: auto;
    bottom: 53px;
  }
}

.button {
  width: fit-content;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  max-height: 100%;
}

.imageColumn {
  background-size: cover;
}

/* Changes the z-index of the lightbox */
:has(.backdrop) {
  z-index: calc(var(--tfd-appnav-z-index) + 1) !important;
}

@media (--viewport-xl) {
  .title {
    padding-right: 0;
  }
}
