.container {
  background: var(--chickpea-1);
  padding: 72px 30px 96px;
  text-align: left;

  .column {
    max-width: 375px;
    margin: 0 auto;
  }

  p {
    margin-top: 24px;
    margin-bottom: 0;
  }

  a {
    margin-top: 36px;
    margin-left: 5px;
  }

  &.lg {
    padding: 96px 30px 120px;
    text-align: center;

    .column {
      max-width: 620px;
    }
  }
}

.lg.accessibilityThemeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--tfd-spacing-lg) auto;
  text-align: center;
  max-width: 620px;
}
