@media (--viewport-lg) {
  .container {
    max-width: 1012px;
  }
  .group-description {
    display: none;
  }
}

@media (--viewport-sm) {
  .container {
    margin-top: 16px;
  }
}
