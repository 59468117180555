.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;
  overflow-x: clip;
}

.mobile {
  position: relative;
  text-align: center;
}

.desktop {
  margin: auto;
  max-width: 1440px;
  position: relative;
  text-align: center;
}

@media (--viewport-lg) {
  .wrapper {
    padding-top: 60px;
  }
}
