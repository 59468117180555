.container {
  display: flex;
  padding: var(--margin-lg);
  flex-direction: row-reverse;
  justify-content: left;
}

.image {
  position: absolute;
  bottom: 0;
  right: 18px;
  max-height: 142px;
}
.content {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: 100px;
  justify-content: space-between;
  width: 100%;
  column-gap: var(--margin-md);
  row-gap: var(--margin-md);
}

@media (--viewport-lg) {
  .container {
    flex-direction: row;
  }

  .image {
    left: 36px;
  }

  .content {
    margin-left: 170px;
    margin-right: 0;
    flex-direction: row;
  }

  .content div,
  .content span {
    align-self: center;
  }
}
