.container {
  background: var(--white);
  text-align: left;

  .column {
    max-width: 720px;
    padding: 72px 30px 0;
    margin: 0 auto;
  }

  .video {
    margin: var(--tfd-spacing-xl) 0;
    height: 512px;
    width: 100%;
  }

  .videoHeading {
    display: none;
  }

  .videoQuote {
    margin: 0;
    padding-bottom: 120px;
    max-width: 620px;
  }

  .videoQuote blockquote {
    margin: 0;
  }

  p {
    margin-top: 24px;
    margin-bottom: 0;
  }
}

@media (--viewport-lg) {
  .container {
    padding: 96px 30px 0;
    text-align: center;

    .video {
      height: 540px;
    }
  }
}
