.title,
.subtitle {
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 40px;
  line-height: 48px;
}

.subtitle {
  font-size: 16px;
  line-height: 22px;
}

.ctaButton {
  right: 50%;
  bottom: 70px;
  position: absolute;
  transform: translateX(50%);
}

.accessibilityThemeCta {
  background-color: var(--kale-0) !important;
  color: var(--kale-3) !important;
  border-radius: 30px !important;
}

.bottomTextButton {
  margin-top: var(--margin-md);
  position: static;
  transform: unset;
  align-self: center;
}

.bottomTextButton .subtitle {
  line-height: 22px;
}

.baseCopyContainerClassname {
  width: 100%;
}

@media (--viewport-lg) {
  .ctaButton {
    margin: 0;
    position: static;
    transform: unset;
  }

  .title,
  .subtitle {
    margin-left: 0;
    margin-right: 0;
  }

  .title {
    font-size: 52px;
    line-height: 62px;
  }

  .subtitle {
    font-size: 22px;
    line-height: 30px;
    max-width: unset;
  }

  .bottomTextButton {
    align-self: unset;
  }
}
