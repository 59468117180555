@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.plan-summary {
  position: fixed;
  text-align: center;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  padding: $margin-sm $margin-md $margin-lg;
  width: 100vw;
  box-shadow: 0px -6px 4px -4px rgba(0, 0, 0, 0.2);
}

.lg.plan-summary {
  margin: $margin_lg 0;
  position: relative;
  bottom: unset;
  width: unset;
  text-align: left;
  box-shadow: none;
  padding: $margin-sm 0 0;
}

.cta {
  margin: $margin_sm auto 0;
  display: block;
}

.cta.fullWidth {
  width: 100%;
}

.lg .cta {
  margin: $margin_lg 0 0;
}
