.pageHeroWrapper {
  margin: auto;
  max-width: 1440px;
  text-align: center;
  overflow-x: clip;
}

.heroContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--tfd-spacing-lg);
  justify-content: center;
  margin-bottom: var(--tfd-spacing-md);
  margin-top: var(--tfd-spacing-sm);
}

@media (--viewport-xl) {
  .heroContent {
    flex-direction: row;
    gap: var(--tfd-spacing-lg);
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 30px;
  }
}
