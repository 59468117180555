.topContent {
  padding: 0 var(--tfd-spacing-sm);
  max-width: 492px;
  position: relative;
}

.bowlWrapper {
  width: 100%;
}

.bowlContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10rem;
  width: 100%;
  max-width: 100vw;
  position: relative;
}

.packImageContainer {
  width: 100%;
  max-width: 70vw;
  position: absolute;
  left: 0;
  transform: translate(41vw, 2rem);
  z-index: 30;
}

.packImageContainer picture {
  margin-left: auto;
}

.bowlImage {
  position: relative;
  z-index: 20;
}

.bowlShadowImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: scale(1.4) rotate(-110deg) translateX(-50px);
  z-index: 10;
}

.packImage {
  transform: rotate(30deg);
  max-width: 20rem;
  margin-bottom: var(--tfd-spacing-md);
}

.bowlImage,
.packImage {
  width: 100%;
  object-fit: fill;
}

.bottomContent {
  padding: 0 var(--tfd-spacing-md);
  max-width: 383px;
  position: relative;
}

.bowlArrow {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-1.75rem, 4.25rem);
  z-index: 100;
  min-width: 130px;
  width: 26vw;
  max-width: 150px;
}

.packArrow {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(13vw, -5.5rem);
  z-index: 100;
  width: 160px;
}

.desktop {
  display: none;
}

@media (--viewport-md) {
  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  .bowlImage {
    transform: scale(1) translateX(4px);
  }

  .bowlArrow {
    transform: translate(-2rem, 5rem);
    width: 140px;
  }

  .packArrow {
    width: 180px;
  }

  .packImageContainer {
    transform: translate(16rem, 2rem);
  }
}
