.modal {
  width: 327px;
  height: auto;
  padding: var(--margin-md);
  border-radius: var(--margin-md);
  background-color: var(--chickpea-0);
}

.grid {
  text-align: center;
}

.skipTreatsButton {
  margin-top: var(--margin-lg);
  width: 100%;
}

.removeFromPlanButton {
  margin-top: var(--margin-sm);
  width: 100%;
  padding: 8px;
}

@media (--viewport-md) {
  .modal {
    width: 462px;
  }
}
