.bowl {
  max-width: 250px;
}

.title {
  display: flex;
  justify-content: center;
  max-width: 250px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  line-height: 32px;
  max-width: 250px;
}

.subtitleMobile {
  line-height: 26px;
  max-width: 335px;
}

.time {
  margin-top: 0;
  margin-bottom: var(--margin-lg);
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.buttonBackground {
  background-color: var(--white);
  position: fixed;
  width: 100vw;
  left: 0;
  bottom: 0;
  padding-top: var(--margin-lg);
  padding-bottom: var(--margin-lg);
  z-index: 2;
}

.benefitsGrid {
  max-width: 335px;
}

.benefitList {
  list-style: none;
  text-align: left;
  margin: 0;
}

.gridBowl {
  max-width: 129px;
  margin-top: 7px;
}

@media (--viewport-md) {
  .time {
    margin-bottom: var(--margin-3xl);
  }

  .bowl {
    max-width: 450px;
    position: absolute;
    transform: translateX(-160%) translateY(-5%);
  }

  .title {
    max-width: 480px;
  }

  .subtitle {
    max-width: 520px;
    margin-bottom: var(--margin-lg);
  }

  .buttonBackground {
    width: auto;
    position: relative;
    display: block;
    margin: auto;
    margin-bottom: var(--margin-xl);
    padding-top: 0;
    padding-bottom: 0;
  }
}
