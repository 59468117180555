.container {
  background: var(--white);
  padding: 72px 30px;
  text-align: center;

  p {
    display: block;
    max-width: 588px;
    margin: 17px auto 0;
  }

  a {
    margin-top: var(--tfd-spacing-md);
  }
}

@media (--viewport-lg) {
  .container {
    padding: var(--tfd-spacing-xxl) 30px 96px;

    p {
      margin-top: 24px;
    }
  }
}
