.wrapper {
  width: 100%;
}

.container {
  background-color: var(--kale-3);
  border-radius: 20px;
  height: 423px;
  margin: auto;
  max-width: 994px;
  padding: var(--margin-lg) var(--margin-md) var(--margin-md) var(--margin-md);
  width: 100%;
}

.subtitle {
  letter-spacing: 2px;
  text-transform: uppercase;
}

.mobileBreakline {
  display: block;
}

.desktopBreakline {
  display: none;
}

.selectedTitle {
  margin-top: 40px;
}

.button {
  width: 100%;

  &:active {
    background-color: var(--kale-2) !important;
  }
}

.sizeText {
  font-size: 16px;
  line-height: 22px;
}

.normalText {
  font-weight: normal;
}

.resetButton {
  display: block;
  margin: 0 auto;
  color: var(--kale-1);
  text-decoration: underline;
  margin-bottom: var(--margin-lg);
}

@media (--viewport-md) {
  .sizeText {
    font-size: 22px;
    line-height: 30px;
  }
}

@media (--viewport-lg) {
  .container {
    height: 375px;
    padding: var(--margin-xl) var(--margin-xl) var(--margin-xxl)
      var(--margin-xl);
  }

  .desktopBreakline {
    display: block;
  }

  .mobileBreakline {
    display: none;
  }

  .selectedTitle {
    margin-top: 0;
  }

  .resetButton {
    font-size: 18px;
    margin-bottom: var(--margin-sm);
  }
}
