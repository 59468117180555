.section {
  padding: 0 var(--margin-md);
  text-align: center;
  width: 100%;
}

.accordion button {
  padding: 0 12px;
  margin: var(--margin-sm) 0 !important;
}

.accordion div:first-of-type {
  border-top: none !important;
}

.accordion h3 {
  font-size: 16px;
  font-weight: bold !important;
  text-align: left;
  color: var(--kale-3);
}

.title {
  margin-top: 60px;
}

.link {
  text-decoration: underline;
}

@media (--viewport-lg) {
  .container {
    margin: 0 auto;
    max-width: 486px;
  }

  .title {
    margin-top: 0;
    font-size: 40px;
  }
}
