.jadaPuppy {
  margin: 24px auto 0;
}

.container {
  background: var(--white);
  padding: 72px 30px 96px;
  text-align: center;

  p {
    max-width: 375px;
    margin: 6px auto 0;
  }

  h2 {
    margin: var(--tfd-spacing-md) auto 0;
  }
}

@media (--viewport-lg) {
  .container {
    padding: 96px 30px 120px;

    p {
      max-width: 375px;
      margin-top: var(--tfd-spacing-sm);
    }
  }
}
