.card {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.4s ease-in-out;
}

.card::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  z-index: 2;
}

.card img {
  transition: transform 0.4s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  vertical-align: bottom;
}

.card:hover img,
.card:focus img {
  transform: scale(1.2);
}

.title,
.openButton {
  position: absolute;
  z-index: 3;
}

.title {
  top: var(--margin-xl);
  left: var(--margin-xl);
}

.openButton {
  bottom: var(--margin-xl);
  right: var(--margin-xl);
}
