.container {
  left: 0;
  overflow: hidden;
  position: fixed;
  height: 100vh;
  top: 0;
  width: 100vw;
  visibility: hidden;
  z-index: calc(var(--tfd-appnav-z-index) + 1);

  &.open {
    visibility: visible;
  }
}

.overlay {
  background: rgba(57, 56, 60, 0);
  border: none;
  height: 100%;
  position: absolute;
  width: 100%;
  position: fixed;
  transition: background 0.3s ease-out;
  z-index: calc(var(--tfd-appnav-z-index) + 2);

  &.open {
    background: rgba(57, 56, 60, 0.6);
  }

  &:focus {
    outline: none;
  }
}

.slidingPanel {
  top: 100%;
  height: auto;
  position: fixed;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  z-index: calc(var(--tfd-appnav-z-index) + 3);

  &.open {
    transform: translateY(-100%) !important;
  }
}
