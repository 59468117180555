.text {
  margin-top: var(--margin-sm);
  color: var(--charcoal-3);
}

.cta {
  border-bottom: 1px solid;
}

.email {
  margin: 0 var(--margin-sm);
}

@media (--viewport-md) {
  .email {
    margin-top: 0;
    margin-bottom: 0;
  }
}
