.button {
  margin-bottom: var(--margin-xxl);
}

.title {
  font-size: 40px;
  margin-bottom: 16px;
  line-height: 48px;
}

.container::after {
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  z-index: -1;
  height: 100%;
  display: block;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
}

.underlineContainer {
  position: relative;
}

.mobileBreakline {
  display: block;
}

.desktopBreakline {
  display: none;
}

@media (--viewport-lg) {
  .mobileBreakline {
    display: none;
  }

  .desktopBreakline {
    display: block;
  }

  .button {
    margin: 0;
  }

  .cta {
    padding-top: var(--margin-3xl);
  }

  .title {
    font-size: 52px;
    margin-bottom: 20px;
    line-height: 62px;
  }
}
